// Creates the store for Redux
import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';

import middleware from "./middleware";
import enhancers from "./storeEnhancers";
import reducers from "../../store/reducers";
import whitelist from './whitelistReducers'
import devToolsOptions from './devToolsOptions'

// Create the persist config object
const persistConfig = {
	key: 'root',
	storage: storage,
	whitelist: whitelist
};

// Create a persisted reducer using the persist config
const persistedReducer = persistReducer(persistConfig, reducers);

// create enhancers for the Redux Dev tools
const composeEnhancers = composeWithDevTools(devToolsOptions);

// Create the Redux Saga middleware
const sagaMiddleware = createSagaMiddleware();

let store = createStore(persistedReducer, composeEnhancers(
	applyMiddleware(...middleware, sagaMiddleware),
	...enhancers,
));

// Run Saga Middleware
//sagaMiddleware.run(rootSaga);

let persistor = persistStore(store);

export {store, persistor};
