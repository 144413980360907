import React , { useState } from "react";

export const SelectAllEmail = React.createContext ( {
	emails : [
		{
			emailId : '' ,
			checked : false ,
		}
	] ,
	updateEmailState : () =>
	{
	} ,
	registerEmail : () =>
	{

	} ,
	selectAll : false ,
	toggleSelectAll : () =>
	{
	} ,
} );

export function SelectAllEmailContextProvider ( props )
{
	// Create a state to register all the emails along with their state
	const [ emails , setEmails ] = useState ( [] );

	// Create a state to register all the emails along with their state
	const [ selectAll , setSelectAll ] = useState ( false );

	// Create a temprorary artray to store the emails that need to be pushed to state
	let temporaryArr = [ ...emails ];

	// Function to register all the emails to the context
	function registerEmail ( emailId , bool )
	{
		function findEmail ( each )
		{
			return each.emailId === emailId;
		}

		let found = emails.some ( findEmail );

		if ( !found )
		{
			temporaryArr.push (
				{
					emailId : emailId ,
					checked : bool ,
				}
			)
			setEmails ( temporaryArr );
		}
	}

	// Function to update the state of a specific email when checkbox is clicked
	function updateEmailState ( emailId , bool )
	{
		// Check the email and change status
		temporaryArr.map ( ( each ) =>
			(
				each.emailId === emailId ?
					(
						each.checked = bool
					)
					:
					(
						{
							...each
						}
					)
			)
		)
		setEmails ( temporaryArr )
	}

	// Function to toggle the selectAll property of the context
	function toggleSelectAll ( bool )
	{
		setSelectAll ( bool );
	}

	return (
		<SelectAllEmail.Provider
			value = {
				{
					emails : emails ,
					registerEmail : registerEmail ,
					updateEmailState : updateEmailState ,
					selectAll : selectAll ,
					toggleSelectAll : toggleSelectAll
				}
			}
		>
			{ props.children }
		</SelectAllEmail.Provider>
	)
}

