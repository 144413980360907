import React , { useState } from "react";

export const MiniSidebarState = React.createContext ( {
	open : true ,
	toggle : () =>
	{
	} ,
} );

export function MiniSidebarStateContextProvider ( props )
{
	const [ open , setOpen ] = useState ( true );

	function toggleHandler ()
	{
		open ? setOpen ( false ) : setOpen ( true );
	}

	return (
		<MiniSidebarState.Provider
			value = {
				{
					open : open ,
					toggle : toggleHandler ,
				}
			}
		>
			{ props.children }
		</MiniSidebarState.Provider>
	)
}

