/**
 * Declaring object for the default blue color
 */
const rapidTextColor = {
    primary: 'rgba(0,0,0,.75)',
    secondary: 'rgba(0,0,0,.6)',
    disabled: 'rgba(0,0,0,.38)',
    hint: 'rgba(0,0,0,.38)',
    icon: 'rgba(0,0,0,.38)',
    divider: 'rgba(0,0,0,.12)',
};

export default rapidTextColor;
