import React , { useContext } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import clsx from "clsx";
import { makeStyles , useTheme } from '@material-ui/core/styles';
import { MiniSidebarState } from '../../../context';
import PropTypes from "prop-types";

/**
 * RapidMainContent is used to defind an area where most of your application lives. This is the area which excludes the sidebar, header and the footer of the application. This component is useful if you are creating your own layouts, but
 * if you are using one of the pre-defined layouts in RapidAdminReact® then you would not need to use this component ever.
 *
 * ##### Syntax
 * ```jsx
 <RapidMainContent>
	{ props.children }
 </RapidMainContent>
 * ```
 * @since version 1.0.0
 */
export default function RapidMainContent ( props )
{
	// Extract all props
	const { className } = props;

	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			display : 'block' ,
			width : 'fill-available' ,
		} ,
		rootRtl : {
			display : 'block' ,
			flexDirection : 'row-reverse' ,
		} ,
		toolbar : {
			display : 'flex' ,
			alignItems : 'center' ,
			justifyContent : 'flex-end' ,
			padding : theme.spacing ( 0 , 1 ) ,
			// necessary for content to be below app bar
			...theme.mixins.toolbar ,
		} ,
		content : {
			flexGrow : 1 ,
			padding : theme.spacing ( 3 ) ,
		} ,
		backdrop : {
			zIndex : theme.zIndex.drawer - 1 ,
			color : "#fff" ,
			[ theme.breakpoints.up ( "md" ) ] : {
				display : "none"
			}
		} ,
	} ) );

	const classes = useStyles ();
	const theme = useTheme ();
	const ltr = theme.direction === 'ltr';
	const rtl = theme.direction === 'rtl';
	let sidebarContext = useContext ( MiniSidebarState );
	let open = sidebarContext.open;

	return (
		<main className = { clsx ( `${ classes.content } ${ classes.root } ` + className , {
			[ classes.root ] : ltr ,
			[ classes.rootRtl ] : rtl ,
		} ) }
		>
			<div className = { classes.toolbar } />
			<Backdrop
				className = { classes.backdrop }
				open = { open }
				onClick = { () =>
				{
					sidebarContext.toggle ();
				} }
			/>

			{ props.children }
		</main>

	)
}

/**
 * propTypes for the component
 */
RapidMainContent.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
};