import React , { useContext , useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import RapidSwitch from "../../inputs/RapidSwitch/RapidSwitch";
import { RapidThemeSwitchContext , RapidThemeSwitchButtonContext } from "../../../context";
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import { theme } from "../../../../config/theme";

/**
 * RapidThemeSwitch - a component that lets you create a live switch between a `light theme` and a `dark theme`. You can include the RapidThemeSwitch component anywhere in your application. Just ensue that in `settings->config->theme` is
 * set to `'context'`
 * @since version: 1.0.0
 */
export default function RapidThemeSwitch ( props )
{
	//Extract all props
	const {
		offColor ,
		onColor ,
		offHandleColor ,
		onHandleColor ,
		handleDiameter ,
		uncheckedIcon ,
		checkedIcon ,
		boxShadow ,
		activeBoxShadow ,
		height ,
		width ,
		className ,
	}
		= props;

	// Create Styles
	const useStyles = makeStyles ( () => ( {
		iconLeft : {
			'& .react-switch-bg' : {
				'& svg' : {
					margin : '12% 22%' ,
					width : '1.1rem' ,
					height : '1.1rem' ,
				}
			}
		}
	} ) );
	// Create the classes
	const classes = useStyles ();

	// Set the context for the state of the theme
	const currentThemeContext = useContext ( RapidThemeSwitchContext );

	// Get the button context
	const buttonContext = useContext ( RapidThemeSwitchButtonContext );

	// Set the checked Value Of the button
	const checked = buttonContext.checked;

	// Handle change in the switch state
	const handleChange = ( checkedStatus , event , id ) =>
	{
		if ( id === 'rapidThemeSwitch' )
		{
			buttonContext.toggleChecked ( checkedStatus );
		}
	};

	// useEffect to change the theme in the context
	useEffect ( () =>
	{
		checked ? currentThemeContext.toggleTheme ( 'light' ) : currentThemeContext.toggleTheme ( 'dark' )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ checked ] );

	return (
		<RapidSwitch
			id = "rapidThemeSwitch"
			checked = { checked }
			onChange = { handleChange }
			offColor = { offColor }
			onColor = { onColor }
			offHandleColor = { offHandleColor }
			onHandleColor = { onHandleColor }
			handleDiameter = { handleDiameter }
			uncheckedIcon = { uncheckedIcon }
			checkedIcon = { checkedIcon }
			boxShadow = { boxShadow }
			activeBoxShadow = { activeBoxShadow }
			height = { height }
			width = { width }
			className = { classes.iconLeft + ' ' + className }
		/>
	);
}

RapidThemeSwitch.defaultProps = {
	checkedIcon : ( <WbSunnyIcon /> ) ,
	uncheckedIcon : ( <Brightness4Icon /> ) ,
	onColor : theme.palette.warning.main ,
	offColor : theme.palette.primary.dark ,
	activeBoxShadow : theme.shadows[ 3 ] ,
	width : 50 ,
	height : 24 ,
};

RapidThemeSwitch.propTypes = {
	/**
	 *  The switch will take on this color when it is not checked. Only accepts hex-colors.
	 */
	offColor : PropTypes.string ,
	/**
	 *  The switch will take on this color when it is checked. Only accepts hex-colors.
	 */
	onColor : PropTypes.string ,
	/**
	 *  The handle of the switch will take on this color when it is not checked. Only accepts hex-colors.
	 */
	offHandleColor : PropTypes.string ,
	/**
	 *  The handle of the switch will take on this color when it is checked. Only accepts hex-colors.
	 */
	onHandleColor : PropTypes.string ,
	/**
	 *  The diameter of the handle, measured in pixels. By default it will be 2 pixels smaller than the height of the switch.
	 */
	handleDiameter : PropTypes.number ,
	/**
	 *  An icon that will be shown on the switch when it is not checked. Pass in false if you don't want any icon.
	 */
	uncheckedIcon : PropTypes.node ,
	/**
	 *  An icon that will be shown on the switch when it is checked. Pass in false if you don't want any icon.
	 */
	checkedIcon : PropTypes.node ,
	/**
	 *  The default box-shadow of the handle. You can read up on the box-shadow syntax on MDN.
	 */
	boxShadow : PropTypes.string ,
	/**
	 *  The box-shadow of the handle when it is active or focused. Do not set this to null, since it is important for accessibility.
	 */
	activeBoxShadow : PropTypes.string ,
	/**
	 *  The height of the background of the switch, measured in pixels.
	 */
	height : PropTypes.number ,
	/**
	 *  The width of the background of the switch, measured in pixels.
	 */
	width : PropTypes.number ,
	/**
	 *  The width of the background of the switch, measured in pixels.Set as the className of the outer shell of the switch. Useful for positioning the switch.
	 */
	className : PropTypes.string ,

};
