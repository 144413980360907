import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles ( () => ( {
	rightWrapper : {
		display : 'flex' ,
	} ,
	rightWrapperRtl : {
		display : 'flex' ,
		flexDirection : 'row-reverse' ,
	}
} ) );

/**
 * RapidHeaderLinksRight is used to render the components on the rigght side of the header. This component is included in the default layouts. You can read more about [customising layouts here](/docs/createCutomLayouts/).
 *
 * ##### Syntax
 *```jsx
 <RapidHeaderLinksRight>
	Insert The Links Visible On The left Side Of The Header As Child Elements
 </RapidHeaderLinksRight>
 *```
 * @since version 1.0.0
 */

export default function RapidHeaderLinksRight ( props )
{
	// Extract all props
	const { className } = props;

	const classes = useStyles ();

	return (
		<Box className = { classes.rightWrapper + ' ' + className }>
			{ props.children }
		</Box>
	)

}

/**
 * propTypes for the component
 */
RapidHeaderLinksRight.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
};
