import React , { useState } from "react";

export const ComposeEmailState = React.createContext ( {
	open : false ,
	setOpen : () =>
	{
	} ,
} );

export function ComposeEmailStateContextProvider ( props )
{
	const [ open , setOpen ] = useState ( false );

	function toggleOpen ()
	{
		setOpen ( !open )
	}

	return (
		<ComposeEmailState.Provider
			value = {
				{
					open : open ,
					setOpen : toggleOpen ,
				}
			}
		>
			{ props.children }
		</ComposeEmailState.Provider>
	)
}

