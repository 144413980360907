/**
 * Declaring object for the default blue color
 */
const rapidActionColorsDark = {
	active : 'rgba(182,183,186, 0.50)' ,
	hover : 'rgba(182,183,186, 0.10)' ,
	hoverOpacity : 0.08 ,
	selected : 'rgba(182,183,186, 0.08)' ,
	selectedOpacity : 0.08 ,
	disabled : 'rgba(182,183,186, 0.26)' ,
	disabledBackground : 'rgba(182,183,186, 0.08)' ,
	disabledOpacity : 0.26 ,
	focus : 'rgba(182,183,186, 0.12)' ,
	focusOpacity : 0.12 ,
	activatedOpacity : 0.12 ,
};

export default rapidActionColorsDark;
