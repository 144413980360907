import React , { useState } from "react";

export const RapidThemeDirectionContext = React.createContext ( {
	direction : 'ltr' ,
	toggleDirection : () =>
	{
	} ,
} );

export function RapidThemeDirectionContextProvider ( props )
{
	const [ direction , setDirection ] = useState ( 'rtl' );

	function toggleDirection ( string )
	{
		setDirection ( string );
	}

	return (
		<RapidThemeDirectionContext.Provider
			value = {
				{
					direction : direction ,
					toggleDirection : toggleDirection ,
				}
			}
		>
			{ props.children }
		</RapidThemeDirectionContext.Provider>
	)
}

