/**
 * Declaring object for the default blue color
 */
const rapidActionColors = {
	active : 'rgba(9,13,23,0.54)' ,
	hover : 'rgba(9,13,23,0.04)' ,
	hoverOpacity : 0.04 ,
	selected : 'rgba(9,13,23,0.08)' ,
	selectedOpacity : 0.08 ,
	disabled : 'rgba(9,13,23,0.26)' ,
	disabledBackground : 'rgba(9,13,23,0.12)' ,
	disabledOpacity : 0.38 ,
	focus : 'rgba(9,13,23,0.12)' ,
	focusOpacity : 0.12 ,
	activatedOpacity : 0.12 ,
};

export default rapidActionColors;
