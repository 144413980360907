import React , { Fragment , useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { MiniSidebarState } from '../../../context';
import settings from "../../../../config/rapidAdmin/settings";
import PropTypes from "prop-types";
import calculateAdjacentBreakpoints from "../../../tools/functions/calculateAdjacentBreakpoints";
import RapidRenderTheme from "../../../tools/RapidRenderTheme/RapidRenderTheme";

/**
 * RapidHeader - component is used to the render the main head navigation. You only need to use this component if you are creating your own custom layouts. If you are already using one of the layouts provided by RapidAdminReact then
 * probably you will never need to use this component. See how you can use [ready layouts here](/docs/usingLayouts/)
 * @since version 1.0.0
 */
export default function RapidHeader ( props )
{
	const drawerWidth = settings.sidebar.drawerWidth;

	// Extract all props
	const {
		themeUsed ,
		backgroundColor ,
		className ,
		scrollBehavior ,
		elevation ,
	} = props;

	// Set the sidebar open or closed context
	let sidebarContext = useContext ( MiniSidebarState );
	let open = sidebarContext.open;
	// Create an object of adjacent breakpoints
	const breakpoints = calculateAdjacentBreakpoints ( settings.config.desktopBreakpoint );

	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			display : 'flex' ,
		} ,
		rootRtl : {
			display : 'flex' ,
		} ,
		appBar : {
			zIndex : theme.zIndex.drawer + 1 ,
			transform : 'translate3d(0,0,0)' ,
			transition : theme.transitions.create ( [ 'width' ] , {
				easing : theme.transitions.easing.easeOut ,
				duration : theme.transitions.duration.shortest ,
			} ) ,
			boxShadow : theme.shadows[ elevation ] ,
		} ,
		appBarShift : {
			marginLeft : drawerWidth ,
			width : `calc(100% - ${ drawerWidth }px)` ,
			transform : 'translate3d(0,0,0)' ,
			transition : theme.transitions.create ( [ 'width' ] , {
				easing : theme.transitions.easing.easeOut ,
				duration : theme.transitions.duration.shortest ,
			} ) ,
		} ,
		appBarWidthClosed : {
			width : `calc(100% - ${ theme.spacing ( 0 ) + 1 }px)` ,
			[ theme.breakpoints.up ( breakpoints.current ) ] : {
				width : `calc(100% - ${ theme.spacing ( 7 ) + 1 }px)` ,
			} ,
		} ,
		hide : {
			display : 'none' ,
		} ,
		navbarWrap : {
			justifyContent : "space-between" ,
			alignItems : "center" ,
		} ,
		navbarWrapRtl : {
			flexDirection : 'row-reverse' ,
		} ,
	} ) );
	// Creates classes
	const classes = useStyles ();

	return (
		<Fragment>
			<RapidRenderTheme themeOverride = { themeUsed }>
				<AppBar
					color = { backgroundColor }
					position = { scrollBehavior }
					className = { clsx ( `${ classes.appBar } ` + className , {
						[ classes.appBarShift ] : open ,
						[ classes.appBarWidthClosed ] : !open ,
						[ classes.root ] : open ,
					} ) }
				>
					<Toolbar
						component = "nav"
						className = { classes.navbarWrap }
					>
						{ props.children }

					</Toolbar>
				</AppBar>
			</RapidRenderTheme>
		</Fragment>
	)
}

// Assign the default props for the component
RapidHeader.defaultProps = {
	backgroundColor : 'inherit' ,
	toggle : false ,
	scrollBehavior : 'fixed' ,
	elevation : 1 ,
};

/**
 * propTypes for the component
 */
RapidHeader.propTypes = {
	/**
	 *  Theme used for the header component. Whether you want to render a `dark` or a `light` theme.
	 */
	scrollBehavior : PropTypes.oneOf ( [ 'fixed' , 'absolute' ] ) ,
	/**
	 *  Theme used for the header component. Whether you want to render a `dark` or a `light` theme.
	 */
	themeUsed : PropTypes.oneOf ( [ 'light' , 'dark' ] ) ,
	/**
	 *  pass in any of the values defined to set the color of the header
	 */
	backgroundColor : PropTypes.oneOf ( [ 'default' , 'primary' , 'secondary' , 'transparent' , 'inherit' ] ) ,
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  The elevation number to be used as the per the Material UI theme
	 */
	elevation : PropTypes.number ,

};