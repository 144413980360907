import React from "react";
import Board from 'react-trello';
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles , useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import settings from "../../../../config/rapidAdmin/settings";

/**
 * RapidKanban is Pluggable components to add a Trello (like) kanban board to your application
 *
 * ```javascript
// Sample Data Object
const data = {
lanes : [
	{
		id : 'lane1' ,
		title : 'Planned Tasks' ,
		label : '2/2' ,
		cards : [
			{
				id : 'Card1' ,
				title : 'Write Blog' ,
				description : 'Can AI make memes' ,
				label : '30 mins' ,
				draggable : false
			} ,
			{
				id : 'Card2' ,
				title : 'Pay Rent' ,
				description : 'Transfer via NEFT' ,
				label : '5 mins' ,
				metadata : { sha : 'be312a1' }
			}
		]
	} ,
	{
		id : 'lane2' ,
		title : 'Completed' ,
		label : '0/0' ,
		cards : []
	}
]
};
```
 *
 * @since version 1.0.0
 */
export default function RapidKanban ( props )
{
	// Extract All props
	const {
		data ,
		className ,
		draggable ,
		laneDraggable ,
		cardDraggable ,
		collapsibleLanes ,
		editable ,
		canAddLanes ,
		hideCardDeleteIcon ,
		editLaneTitle ,
		handleDragStart ,
		handleDragEnd ,
		handleLaneDragStart ,
		handleLaneDragEnd ,
		onDataChange ,
		onCardClick ,
		onCardAdd ,
		onBeforeCardDelete ,
		onCardDelete ,
		onCardMoveAcrossLanes ,
		onLaneAdd ,
		onLaneDelete ,
		onLaneUpdate ,
		onLaneClick ,
		onLaneScroll ,
		gutterSpace ,
		spaceBetweenCards ,
		cardInnerPadding ,
		cardWidth
	} = props;

	// Get the parent theme
	const parentTheme = useTheme ();

	// Check RTL or not
	const rtl = settings.config.themeDirection === 'rtl';

	// Get the theme direction boolean values for ltr and rtl

	// Create Classes to be passed to Kanban
	const useStyles = makeStyles ( ( theme ) => ( {
		kanbanWrapper : {
			'& .smooth-dnd-draggable-wrapper .react-trello-lane' :
				{
					margin : theme.spacing ( 0 , gutterSpace ) ,
					backgroundColor : theme.palette.background.default ,
					borderRadius : theme.shape.borderRadius + 'px' ,
					padding : theme.spacing ( spaceBetweenCards ) ,

					'& .sc-fzoLsD' : {
						maxHeight : '100%' ,
					} ,

					'& header' :
						{
							padding : theme.spacing ( 1 , 0 ) ,
							alignItems : 'center' ,

							'& span.sc-fzpans' : {
								fontFamily : theme.typography.subtitle1.fontFamily ,
								fontSize : theme.typography.subtitle1.fontSize ,
								fontWeight : theme.typography.subtitle1.fontWeight ,
								lineHeight : 1.1 , //theme.typography.subtitle1.lineHeight ,
								color : theme.palette.text.primary ,
								marginRight : theme.spacing ( 2 ) ,

								// Overriding textarea
								'& textarea.sc-fzqARJ' : {
									margin : '0px' ,
									fontFamily : theme.typography.subtitle1.fontFamily + ' !important' ,
									fontSize : theme.typography.subtitle1.fontSize + ' !important' ,
									fontWeight : theme.typography.subtitle1.fontWeight + ' !important' ,
									color : theme.palette.text.primary + ' !important' ,
									lineHeight : 1.1 , //theme.typography.subtitle2.lineHeight + ' !important' ,
									height : 'auto' ,
									minHeight : 'auto' ,
									padding : theme.spacing ( 1 ) ,
									borderRadius : theme.shape.borderRadius + 'px' ,
									backgroundColor : theme.palette.background.default ,

									'&:focus' : {
										boxShadow : 'none' ,
										border : `1px solid ${ theme.palette.primary.main }` ,
										backgroundColor : theme.palette.background.paper ,
									}

								} ,

								'& span' : {
									fontFamily : theme.typography.caption.fontFamily ,
									fontSize : theme.typography.caption.fontSize ,
									fontWeight : theme.typography.caption.fontWeight ,
									color : theme.palette.text.primary ,
									lineHeight : 1.1 ,// theme.typography.caption.lineHeight ,
								} ,
							} ,

							'& .sc-fzplWN' : {
								width : 'auto' ,
								fontFamily : theme.typography.caption.fontFamily ,
								fontSize : theme.typography.caption.fontSize ,
								fontWeight : theme.typography.caption.fontWeight ,
								color : theme.palette.text.primary ,
							} ,

							'& .sc-AxiKw' : {
								cursor : 'pointer' ,
								position : 'relative' ,
								'& .sc-AxjAm' : {
									display : 'flex' ,
									textDecoration : 'none' ,
									padding : theme.spacing ( .75 ) ,
									borderRadius : '50%' ,
									backgroundColor : theme.palette.background.default ,
									transition : 'background .8s .1s' ,
									'&:hover' : {
										backgroundColor : `${ theme.palette.action.hover }` ,
									} ,
									'&:active' : {
										backgroundColor : theme.palette.action.active ,
										backgroundSize : '100%' ,
										transition : 'background 0s' ,
									}
								} ,

								'& .sc-fzoXWK' : {
									margin : '0px' ,
									backgroundColor : 'transparent' ,
									fontFamily : theme.typography.caption.fontFamily ,
									fontSize : theme.typography.caption.fontSize ,
									fontWeight : theme.typography.caption.fontWeight ,
									color : theme.palette.text.primary ,
									'&:hover' : {
										background : 'transparent' ,
									} ,
									'&:active' : {
										backgroundColor : 'transparent' ,
										backgroundSize : '100%' ,
									}
								} ,

								'& > div.sc-AxirZ' : {
									transition : 'all .15s ease-in' ,
									backgroundColor : theme.palette.background.paper ,
									width : theme.spacing ( 20 ) ,
									display : 'flex' ,
									justifyContent : 'space-between' ,
									padding : theme.spacing ( 1 ) ,

									'& .sc-fzpmMD' : {

										width : 'auto' ,
										display : 'flex' ,
										alignItems : 'center' ,
										fontFamily : theme.typography.subtitle2.fontFamily ,
										fontSize : theme.typography.subtitle2.fontSize ,
										fontWeight : theme.typography.subtitle2.fontWeight ,
										color : theme.palette.text.primary ,
										lineHeight : theme.typography.subtitle2.lineHeight ,
										margin : '0px' ,
										padding : theme.spacing ( 1 , 0 ) ,
										borderBottom : `${ theme.palette.divider } solid 1px` ,

										'& div.sc-fzqNqU' : {
											display : 'flex' ,
											alignItems : 'center' ,
											height : '100%' ,
											backgroundColor : theme.palette.background.paper ,
											borderRadius : '50%' ,
											position : 'relative' ,

											'& button.sc-fzoyTs' : {
												backgroundColor : 'transparent' ,
												fontFamily : theme.typography.caption.fontFamily ,
												fontSize : theme.typography.caption.fontSize ,
												fontWeight : theme.typography.caption.fontWeight ,
												color : theme.palette.action.disabled ,
												lineHeight : theme.typography.caption.lineHeight ,
												transition : 'color .5s' ,
												margin : '0px' ,

												'&:focus' : {
													outline : 'none' ,
												} ,
												'&:hover' : {
													color : theme.palette.text.primary ,
												} ,
											}
										} ,

										'& span.sc-fznWOq' : {
											padding : '0px' ,
											alignSelf : 'center' ,
											display : 'flex' ,
											border : 'none' ,
											backgroundColor : 'transparent' ,
											fontFamily : theme.typography.subtitle2.fontFamily ,
											fontSize : theme.typography.subtitle2.fontSize ,
											fontWeight : theme.typography.subtitle2.fontWeight ,
											color : theme.palette.text.primary ,
											lineHeight : theme.typography.subtitle2.lineHeight ,
											margin : '0px 16px 0px 0px' ,
										} ,
									} ,

									'& div.sc-fznxKY' : {
										padding : theme.spacing ( 1.25 , 2.5 ) ,
										transition : `background-color .5s` ,
										'&:hover' : {
											backgroundColor : theme.palette.action.hover ,
										} ,

										'& div' : {
											fontFamily : theme.typography.caption.fontFamily ,
											fontSize : theme.typography.caption.fontSize ,
											fontWeight : theme.typography.caption.fontWeight ,
											color : theme.palette.text.secondary ,

											lineHeight : theme.typography.caption.lineHeight ,
											padding : theme.spacing ( 0 ) ,

											'&:hover' : {
												backgroundColor : 'transparent' ,
												color : theme.palette.text.primary ,
											} ,

										} ,

									} ,
								}

							} ,

						} ,
					'& article' : {
						// Width applied to all articles
						width : cardWidth ,
						maxWidth : 'none !important' ,
						backgroundColor : theme.palette.background.paper ,
						border : 'none' ,
						boxShadow : theme.shadows[ 1 ] ,
						borderRadius : theme.shape.borderRadius + 'px' ,
						marginBottom : theme.spacing ( spaceBetweenCards ) ,
						padding : theme.spacing ( cardInnerPadding / 2 , cardInnerPadding ) ,
						transition : `background-color .5s` ,

						'&:hover' : {
							backgroundColor : theme.palette.action.hover ,
						} ,

						'& header' : {
							display : 'flex' ,
							justifyContent : 'space-between' ,
							margin : '0px' ,
							padding : theme.spacing ( cardInnerPadding * 1.3 , 0 ) ,
							borderBottom : `1px solid ${ theme.palette.divider }` ,

							'& span.sc-fzpans' : {
								fontFamily : theme.typography.subtitle2.fontFamily ,
								fontSize : theme.typography.subtitle2.fontSize ,
								fontWeight : theme.typography.subtitle2.fontWeight ,
								color : theme.palette.text.primary ,
								lineHeight : theme.typography.subtitle2.lineHeight ,
							} ,

							'& span.sc-fzplWN' : {
								fontFamily : theme.typography.caption.fontFamily ,
								fontSize : theme.typography.caption.fontSize ,
								fontWeight : theme.typography.caption.fontWeight ,
								color : theme.palette.text.primary ,
								lineHeight : theme.typography.caption.lineHeight ,
								margin : '0px' ,
							} ,
						} ,
						"& .sc-fzqNJr" : {
							padding : theme.spacing ( cardInnerPadding * 1.3 , 0 ) ,
							fontFamily : theme.typography.body2.fontFamily ,
							fontSize : theme.typography.body2.fontSize ,
							fontWeight : theme.typography.body2.fontWeight ,
							color : theme.palette.text.primary ,
							lineHeight : theme.typography.body2.lineHeight ,
						} ,

						// Close Button On The Cards
						'& button.sc-fzoNJl' : {
							fontFamily : theme.typography.overline.fontFamily ,
							fontSize : theme.typography.overline.fontSize ,
							fontWeight : theme.typography.overline.fontWeight ,
							color : theme.palette.text.secondary ,
							lineHeight : theme.typography.overline.lineHeight ,
							margin : theme.spacing ( 1 ) ,

							'&:hover' : {
								color : theme.palette.text.primary ,
							} ,
							'&:focus' : {
								outline : 'none' ,
							} ,
						} ,

						// Styling the area for tags
						'& .sc-fzoyAV' : {
							padding : theme.spacing ( 1 , 0 ) ,
							borderTop : `1px solid ${ theme.palette.divider }` ,

							// Styling for the tags in the article card
							'& .sc-fzoLag' : {
								padding : theme.spacing ( .75 , 1 ) ,
								borderRadius : theme.shape.borderRadius + 'px' ,
								fontFamily : theme.typography.caption.fontFamily ,
								fontSize : theme.typography.caption.fontSize ,
								fontWeight : theme.typography.caption.fontWeight ,
								color : theme.palette.common.white ,
								lineHeight : theme.typography.caption.lineHeight ,
								transition : `background-color .5s` ,
								margin : theme.spacing ( .5 ) ,

								'&: hover' : {
									backgroundColor : parentTheme.palette.action.hover ,
								} ,
							}

						} ,
					} ,

					// Adding A New Card
					'& .sc-fzoXzr' : {
						fontFamily : theme.typography.caption.fontFamily ,
						fontSize : theme.typography.caption.fontSize ,
						fontWeight : theme.typography.caption.fontWeight ,
						color : theme.typography.caption.color ,
						lineHeight : theme.typography.caption.lineHeight ,
						textDecoration : 'none' ,
						transition : `color .5s` ,
						margin : '0px' ,
						padding : theme.spacing ( cardInnerPadding , 0 ) ,
						'& .hover' : {
							color : theme.palette.action.hover ,
						} ,
					} ,

					// Add new card form
					'& .sc-fzoiQi' : {
						backgroundColor : theme.palette.background.paper ,
						padding : theme.spacing ( cardInnerPadding ) ,
						borderRadius : theme.shape.borderRadius + 'px' ,
						width : cardWidth ,
						maxWidth : 'none' ,

						// Overrind styles for buttons
						'& button' : {
							border : '0px' ,
							borderRadius : theme.shape.borderRadius + 'px' ,
							appearance : 'none' ,
							boxShadow : 'none' ,
							borderImage : 'none !important' ,
							borderColor : 'transparent !important' ,
							fontFamily : theme.typography.caption.fontFamily ,
							fontSize : theme.typography.caption.fontSize ,
							fontWeight : theme.typography.caption.fontWeight ,
							color : theme.typography.caption.color ,
							lineHeight : theme.typography.caption.lineHeight ,

							'&:focus' : {
								outline : 'none !important'
							} ,
						} ,

						'& button.sc-fzoKki' : {
							backgroundColor : theme.palette.primary.main ,
							transition : `background-color .5 ease-in` ,

							'&:hover' : {
								backgroundColor : theme.palette.primary.dark ,
							} ,

							'&:focus' : {
								backgroundColor : theme.palette.primary.light ,
							} ,
						} ,

						'& button.sc-fzoYkl' : {
							backgroundColor : theme.palette.action.disabled ,
							transition : `background-color .5 ease-in` ,

							'&:hover' : {
								backgroundColor : theme.palette.action.active ,
							} ,

							'&:focus' : {
								backgroundColor : theme.palette.action.focus ,
							} ,
						} ,

						'& article.sc-fznyAO' : {
							border : 'none' ,
							boxShadow : 'none' ,
							width : '100%' ,
							padding : theme.spacing ( 0 ) ,
							marginBottom : theme.spacing ( cardInnerPadding ) ,

							'&:hover' : {
								backgroundColor : 'inherit' ,
							} ,

							'& header.sc-AxheI' : {
								display : 'flex' ,
								alignItems : 'center' ,

								'& span' : {

									'&:focus' : {
										border : `1px solid ${ theme.palette.primary.main }` ,
									} ,
								} ,

							} ,

							'& .comPlainTextContentEditable' : {
								outline : 'none' ,
								textAlign : 'left' ,
								padding : theme.spacing ( 1 , 1.5 ) ,
								border : `1px solid ${ theme.palette.divider }` ,
								borderRadius : `${ theme.shape.borderRadius }px ` ,
								fontFamily : theme.typography.caption.fontFamily ,
								fontSize : theme.typography.caption.fontSize ,
								fontWeight : theme.typography.caption.fontWeight ,
								color : theme.typography.caption.color ,
								lineHeight : theme.typography.caption.lineHeight ,
								'&:focus' : {
									border : `1px solid ${ theme.palette.primary.main }` ,
								} ,
							}
						} ,
					} ,
				} ,

			// Overiding the Add Another Item Button
			'& .smooth-dnd-container' : {

				'& .sc-fznxsB' : {
					backgroundColor : 'transparent' ,
					margin : theme.spacing ( 0 ) ,
					padding : theme.spacing ( 0 ) ,

					'& .sc-fzpkJw' : {
						padding : theme.spacing ( 2 , 3 ) ,
						transition : `background-color .5s ease-in` ,
						backgroundColor : theme.palette.primary.main ,
						fontFamily : theme.typography.subtitle2.fontFamily ,
						fontSize : theme.typography.subtitle2.fontSize ,
						fontWeight : theme.typography.subtitle2.fontWeight ,
						color : theme.typography.subtitle2.color ,
						lineHeight : theme.typography.subtitle2.lineHeight ,

						'&:hover' : {
							backgroundColor : theme.palette.primary.dark ,
						} ,

						'&:focus' : {
							outline : 'none' ,
							backgroundColor : theme.palette.primary.light ,
						}
					} ,
				} ,

				// Add New Lane Section
				'& section.sc-Axmtr' : {
					margin : theme.spacing ( 0 , gutterSpace ) ,
					borderRadius : theme.shape.borderRadius + 'px' ,
					backgroundColor : theme.palette.background.default ,
					padding : theme.spacing ( cardInnerPadding ) ,
					minWidth : `calc( ${ cardWidth } + ${ ( theme.spacing ( cardInnerPadding ) * 2 ) }px )` ,

					'& .sc-fzpjYC' : {

						'& .kxPMcv' : {
							margin : '0px' ,
							fontFamily : theme.typography.subtitle2.fontFamily + ' !important' ,
							fontSize : theme.typography.subtitle2.fontSize + ' !important' ,
							fontWeight : theme.typography.subtitle2.fontWeight + ' !important' ,
							color : theme.palette.text.primary + ' !important' ,
							lineHeight : 1.1 , //theme.typography.subtitle2.lineHeight + ' !important' ,
							height : 'auto' ,
							minHeight : 'auto' ,
							padding : theme.spacing ( 1 ) ,
							borderRadius : theme.shape.borderRadius + 'px' ,
							backgroundColor : theme.palette.background.default ,
						}
					} ,

					'& .sc-fznWqX' : {

						'& button' : {
							border : '0px' ,
							borderRadius : theme.shape.borderRadius + 'px' ,
							appearance : 'none' ,
							boxShadow : 'none' ,
							borderImage : 'none !important' ,
							borderColor : 'transparent !important' ,
							fontFamily : theme.typography.caption.fontFamily ,
							fontSize : theme.typography.caption.fontSize ,
							fontWeight : theme.typography.caption.fontWeight ,
							color : theme.typography.caption.color ,
							lineHeight : theme.typography.caption.lineHeight ,

							'&:focus' : {
								outline : 'none !important'
							} ,
						} ,

						'& button.sc-fzoKki' : {
							backgroundColor : theme.palette.primary.main ,
							transition : `background-color .5 ease-in` ,

							'&:hover' : {
								backgroundColor : theme.palette.primary.dark ,
							} ,

							'&:focus' : {
								backgroundColor : theme.palette.primary.light ,
							} ,
						} ,

						'& button.sc-fzoYkl' : {
							backgroundColor : theme.palette.action.disabled ,
							transition : `background-color .5 ease-in` ,

							'&:hover' : {
								backgroundColor : theme.palette.action.active ,
							} ,

							'&:focus' : {
								backgroundColor : theme.palette.action.focus ,
							} ,
						} ,
					} ,

					// Override Close and open Button Styling

					'& .sc-fzozJi' : {
						height : `${ theme.spacing ( cardInnerPadding ) * 2 }px` ,
					} ,

				} ,

			} ,
		} ,

		kanbanWrapperRTL : {

			'& .react-trello-board' : {
				display : 'rtl' ,
				//flexDirection : 'row-reverse' ,
			} ,

			'& .sc-fzqNqU' : {
				right : '2px' ,
				left : 'auto'
			}

		} ,

		// CSS class to be applied to Card when being dragged
		cardDragClass : {
			boxShadow : `${ theme.shadows[ 16 ] } !important` ,
		} ,
		// CSS class to be applied to Card when being dropped
		cardDropClass : {} ,
		// CSS class to be applied to Lane when being dragged
		laneDragClass : {
			boxShadow : theme.shadows[ 14 ] ,
		} ,
		// CSS class to be applied to Lane when being dropped
		laneDropClass : {} ,
	} ) );

	// Declaring the styles for the Kanban
	const boardClasses = {
		// Pass CSS style props to board container
		style : {
			backgroundColor : 'transparent' ,
			padding : '0px' ,
			height : 'auto' ,
		} ,
		// CSS style for every cards
		cardStyle : {} ,
		// CSS style for every lanes
		laneStyle : {} ,
		// If cards have tags, use this prop to modify their style
		tagStyle : {} ,
	};

	// Create styles for the Kanban
	const classes = useStyles ();

	return (
		<Box className = { clsx ( classes.kanbanWrapper + ' ' + className , {
			[ classes.kanbanWrapperRTL ] : rtl ,
		} ) }
		>
			<Board
				className
				cardDragClass = { classes.cardDragClass }
				cardDropClass = { classes.cardDropClass }
				laneDragClass = { classes.laneDragClass }
				laneDropClass = { classes.laneDropClass }
				style = { boardClasses.style }
				cardStyle = { boardClasses.cardStyle }
				laneStyle = { boardClasses.laneStyle }
				tagStyle = { boardClasses.tagStyle }
				data = { data }
				laneDraggable = { laneDraggable }
				draggable = { draggable }
				cardDraggable = { cardDraggable }
				collapsibleLanes = { collapsibleLanes }
				editable = { editable }
				canAddLanes = { canAddLanes }
				hideCardDeleteIcon = { hideCardDeleteIcon }
				editLaneTitle = { editLaneTitle }
				handleDragStart = { handleDragStart }
				handleDragEnd = { handleDragEnd }
				handleLaneDragStart = { handleLaneDragStart }
				handleLaneDragEnd = { handleLaneDragEnd }
				onDataChange = { onDataChange }
				onCardClick = { onCardClick }
				onCardAdd = { onCardAdd }
				onBeforeCardDelete = { onBeforeCardDelete }
				onCardDelete = { onCardDelete }
				onCardMoveAcrossLanes = { onCardMoveAcrossLanes }
				onLaneAdd = { onLaneAdd }
				onLaneDelete = { onLaneDelete }
				onLaneUpdate = { onLaneUpdate }
				onLaneClick = { onLaneClick }
				onLaneScroll = { onLaneScroll }
			/>
		</Box>
	)
}

RapidKanban.defaultProps = {
	draggable : true ,
	laneDraggable : true ,
	cardDraggable : true ,
	collapsibleLanes : true ,
	editable : true ,
	canAddLanes : true ,
	hideCardDeleteIcon : false ,
	editLaneTitle : true ,
	gutterSpace : 1 ,
	spaceBetweenCards : 1.5 ,
	cardInnerPadding : 1.5 ,
	cardWidth : '300px' ,
};

RapidKanban.propTypes = {
	/**
	 *  The data object that needs to be passed to RapidKanban to Generate Cards
	 */
	data : PropTypes.object ,
	/**
	 *  Makes the cards and the lanes in RapidKanban Draggable. Pass `false` to disable dragging
	 */
	draggable : PropTypes.bool ,
	/**
	 *  Set to false to disable lane dragging.
	 */
	laneDraggable : PropTypes.bool ,
	/**
	 * Set to false to disable card dragging.
	 */
	cardDraggable : PropTypes.bool ,
	/**
	 * Make the lanes with cards collapsible.
	 */
	collapsibleLanes : PropTypes.bool ,
	/**
	 * Makes the entire board editable. Allow cards to be added or deleted
	 */
	editable : PropTypes.bool ,
	/**
	 * Allows new lanes to be added to the board.
	 */
	canAddLanes : PropTypes.bool ,
	/**
	 * Disable showing the delete icon to the top right corner of the card (when board is editable)
	 */
	hideCardDeleteIcon : PropTypes.bool ,
	/**
	 * Disable showing the delete icon to the top right corner of the card (when board is editable)
	 */
	editLaneTitle : PropTypes.bool ,
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  Callback function triggered when card drag is started: `handleDragStart(cardId, laneId)`
	 */
	handleDragStart : PropTypes.func ,
	/**
	 *  Callback function triggered when card drag ends, return false if you want to cancel drop: `handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails`)
	 */
	handleDragEnd : PropTypes.func ,
	/**
	 *  Callback function triggered when lane drag is started: `handleLaneDragStart(laneId)`
	 */
	handleLaneDragStart : PropTypes.func ,
	/**
	 *  Callback function triggered when lane drag ends: `handleLaneDragEnd(removedIndex, addedIndex, payload)`
	 */
	handleLaneDragEnd : PropTypes.func ,
	/**
	 *  Called everytime the data changes due to user interaction or event bus: `onDataChange(newData)`
	 */
	onDataChange : PropTypes.func ,
	/**
	 *  Called when a card is clicked: `onCardClick(cardId, metadata, laneId)`
	 */
	onCardClick : PropTypes.func ,
	/**
	 *  Called when a new card is added: `onCardAdd(card, laneId)`
	 */
	onCardAdd : PropTypes.func ,
	/**
	 *  Called before delete a card, please call the callback() if confirm to delete a card: `onConfirmCardDelete(callback)`
	 */
	onBeforeCardDelete : PropTypes.func ,
	/**
	 *  Called when a card is deleted: `onCardDelete(cardId, laneId)`
	 */
	onCardDelete : PropTypes.func ,
	/**
	 *  Called when a card is moved across lanes `onCardMoveAcrossLanes(fromLaneId, toLaneId, cardId, index)`
	 */
	onCardMoveAcrossLanes : PropTypes.func ,
	/**
	 *  Called when a new lane is added: `onLaneAdd(params)`
	 */
	onLaneAdd : PropTypes.func ,
	/**
	 *  Called when a lane is deleted `onLaneDelete(laneId)`
	 */
	onLaneDelete : PropTypes.func ,
	/**
	 *  Called when a lane attributes are updated onLaneUpdate(laneId, data)
	 */
	onLaneUpdate : PropTypes.func ,
	/**
	 *  Called when a lane is clicked `onLaneClick(laneId)`. Card clicks are not propagated to lane click event
	 */
	onLaneClick : PropTypes.func ,
	/**
	 *  Called when a lane is scrolled to the end: `onLaneScroll(requestedPage, laneId)`
	 */
	onLaneScroll : PropTypes.func ,
	/**
	 *  Gutter space between different lanes. The number is multiplies by the base used by Material UI theme. The default being `1 = 8px`
	 */
	gutterSpace : PropTypes.number ,
	/**
	 *  The pace between the cards in a single lane. The number is multiplies by the base used by Material UI theme. The default being `1 = 8px`
	 */
	spaceBetweenCards : PropTypes.number ,
	/**
	 *  Padding inside the Cards. The number is multiplies by the base used by Material UI theme. The default being `1 = 8px`
	 */
	cardInnerPadding : PropTypes.number ,
	/**
	 *  The Width of the card. Has to be a sting with any valid value `100px`, `12em`
	 */
	cardWidth : PropTypes.string ,

};
