import React from 'react';
import { makeStyles , useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Album from '@material-ui/icons/Album';
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import ButtonBase from '@material-ui/core/ButtonBase';

// Function to render the color of the icon
// 'primary' , 'secondary' , 'default' , 'warning' , 'error' , 'success' , 'info'
function renderIconColor ( iconColor , theme )
{
	if ( iconColor === 'primary' )
	{
		return { color : theme.palette.primary.main }

	}
	else if ( iconColor === 'secondary' )
	{
		return { color : theme.palette.secondary.main }
	}
	else if ( iconColor === 'default' )
	{
		return { color : theme.palette.background.default }
	}
	else if ( iconColor === 'warning' )
	{
		return { color : theme.palette.warning.main }
	}
	else if ( iconColor === 'error' )
	{
		return { color : theme.palette.error.main }
	}
	else if ( iconColor === 'success' )
	{
		return { color : theme.palette.success.main }
	}
	else if ( iconColor === 'info' )
	{
		return { color : theme.palette.info.main }
	}
}

/**
 * RapidTimeline renders a timeline of events along with links to those events if needed. To render the events you need to pass an array of events in the following structure as a prop
 *
 * ```javascript
 * const events = [
 * 		{
 * 			id : 'events1' ,
 * 		    date: '25 January, 2020',
 * 		    time: '23:40 Hrs',
 * 		    detail: 'You sold an item SKU: AMZ1245',
 * 		    color: 'primary', // Use any 'primary', 'secondary', 'warning', 'error', 'info', 'default', 'success'
 * 		    path: '#'
 * 		},
 * 		{
 * 			id : 'events2' ,
 * 		    date: '26 January, 2020',
 * 		    time: '10:17 Hrs',
 * 		    detail: 'Funds transferred to your account ending 789',
 * 		    color: 'secondary',
 * 		    path: '#'
 * 		}
 * ]
 * ```
 *
 * @since version: 1.0.0
 */
export default function RapidTimeline ( props )
{
	// Extract all props
	const
		{
			dateContainerWidth ,
			eventContainerWidth ,
			iconContainerWidth ,
			dividerThickness ,
			iconWidth ,
			events ,
			maxEvents ,
			displaySeeAll ,
			seeAllButtonText ,
			seeAllPath ,
			horizontalPadding ,
			verticalPadding ,
			seeAllButtonColor ,
			seeAllButtonVariant ,
			className ,
		}
			= props;
	// Get the parent theme that is being used
	const parentTheme = useTheme ();

	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			width : '100%' ,
			position : 'relative' ,
			display : 'flex' ,
			flexDirection : 'column' ,
		} ,
		timelineInnerContainer : {
			borderLeft : `${ dividerThickness }px solid ${ theme.palette.text.divider }` ,
			height : displaySeeAll ? `calc(100% - ${ verticalPadding * 3.5 }px)` : `calc(100% - ${ verticalPadding * 2 }px)` ,
			position : 'absolute' ,
			top : verticalPadding + 'px' ,
		} ,
		itemLeft : {
			left : `calc(${ dateContainerWidth }%  + ${ iconContainerWidth / 2 }% - ${ dividerThickness / 2 }px + ${ ( horizontalPadding / 2 ) - ( dividerThickness / 2 ) }px)` ,
		} ,
		timelineItem : {
			display : 'flex' ,
			paddingTop : verticalPadding + 'px' ,
			paddingBottom : verticalPadding + 'px' ,
			paddingLeft : `${ horizontalPadding }px` ,
			paddingRight : `${ horizontalPadding }px` ,
			textDecoration : 'none' ,
			color : 'inherit' ,
			transition : theme.transitions.create ( 'background-color' , {
				easing : theme.transitions.easing.easeIn ,
				duration : theme.transitions.duration.easeOut ,
			} ) ,
			'&:hover' : {
				backgroundColor : theme.palette.action.hover ,
			} ,
		} ,
		timeRight : {
			flexDirection : 'row-reverse' ,
		} ,
		timelineDate : {
			display : 'inline-flex' ,
			width : dateContainerWidth + '%' ,
		} ,
		timelineIcon : {
			display : 'inline-flex' ,
			width : `${ iconContainerWidth }%` ,
			position : 'relative' ,
			zIndex : '4' ,
			justifyContent : 'center' ,
		} ,
		icon : {
			width : iconWidth + 'px' ,
			height : iconWidth + 'px' ,
		} ,
		timelineIconBorder : {
			backgroundColor : theme.palette.background.paper ,
			display : "block" ,
			width : ( iconWidth + 1 ) + 'px' ,
			height : ( iconWidth + 1 ) + 'px' ,
			borderRadius : '50%' ,
		} ,
		timelineEvent : {
			display : 'inline-flex' ,
			width : eventContainerWidth + '%' ,
		} ,
		timelineHeading : {
			marginBottom : `${ theme.spacing ( 0 ) }px` ,
		} ,
		timelineDetail : {
			marginBottom : theme.spacing ( 0 )
		} ,
		viewAll : {
			backgroundColor : theme.palette.action.hover ,
			display : 'flex' ,
			justifyContent : 'center' ,
		}
	} ) );
	// Create classed to be used for styling
	const classes = useStyles ();

	// Declaring styles for the button base
	const ButtonBaseStyles = makeStyles ( () => ( {
		root : {
			textAlign : 'left' ,
			display : 'block' ,
		}
	} ) );
	const buttonClasses = ButtonBaseStyles ();

	return (
		<Box className = { classes.root + ' ' + className }>
			<Box
				component = "span"
				className = { classes.timelineInnerContainer + ' ' + classes.itemLeft }
			/>

			{/* One Time Starts*/ }
			{ events.length > 0 ? (
					events.map ( ( event , index ) => (
						index < maxEvents ?
							(
								<ButtonBase key = { event.id } className = { buttonClasses.root }>
									<Box
										component = { Link }
										to = { event.path }
										className = { classes.timelineItem }
									>
										<Typography component = "div" variant = "subtitle2" className = { classes.timelineDate }>
											{ event.date }
										</Typography>

										<div
											className = { classes.timelineIcon }
										>
											<Box className = { classes.timelineIconBorder } component = "span">
												<Album style = { renderIconColor ( event.color , parentTheme ) } className = { classes.icon } />
											</Box>
										</div>
										<div className = { classes.timelineEvent }>
											<div className = { classes.timelineHeading }>
												<Typography className = { classes.timelineHeading } component = "h5" variant = "subtitle2">
													{ event.time }
												</Typography>
												<Typography className = { classes.timelineDetail } component = "p" variant = "body2">
													{ event.detail }
												</Typography>
											</div>
										</div>
									</Box>
								</ButtonBase>
							)
							:
							false
					) )
				)
				:
				(
					<Typography variant = "subtitle2">There are no events to be displayed as of now</Typography>
				)
			}
			{
				displaySeeAll ?
					(
						<Box p = { 1 } className = { classes.viewAll }>
							<Button
								color = { seeAllButtonColor }
								variant = { seeAllButtonVariant }
								size = "small"
								component = { Link }
								to = { seeAllPath }
							>
								{ seeAllButtonText }
							</Button>
						</Box>
					)
					:
					false
			}
		</Box>
	)
}

/**
 * Default propTypes
 */
RapidTimeline.defaultProps = {
	dateContainerWidth : 25 ,
	eventContainerWidth : 60 ,
	iconContainerWidth : 15 ,
	dividerThickness : 4 ,
	iconWidth : 24 ,
	maxEvents : 5 ,
	displaySeeAll : true ,
	events : [] ,
	seeAllButtonText : 'View All Events' ,
	horizontalPadding : 16 ,
	verticalPadding : 24 ,
	seeAllButtonColor : 'default' ,
	seeAllButtonVariant : 'text' ,
	seeAllPath : '#' ,

};

/**
 * Declare the required propTypes for the component
 */
RapidTimeline.propTypes = {
	/**
	 *  A percentage value for the width assigned to the date container. The cummulative width of all the three containers needs to be 100%
	 */
	dateContainerWidth : PropTypes.number ,
	/**
	 *  A percentage value for the width assigned to the event container. The cummulative width of all the three containers needs to be 100%
	 */
	eventContainerWidth : PropTypes.number ,
	/**
	 *  A percentage value for the width assigned to the icon container. The cummulative width of all the three containers needs to be 100%
	 */
	iconContainerWidth : PropTypes.number ,
	/**
	 *  This is the thickness assigned to the divider in px values
	 */
	dividerThickness : PropTypes.number ,
	/**
	 *  Value in px which is assigned as the height as well as the width of the icon.
	 */
	iconWidth : PropTypes.number ,
	/**
	 *  Timline events to be rendered inside the timeline. See the above sample code on how you can structrue your array.
	 */
	events : PropTypes.array ,
	/**
	 *  The maximum number of events that need to be displayed.
	 */
	maxEvents : PropTypes.number ,
	/**
	 *  Whether to display the see all events button or not.
	 */
	displaySeeAll : PropTypes.bool ,
	/**
	 *  The route use needs to be redirected to when she clicks on see all button
	 */
	seeAllPath : PropTypes.string ,
	/**
	 *  The text for the see all events button
	 */
	seeAllButtonText : PropTypes.string ,
	/**
	 *  The color for the see all events button
	 */
	seeAllButtonColor : PropTypes.oneOf ( [ 'primary' , 'secondary' , 'default' ] ) ,
	/**
	 *  The color for the see all events button
	 */
	seeAllButtonVariant : PropTypes.oneOf ( [ 'outlined' , 'contained' , 'text' ] ) ,
	/**
	 *  Padding on the side of each of the item
	 */
	horizontalPadding : PropTypes.number ,
	/**
	 *  Vertical padding on each of the item
	 */
	verticalPadding : PropTypes.number ,
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
};