export default function emitTitle ( title , parentTheme )
{
	// If falsy declare a dummy object
	if ( !title )
	{
		title = {};
	}
	// The actually styling object
	return {
		...title ,
		style : {
			...title.style ,
			fontSize : parentTheme.typography.h5.fontSize ,
			fontWeight : parentTheme.typography.h5.fontWeight ,
			fontFamily : parentTheme.typography.h5.fontFamily ,
			color : parentTheme.palette.text.secondary ,
		} ,
	};
}