import React , { useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import { ComposeEmailState } from "../../../context";

const useStyles = makeStyles ( ( theme ) => ( {
	buttonContainer : {
		padding : theme.spacing ( 2 )
	} ,
	buttonIconWrrapper : {
		minWidth : '0px' ,
		width : 'auto' ,
		marginRight : theme.spacing ( 1 ) ,
	} ,/**/
} ) );

/**
 * RapidComposeEmailButton - Renders the compose email button. It is required that you to embedd RapidComposeEmail separately in your DOM. This component just acts as a trigger for the RapidComposeEmail Dialogue
 * @since version: 1.0.0
 */
export default function RapidComposeEmailButton ( props )
{
	// extract Props
	const { position } = props;

	// Declare classes
	const classes = useStyles ();

	// Get the Context to check the status of the dialogue
	const emailComposeContext = useContext ( ComposeEmailState );

	if ( position === 'top' )
	{
		return (
			<>
				<Box className = { classes.buttonContainer }>
					<Button
						onClick = { () => emailComposeContext.setOpen () }
						variant = "contained"
						size = "large"
						fullWidth
						color = "primary"
					>
						Compose
					</Button>
				</Box>
				<Divider />
			</>
		)
	}
	else
	{
		return (
			<>
				<Divider />
				<Box className = { classes.buttonContainer }>
					<Button
						onClick = { () => emailComposeContext.setOpen () }
						variant = "contained"
						size = "large"
						fullWidth
						color = "primary"
					>
						Compose
					</Button>
				</Box>
			</>
		)
	}
}
