import React from "react";
import { makeStyles , useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

/**
 * RapidPreLoader is a pre-loader that can be used for a smooth transition while the app is loading and between lazy loaded components in RapidAdminReact®.
 * @since version 1.0.0
 */
export default function RapidPreLoader ( props )
{
	const {
		backgroundColor ,
		loaderColor ,
		loaderThickness ,
		className
	} = props;

	// Get the parent theme
	const parentTheme = useTheme ();

	const useStyles = makeStyles ( ( theme ) =>
		( {
			preloaderWrap : {
				display : 'flex' ,
				position : 'fixed' ,
				width : '100.1%' ,
				height : '100.1%' ,
				left : 0 ,
				top : 0 ,
				backgroundColor : backgroundColor ? backgroundColor : parentTheme.palette.background.paper ,
				zIndex : theme.zIndex.tooltip + 5 ,
				justifyContent : 'center' ,
				alignItems : 'center' ,
			} ,
			loader : {}
		} )
	);

	const classes = useStyles ();

	return (

		<Box className = { classes.preloaderWrap + ' ' + className }>
			<CircularProgress thickness = { loaderThickness } color = { loaderColor } className = { classes.loader } />
		</Box>

	)
}

//Assign default props
RapidPreLoader.defaultProps = {
	loaderColor : 'primary' ,
	loaderThickness : 4 ,
	className : null

};

/**
 * propTypes for the component
 */
RapidPreLoader.propTypes = {
	/**
	 *  Pass any color rgba, rgb or #code to render the background color of the preloader
	 */
	backgroundColor : PropTypes.string ,
	/**
	 *  Takes any of 'primary' or 'secondary' color
	 */
	loaderColor : PropTypes.oneOf ( [ 'primary' , 'secondary' ] ) ,
	/**
	 *  The thinkness of the preloader circle. Needs to be a number
	 */
	loaderThickness : PropTypes.number ,

	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,

};
