import React , { Fragment , useContext } from 'react';
import clsx from 'clsx';
import { makeStyles , useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import { useHistory } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";
import {
	MiniSidebarState ,
	MiniSidebarLinkState ,
	MiniSidebarActiveState
}
	from '../../../context';
import RenderTooltip from '../../../tools/RenderToolTip/RenderToolTip';

const useStyles = makeStyles ( ( theme ) => ( {
	root : {
		width : '100%' ,
		backgroundColor : 'transparent' ,
	} ,
	active : {
		backgroundColor : theme.palette.action.selected ,
	} ,
	listRoot : {} ,
	listRootRtl : {
		flexDirection : 'row-reverse' ,
	} ,
	nested : {
		paddingLeft : theme.spacing ( 7 ) ,
	} ,
	nestedRtl : {
		paddingRight : theme.spacing ( 7 ) ,
	} ,
	nestedText : {
		fontSize : theme.typography.body2.fontSize ,
	} ,
	navbarIcon : {
		minWidth : '2.5rem' ,
		color : theme.palette.text.primary ,
	} ,
	navbarIconRtl : {
		minWidth : '2.5rem' ,
		color : theme.palette.text.primary ,
		justifyContent : 'flex-end' ,
	} ,
	navbarIconRtlClosed : {
		justifyContent : 'flex-start !important' ,
	} ,
	badgeStyle : {
		margin : '0 12px' ,
		opacity : 1 ,
	} ,
	badgeHidden : {
		opacity : 0 ,
		display : "none" ,
	} ,
	subheaderShow : {
		margin : '0 12px' ,
		opacity : 1 ,
		display : 'block' ,
	} ,
	subheaderHide : {
		opacity : 0 ,
		display : "none" ,
	} ,
	subheaderRtl : {
		textAlign : 'right' ,
	} ,
	subheader : {
		position : 'relative' ,
	} ,
	primary : {
		whiteSpace : 'normal' ,
		'& span' : {
			fontSize : theme.typography.body2.fontSize + ' !important' ,
		}
	} ,
	primaryRtl : {
		whiteSpace : 'normal' ,
		'& span' : {
			fontSize : theme.typography.body2.fontSize + ' !important' ,
		} ,
		textAlign : 'end' ,
	} ,
	listItemtext : {
		fontSize : theme.typography.body1.fontSize ,
		whiteSpace : 'normal' ,
	} ,
	listItemtextRtl : {
		fontSize : theme.typography.body1.fontSize ,
		whiteSpace : 'normal' ,
		textAlign : 'right'
	} ,
	displayNone : {
		opacity : '0' ,
		display : 'none' ,
	} ,
	hide : {
		opacity : 0 ,
		display : "none" ,
	}
} ) );

export default function RapidNavbarLinks ( props )
{
	// Extract all properties from props
	const { renderToolTip } = props;
	const classes = useStyles ();
	let miniSidebarLinkState = useContext ( MiniSidebarLinkState );
	let id = miniSidebarLinkState.id;
	const navigation = [ ...props.navigation ];
	const history = useHistory ();
	const theme = useTheme ();
	const ltr = theme.direction === 'ltr';

	// Set the state of the sidebar context
	let sidebarContext = useContext ( MiniSidebarState );
	let open = sidebarContext.open;
	// Set the active link  sidebar context
	let activeLinkContext = useContext ( MiniSidebarActiveState );
	let activeLink = activeLinkContext.activeLink;

	// Function to handle links
	const handleLink = ( path , id ) =>
	{
		activeLinkContext.setActiveLink ( id );
		history.push ( path );
	};

	// Function to rendetr a divider
	const renderDivider = ( nav ) =>
	{
		if ( nav )
		{
			return (
				<Box my = { 2 }>
					<Divider />
				</Box>
			)
		}
	};

	// Function to render a badge
	const renderBadge = ( badge ) =>
	{
		if ( badge )
		{
			return (
				<Badge
					className = { clsx ( {
						[ classes.badgeStyle ] : open ,
						[ classes.badgeHidden ] : !open ,
					} ) }
					badgeContent = { badge } color = "primary">
				</Badge>
			)
		}
	};

	return (
		<Fragment>
			<List
				component = "nav"
				className = { classes.root }
			>
				{
					// Start Looping Through the list Items
					navigation.map ( ( nav ) =>
						// Condition to check if there are any Sublinks in the list item
						nav.subLinks ? (
							<Fragment key = { nav.id }>
								{/*Condition to check if there is a Subheader*/ }
								{ nav.subheader ? (
									// Render the List Subheader if there is one
									<ListSubheader
										className = { clsx ( classes.subheader , {
											[ classes.subheaderShow ] : open ,
											[ classes.subheaderHide ] : !open ,
											//[ classes.subheaderRtl ] : rtl ,
										} ) }
										component = "div"
										id = { nav.id + 'subheader' }>
										{ nav.subheader }
									</ListSubheader>
								) : false }
								{/* Condition to check if the theme is in 'ltr' or 'rtl' mode */ }

								<RenderTooltip
									renderToolTip = { !!renderToolTip }
									name = { nav.name }
									placement = { ltr ? ( 'right' ) : ( 'left' ) }
									open = { open }
								>
									<ListItem
										id = { nav.id }
										button
										aria-expanded = { id === nav.id ? "true" : "false" }
										aria-labelledby = { nav.id + nav.id }
										onClick = { ( ( e ) => miniSidebarLinkState.setId ( e , nav.id ) ) }>
										{ nav.icon ? (
												<ListItemIcon className = { classes.navbarIcon }>
													{ nav.icon }
												</ListItemIcon>
											) :
											false
										}
										<ListItemText
											disableTypography
											id = { nav.id + nav.id }
											className = { clsx ( classes.listItemtext , {
												[ classes.displayNone ] : !open ,
											} ) }
											primary = { nav.name }
										/>
										{ renderBadge ( nav.badge ) }
										{ ( nav.id === id ) ?
											<ArrowDropUp
												className = { clsx ( {
													[ classes.hide ] : !open ,
												} ) }
											/>
											:
											<ArrowDropDown
												className = { clsx ( {
													[ classes.hide ] : !open ,
												} ) }
											/>
										}
									</ListItem>
								</RenderTooltip>

								{/* Start rendering the sublink Items */ }
								<Collapse
									in = { nav.id === id }
									timeout = "auto"
									unmountOnExit
									className = { clsx ( {
										[ classes.displayNone ] : !open ,
									} ) }
								>
									<List component = "div" disablePadding>
										{ nav.subLinks.map ( ( sublink ) =>

											//Condition to Check ltr or rtl mode for conditional rendering of sublinks

											<ListItem
												aria-labelledby = { sublink.id + sublink.id }
												onClick = { () => handleLink ( sublink.link , sublink.id ) }
												key = { sublink.id }
												button
												className = { activeLink === sublink.id ? classes.active + ' ' + classes.nested : classes.nested }
											>
												{ sublink.icon ? (
														<ListItemIcon className = { classes.navbarIcon }>
															{ sublink.icon }
														</ListItemIcon>
													) :
													false
												}
												<ListItemText
													disableTypography
													id = { sublink.id + sublink.id }
													primary = { sublink.name }
													className = { clsx ( classes.primary , {
														[ classes.displayNone ] : !open ,
													} ) }
												/>
												{ renderBadge ( sublink.badge ) }
											</ListItem>
										) }
									</List>
								</Collapse>
								{ renderDivider ( nav.divider ) }
							</Fragment>
						) : (
							<Fragment key = { nav.id }>

								{/* Condition to check if there is a Subheader and render it */ }
								{ nav.subheader ? (
									<ListSubheader
										className = { clsx ( classes.subheader , {
											[ classes.subheaderShow ] : open ,
											[ classes.subheaderHide ] : !open ,
											//[ classes.subheaderRtl ] : rtl ,
										} ) }
										component = "div"
										id = { nav.id + 'subheader' }>
										{ nav.subheader }
									</ListSubheader>
								) : false }

								{/* Check if theme is in ltr or rtl mode and render links accordingly */ }

								<RenderTooltip
									renderToolTip = { !!renderToolTip }
									name = { nav.name }
									placement = { ltr ? ( 'right' ) : ( 'left' ) }
									open = { open }
								>
									<ListItem
										aria-labelledby = { nav.id + nav.id }
										button
										className = { activeLink === nav.id ? classes.active : '' }
										onClick = { () => handleLink ( nav.link , nav.id ) } id = { nav.id }>
										{ nav.icon ? (
												<ListItemIcon className = { classes.navbarIcon }>
													{ nav.icon }
												</ListItemIcon>
											) :
											false
										}
										<ListItemText
											disableTypography
											id = { nav.id + nav.id }
											className = { clsx ( classes.listItemtext , {
												[ classes.displayNone ] : !open ,
											} ) }
											primary = { nav.name }
										/>
										{ renderBadge ( nav.badge ) }
									</ListItem>
								</RenderTooltip>
								{ renderDivider ( nav.divider ) }
							</Fragment>
						)
					) }
			</List>
		</Fragment>
	);
}
