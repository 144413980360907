import React from 'react';
import { ThemeProvider , useTheme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import rapidGenerateColoredTheme from "../../../../tools/functions/rapidGenerateColoredTheme/rapidGenerateColoredTheme";

export default function RenderStatus ( props )
{
	// Extract all props
	const { cell } = props;

	// Get the parent theme being used
	let parentTheme = useTheme ();

	// Generate a new theme based on the color selected by the user
	const theme = rapidGenerateColoredTheme ( cell.statusColor , parentTheme )

	return (
		<ThemeProvider theme = { theme }>
			<Chip
				size = "small"
				variant = { cell.statusVariant }
				color = "primary"
				label = { cell.data }
			/>
		</ThemeProvider>
	);
}
