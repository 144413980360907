export default function emitChart ( chart , parentTheme )
{
	// If falsy declare a dummy object
	if ( !chart )
	{
		chart = {
			background : 'transparent' ,
			fontFamily : parentTheme.typography.body1.fontFamily ,
			foreColor : parentTheme.palette.text.secondary ,
		};
	}
	// The actually styling object
	return {
		...chart ,
		background : 'transparent' ,
		fontFamily : parentTheme.typography.body1.fontFamily ,
		foreColor : parentTheme.palette.text.secondary ,
		zoom : {
			enabled : false ,
			...chart.zoom ,
		} ,
		toolbar : {
			show : false ,
			...chart.toolbar ,
		} ,
	};
}