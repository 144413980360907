export default function emitTooltip ( tooltip , parentTheme )
{
	// If falsy declare a dummy object
	if ( !tooltip )
	{
		tooltip = {};
	}
	// The actually styling object
	return {
		...tooltip ,
		style : {
			fontSize : parentTheme.typography.caption.fontSize ,
			fontWeight : parentTheme.typography.caption.fontWeight ,
			fontFamily : parentTheme.typography.caption.fontFamily ,
		} ,
	}
}