import React from "react";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

export default function EmailPagination ( props )
{
	// Extract All Props
	const {
		paginationProps
	} = props;

	// Create Styling Rules
	const useStyles = makeStyles ( () => ( {
		pagination : {
			display : "flex" ,
		} ,
	} ) );
	// Create Classses
	const classes = useStyles ();

	return (
		<Pagination
			className = { classes.pagination }
			count = { 4 }
			size = "small"
			showFirstButton
			showLastButton
			{ ...paginationProps }
		/>
	)
}
