export default function emitSubtitle ( subtitle , parentTheme )
{
	// If falsy declare a dummy object
	if ( !subtitle )
	{
		subtitle = {};
	}
	// The actually styling object
	return {
		...subtitle ,
		style : {
			...subtitle.style,
			fontSize : parentTheme.typography.caption.fontSize ,
			fontWeight : parentTheme.typography.caption.fontWeight ,
			fontFamily : parentTheme.typography.caption.fontFamily ,
			color : parentTheme.palette.text.secondary ,
		} ,
	};
}