// Calendar Actions
export const ADD_EVENT_OPEN = 'ADD_EVENT_OPEN';
export const ADD_EVENT_CLOSE = 'ADD_EVENT_CLOSE';

// Sidebar Actions
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';

// User Related Actions
export const LOGIN_USER = 'LOGIN_USER';
