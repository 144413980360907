/**
 * Declaring object for the default blue color
 */
const rapidPrimary = {
	50 : '#fbe3ea' ,
	100 : '#f5b9ca' ,
	200 : '#ef8ba7' ,
	300 : '#e85d84' ,
	400 : '#e33a69' ,
	500 : '#de174f' ,
	600 : '#da1448' ,
	700 : '#d5113f' ,
	800 : '#d10d36' ,
	900 : '#c80726' ,
	A100 : '#fff2f4' ,
	A200 : '#ffbfc7' ,
	A400 : '#ff8c99' ,
	A700 : '#ff7383' ,
	light : '#e85d84',
	main : '#de174f',
	dark : '#c80726',
};

export default rapidPrimary;
