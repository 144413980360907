export default function emitYAxis ( yaxis , parentTheme )
{
	// If falsy declare a dummy object
	if ( !yaxis )
	{
		yaxis = {};
	}
	// The actually styling object
	return {
		...yaxis ,
		labels : {
			...yaxis.labels ,
			style : {
				fontSize : parentTheme.typography.caption.fontSize ,
				fontFamily : parentTheme.typography.caption.fontFamily ,
				fontWeight : parentTheme.typography.caption.fontWeight ,
			} ,
		} ,
		axisBorder : {
			...yaxis.axisBorder ,
			color : parentTheme.palette.divider ,
		} ,
		axisTicks : {
			...yaxis.axisTicks ,
			color : parentTheme.palette.divider ,
		} ,
		title : {
			...yaxis.title ,
			style : {
				fontSize : parentTheme.typography.caption.fontSize ,
				fontFamily : parentTheme.typography.caption.fontFamily ,
				fontWeight : parentTheme.typography.caption.fontWeight ,
			} ,
		} ,
		crosshairs : {
			...yaxis.crosshairs ,
			stroke : {
				color : parentTheme.palette.divider ,
				width : 0 ,
				dashArray : 0 ,
			} ,
		} ,
		tooltip : {
			...yaxis.tooltip ,
			style : {
				fontSize : parentTheme.typography.caption.fontSize ,
				fontFamily : parentTheme.typography.caption.fontFamily ,
			} ,
		} ,
	};
}