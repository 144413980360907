import React , { useState } from "react";

export const RapidThemeSwitchContext = React.createContext ( {
	theme : 'light' ,
	toggleTheme : () =>
	{
	} ,
} );

export function RapidThemeSwitchContextProvider ( props )
{
	const [ theme , setTheme ] = useState ( 'light' );

	function toggleTheme ( theme )
	{
		setTheme ( theme )
	}

	return (
		<RapidThemeSwitchContext.Provider
			value = {
				{
					theme : theme ,
					toggleTheme : toggleTheme ,
				}
			}
		>
			{ props.children }
		</RapidThemeSwitchContext.Provider>
	)
}

