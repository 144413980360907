/*
 *	RapidMain Component
 * 	This component is used to inject the Rapid Admin React theme to the App.
 */
import React , { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter , HashRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store , persistor } from '../../../../config/redux/reduxStoreCreator';
import settings from "../../../../config/rapidAdmin/settings";
import { makeStyles } from '@material-ui/core/styles';
import { rootContext } from "../../../context/rootContext";
import ComposeContext from "../../../tools/ComposeContext/ComposeContext";
import { RapidScrollbars } from "../../";
import CheckRTL from "../../../tools/CheckRTL/CheckRTL";
import RapidRenderTheme from "../../../tools/RapidRenderTheme/RapidRenderTheme";

const useStyles = makeStyles ( () => ( {
	root : {
		display : 'flex' ,
	} ,
} ) );

// HOC to render the correct router selected by the user
function RenderRouter ( props )
{
	if ( settings.config.router === 'hash' )
	{
		return (
			<HashRouter>
				{ props.children }
			</HashRouter>
		)
	}
	else
	{
		return (
			<BrowserRouter>
				{ props.children }
			</BrowserRouter>
		)
	}
}

// HOC to enable and disable Redux in RapidAdmin
function ManageRedux ( props )
{
	if ( settings.config.redux )
	{
		return (
			<Provider store = { store }>
				{ props.children }
			</Provider>
		)
	}
	else
	{
		return (
			<Fragment>
				{ props.children }
			</Fragment>
		)
	}
}

/**
 * This component is used to inject the Rapid Admin React theme to the App. This is the main component and enabels the selected functionality in your app
 *
 * For the list of fucntionality added by `RapidMain` have a look at this link [RapidMain Functionality](https://react-docs.rapidadm.in/docs/rapidMain/)
 *
 * ##### Syntax:
 * ```jsx
 * <RapidMain>
 * 	// Nest Your Other Strutural Components to create a layout
 * </RapidMain>
 */
export default function RapidMain ( props )
{
	// Ste the classes to be used to style the component
	const classes = useStyles ();

	return (
		<ManageRedux>
			<PersistGate loading = { null } persistor = { persistor }>
				<ComposeContext components = { rootContext }>
					<RenderRouter>
						<RapidRenderTheme>
							<CssBaseline>
								<CheckRTL>
									<RapidScrollbars>
										<div
											className = { classes.root }
										>
											{ props.children }
										</div>
									</RapidScrollbars>
								</CheckRTL>
							</CssBaseline>
						</RapidRenderTheme>
					</RenderRouter>
				</ComposeContext>
			</PersistGate>
		</ManageRedux>
	);
}
