// Creating the actions component for each email
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

export default function RenderTableActions ( props )
{
	// Extract all the props
	const
		{
			cell
		} = props;

	// Declare styling rules
	const useStyles = makeStyles ( ( theme ) => ( {
		wrapper : {
			'& ul' : {
				padding : theme.spacing ( 0 ) ,
			}
		} ,
		actionItem : {
			padding : theme.spacing ( 1.25 , 2 ) ,
			display : 'flex' ,
			alignItems : 'center' ,
		} ,
		actionIcon : {
			minWidth : 'auto' ,
			marginRight : theme.spacing ( 1 ) ,
			'& svg' : {
				fontSize : theme.typography.body1.fontSize ,
				fontWeight : theme.typography.body1.fontWeight ,
				fontFamily : theme.typography.body1.fontFamily ,
				lineHeight : theme.typography.body1.lineHeight ,
			}
		} ,
		actionText : {
			fontSize : theme.typography.body1.fontSize ,
			fontWeight : theme.typography.body1.fontWeight ,
			fontFamily : theme.typography.body1.fontFamily ,
			lineHeight : theme.typography.body1.lineHeight ,
		}
	} ) );
	// Create classes
	const classes = useStyles ();

	const [ anchorEl , setAnchorEl ] = React.useState ( null );
	const open = Boolean ( anchorEl );

	const handleClick = ( event ) =>
	{
		setAnchorEl ( event.currentTarget );
	};

	const handleClose = () =>
	{
		setAnchorEl ( null );
	};

	return (
		<>
			<IconButton
				aria-label = "more"
				aria-controls = "long-menu"
				aria-haspopup = "true"
				onClick = { handleClick }
				size = "small"
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id = "long-menu"
				anchorEl = { anchorEl }
				keepMounted
				className = { classes.wrapper }
				open = { open }
				onClose = { handleClose }
				PaperProps = { {
					style : {
						width : '150px' ,
						padding : '0px' ,
					} ,
				} }
			>
				{ cell.data.map ( ( action , index ) => (
					<MenuItem
						className = { classes.actionItem }
						key = { ( cell.id + index ).toString () }
						id = { cell.id }
						onClick = {
							( e ) =>
							{
								action.onActionClick ( e , cell.id );
								handleClose ();
							}
						}
					>
						<ListItemIcon id = { action.actionId } className = { classes.actionIcon }>
							{ action.actionIcon }
						</ListItemIcon>
						<Box
							id = { action.actionId }
							component = "span"
							className = { classes.actionText }
						>
							{ action.actionLabel }
						</Box>
					</MenuItem>
				) ) }
			</Menu>
		</>
	);
}

RenderTableActions.defaultProps = {};

RenderTableActions.propTypes = {
	/**
	 *  Cell object/
	 */
	cell : PropTypes.object
};

