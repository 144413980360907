import { createMuiTheme } from '@material-ui/core/styles';
import settings from "../rapidAdmin/settings";
import rapidPrimary from './colors/rapidPrimary'
import rapidSecondary from './colors/rapidSecondary'
import rapidRed from "./colors/rapidRed";
import rapidGrey from "./colors/rapidGrey";
import rapidAmber from "./colors/rapidAmber";
import rapidGreen from "./colors/rapidGreen";
import rapidLightBlue from "./colors/rapidLightBlue";
import rapidTextColor from "./lightTheme/rapidTextColor";
import rapidActionColors from "./lightTheme/rapidActionColors";
import rapidBackground from "./lightTheme/rapidBackground";
import rapidTypography from "./typography/rapidTypography";

const theme = createMuiTheme ( {
	typography : rapidTypography ,
	direction : settings.config.themeDirection ,
	palette : {
		type : 'light' ,
		primary : rapidPrimary ,
		secondary : rapidSecondary ,
		error : rapidRed ,
		warning : rapidAmber ,
		info : rapidLightBlue ,
		success : rapidGreen ,
		grey : rapidGrey ,
		text : rapidTextColor ,
		action : rapidActionColors ,
		background : rapidBackground ,
		divider : 'rgba(0, 0, 0, 0.12)' ,
	} ,
	shape : {
		borderRadius : 4
	} ,
	zIndex : {
		mobileStepper : 1000 ,
		speedDial : 1050 ,
		appBar : 1100 ,
		drawer : 1200 ,
		modal : 1300 ,
		snackbar : 1400 ,
		tooltip : 1500 ,
	} ,
	transitions : {
		duration : {
			shortest : 150 ,
			shorter : 200 ,
			short : 250 ,
			standard : 300 ,
			complex : 375 ,
			enteringScreen : 250 ,
			leavingScreen : 250 ,
		}
	}
} );

export default theme;
