/**
 * Declaring object for the default blue color
 */
const rapidAmber = {
    50: '#FFF6E8',
    100: '#FFE8C5',
    200: '#FFD99F',
    300: '#FFC979',
    400: '#FFBE5C',
    500: '#FFB23F',
    600: '#FFAB39',
    700: '#FFA231',
    800: '#FF9929',
    900: '#FF8A1B',
    A100: '#FFFFFF',
    A200: '#FFFFFF',
    A400: '#FFE3CC',
    A700: '#FFD5B3'
};

export default rapidAmber;
