import React , { useState } from "react";

export const MiniSidebarScrollPosition = React.createContext ( {
	position : 0 ,
	setScrollPosition : () =>
	{
	} ,
} );

export function MiniSidebarScrollContextProvidor ( props )
{
	const [ position , setPosition ] = useState ( 0 );

	function positionSetter (position)
	{
		setPosition(position)
	}

	return (
		<MiniSidebarScrollPosition.Provider
			value = {
				{
					position : position ,
					setScrollPosition : positionSetter ,
				}
			}
		>
			{ props.children }
		</MiniSidebarScrollPosition.Provider>
	)
}

