import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
//import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Box from "@material-ui/core/Box";
import clsx from "clsx";

const useStyles = makeStyles ( ( theme ) => ( {

	'@global' : {
		':root' : {
			/* Overrides the border radius setting in the theme. */
			'--ck-border-radius' : theme.shape.borderRadius + 'px' ,

			/* Overrides the default font size in the theme. */
			'--ck-font-size-base' : theme.typography.body1.fontSize ,

			/* Helper variables to avoid duplication in the colors. */
			'--ck-custom-background' : theme.palette.background.paper ,
			'--ck-custom-foreground' : theme.palette.background.paper ,
			'--ck-custom-border' : theme.palette.action.disabled ,
			'--ck-color-base-border' : theme.palette.action.disabled ,
			'--ck-custom-white' : theme.palette.common.white ,

			/* -- Overrides generic colors. ------------------------------------------------------------- */

			'--ck-color-base-foreground' : 'var(--ck-custom-background)' ,
			'--ck-color-focus-border' : theme.palette.action.disabled ,
			'--ck-color-text' : theme.palette.text.primary ,
			'--ck-color-shadow-drop' : 'hsla(0, 0%, 0%, 0.2)' ,
			'--ck-color-shadow-inner' : 'hsla(0, 0%, 0%, 0)' ,

			/* -- Overrides the default .ck-button class colors. ---------------------------------------- */

			'--ck-color-button-default-background' : 'var(--ck-custom-background)' ,
			'--ck-color-button-default-hover-background' : theme.palette.action.hover ,
			'--ck-color-button-default-active-background' : theme.palette.action.hover ,
			'--ck-color-button-default-active-shadow' : 'none' ,
			'--ck-color-button-default-disabled-background' : 'var(--ck-custom-background)' ,

			'--ck-color-button-on-background' : 'var(--ck-custom-foreground)' ,
			'--ck-color-button-on-hover-background' : theme.palette.action.hover ,
			'--ck-color-button-on-active-background' : theme.palette.action.hover ,
			'--ck-color-button-on-active-shadow' : 'none' ,
			'--ck-color-button-on-disabled-background' : 'var(--ck-custom-foreground)' ,

			'--ck-color-button-action-background' : theme.palette.primary.main ,
			'--ck-color-button-action-hover-background' : theme.palette.primary.dark ,
			'--ck-color-button-action-active-background' : theme.palette.primary.main ,
			'--ck-color-button-action-active-shadow' : 'none' ,
			'--ck-color-button-action-disabled-background' : theme.palette.primary.light ,
			'--ck-color-button-action-text' : 'var(--ck-custom-white)' ,

			'--ck-color-button-save' : theme.palette.primary.main ,
			'--ck-color-button-cancel' : ' hsl(15, 100%, 56%)' ,

			/* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

			'--ck-color-dropdown-panel-background' : 'var(--ck-custom-background)' ,
			'--ck-color-dropdown-panel-border' : 'var(--ck-custom-foreground)' ,

			/* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

			'--ck-color-split-button-hover-background' : 'var(--ck-color-button-default-hover-background)' ,
			'--ck-color-split-button-hover-border' : 'var(--ck-custom-foreground)' ,

			/* -- Overrides the default .ck-input class colors. ----------------------------------------- */

			'--ck-color-input-background' : 'var(--ck-custom-foreground)' ,
			'--ck-color-input-border' : theme.palette.action.disabled ,
			'--ck-color-input-text' : theme.palette.text.primary ,
			'--ck-color-input-disabled-background' : theme.palette.action.disabled ,
			'--ck-color-input-disabled-border' : theme.palette.action.disabled ,
			'--ck-color-input-disabled-text' : theme.palette.text.secondary ,

			/* -- Overrides the default .ck-list class colors. ------------------------------------------ */

			'--ck-color-list-background' : 'var(--ck-custom-background)' ,
			'--ck-color-list-button-hover-background' : 'var(--ck-color-base-foreground)' ,
			'--ck-color-list-button-on-background' : 'var(--ck-color-base-active)' ,
			'--ck-color-list-button-on-background-focus' : 'var(--ck-color-base-active-focus)' ,
			'--ck-color-list-button-on-text' : 'var(--ck-color-base-background)' ,

			/* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

			'--ck-color-panel-background' : 'var(--ck-custom-background)' ,
			'--ck-color-panel-border' : 'var(--ck-custom-border)' ,
			/* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

			'--ck-color-toolbar-background' : 'var(--ck-custom-background)' ,
			'--ck-color-toolbar-border' : 'var(--ck-custom-border)' ,

			/* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

			'--ck-color-tooltip-background' : 'hsl(252, 7%, 14%)' ,
			'--ck-color-tooltip-text' : 'hsl(0, 0%, 93%)' ,

			/* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

			'--ck-color-image-caption-background' : theme.palette.background.default ,
			'--ck-color-image-caption-text' : theme.palette.text.secondary ,

			/* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

			'--ck-color-widget-blurred-border' : theme.palette.action.disabled ,
			'--ck-color-widget-hover-border' : theme.palette.primary.main ,
			'--ck-color-widget-editable-focus-background' : 'var(--ck-custom-white)' ,

			/* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

			'--ck-color-link-default' : theme.palette.primary.main ,

			/* -- New Variables found out  ---------------------- */
			'--ck-icon-size' : theme.typography.subtitle1.fontSize ,
			'--ck-focus-outer-shadow' : 'rgba(0,0,0,0)' ,
			'--ck-color-base-background' : theme.palette.background.paper ,
			'--ck-color-base-active-focus' : theme.palette.primary[ 700 ] ,
			'--ck-color-base-active' : theme.palette.primary.main ,

		} ,
	} ,
	editor : {
		'& .ck-button__label' : {
			color : theme.palette.text.primary + ' !important' ,
		} ,
	} ,

	checkboxType : {
		display : 'inline-flex' ,
		minheight : '200px' ,
	} ,
	bgColor : {
		backgroundColor : '#ffffff' ,
		'& .MuiInputLabel-shrink' : {
			backgroundColor : '#ffffff' ,
			transform : 'translate(14px, -16px) scale(0.75)' ,
			padding : theme.spacing ( 1.5 ) ,
		} ,
		'& p' : {
			backgroundColor : 'rgba(255,255,255,0)' ,
		} ,
	} ,
	buttonSection : {
		display : 'flex' ,
		marginTop : theme.spacing ( 3 )
	} ,
	button : {
		marginRight : theme.spacing ( 1 )
	} ,
	gridClass : {
		display : 'flex' ,
		alignItems : 'center' ,
	} ,
} ) );

/**
 *  RapidCkeEditorClassic - RapidAdmin is compatible with CKEditor. Use this component to embedd the CKEditor within your application.
 *  **Please Note:** We only provide compatibility with the CKEditor by giving it a theme to match the styling of RapidAdmin React and do no give you a license to use the CKEditor in your application. If your application comes under a
 * commercial license of the CKEditor please speak to the [CKEditor team](https://ckeditor.com/pricing/) and they should be able to help you with the kind of license you should be using for your application
 */
export default function RapidCkeEditorClassic ( props )
{
	// Extract All props
	const {
		className ,
		minHeight ,
		scrollOverflow ,
		ckEditorProps ,
	} = props;

	// Create styles that need to be changed using props
	const changeStyles = makeStyles ( () => ( {
		changeEditor : {
			'& .ck-content' : {
				height : minHeight ,
			} ,
		} ,
		scroll : {
			overflowY : 'auto' ,
		}
	} ) )
	// Create the classes
	const newClasses = changeStyles ();

	// Declare classes for styling
	const classes = useStyles ();

	return (
		<Box
			className = { clsx ( classes.editor + ' ' + newClasses.changeEditor + ' ' + className , {
				[ newClasses.scroll ] : scrollOverflow ,
			} ) }
		>
			<CKEditor
				config = {
					{
						toolbar : [ 'heading' , '|' , 'bold' , 'italic' , 'bulletedList' , 'numberedList' , 'blockQuote' ] ,
						placeholder : "Start typing ...." ,
					}
				}
				editor = { ClassicEditor }
				onChange = { ( event , editor ) =>
				{
					const data = editor.getData ();
					console.log ( { event , editor , data } );
				} }
				{ ...ckEditorProps }
			/>
		</Box>
	);
}

RapidCkeEditorClassic.defaultProps = {
	minHeight : '200px' ,
	scrollOverflow : true ,
};

RapidCkeEditorClassic.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,

	/**
	 *  The minimum height that you want to assign to the editor. Any valid CSS heightb property passed as a string.
	 */
	minHeight : PropTypes.string ,

	/**
	 *  When set to true the content will have a scrollbar in the editor area instead of the editor out expanding in height.
	 */
	scrollOverflow : PropTypes.bool ,
	/**
	 * You can use any CKEditor props passed as an object. All the props for CKEditor can we check in the [CKEditor React
	 * Documentation](https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html#component-properties)
	 */
	ckEditorProps : PropTypes.object ,
};