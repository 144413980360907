import React , { useContext , useState , useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SelectAllEmail } from "../../../../context";

export default function SelectAllEmailCheckbox ()
{
	// Declaring the styling rules
	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			margin : theme.spacing ( 0 , 1 , 0 , 0 ) ,
		} ,
	} ) );
	// creating classes
	const classes = useStyles ();

	// Use the context for the Select All email
	const selectAllContext = useContext ( SelectAllEmail );

	// Declare the state for th checkbox
	const [ checked , setChecked ] = useState ( false );

	// Handle change checkbox method
	const handleChange = ( event ) =>
	{
		setChecked ( event.target.checked );
	};

	// Pass the checked state to the context as soon as it is changed
	useEffect ( () =>
	{
		selectAllContext.toggleSelectAll ( checked );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ checked ] )

	return (
		<FormControlLabel
			className = { classes.root }
			control = {
				<Checkbox
					checked = { checked }
					onChange = { handleChange }
					name = "selectAll"
					color = "primary"
				/>
			}
			label = "Select All"
		/>
	)
}

SelectAllEmailCheckbox.defaultProps = {};

SelectAllEmailCheckbox.propTypes = {};