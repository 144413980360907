import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

// HOC to Optionally Render ToolTip
export default function RenderTooltip ( props )
{
	let { name , placement , open, renderToolTip } = props;
	return (
		open ? props.children :
			(
				renderToolTip ?
					(
						<Tooltip
							title = { name }
							placement = { placement }
							arrow
						>
							{ props.children }
						</Tooltip>
					) : (
						props.children
					)
			)
	)
}