import React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

export default function RapidSwitch ( props )
{
	//Extract All Props
	const {
		className ,
		checked ,
		onChange ,
		disabled ,
		offColor ,
		onColor ,
		offHandleColor ,
		onHandleColor ,
		uncheckedIcon ,
		checkedIcon ,
		boxShadow ,
		activeBoxShadow ,
		height ,
		width ,
		id ,

	} = props;

	const useStyles = makeStyles ( () => ( {
		root : {
			display : 'inline-flex' ,
			alignItems : 'center'
		}
	} ) );
	// get the classes
	const classes = useStyles ();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label htmlFor = { id } className = { classes.root }>
			<Switch
				className = { className }
				onChange = { onChange }
				checked = { checked }
				disabled = { disabled }
				offColor = { offColor }
				onColor = { onColor }
				offHandleColor = { offHandleColor }
				onHandleColor = { onHandleColor }
				uncheckedIcon = { uncheckedIcon }
				checkedIcon = { checkedIcon }
				boxShadow = { boxShadow }
				activeBoxShadow = { activeBoxShadow }
				height = { height }
				width = { width }
				id = { id }
			/>
		</label>
	);
}

RapidSwitch.defaultProps = {
	checked : false ,
	disabled : false ,
	offColor : '#565656' ,
	onColor : '#1b3f70' ,
	offHandleColor : '#ffffff' ,
	onHandleColor : '#ffffff' ,
	uncheckedIcon : false ,
	checkedIcon : false ,
	activeBoxShadow : '0 0 2px 3px #3bf' ,
	height : 28 ,
	width : 56 ,
};

RapidSwitch.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  **|Required|** If true, the switch is set to checked. If false, it is not checked.
	 */
	checked : PropTypes.bool ,
	/**
	 *  **|Required|** Invoked when the user clicks or drags the switch. It is passed three arguments: checked, which is a boolean that describes the presumed future state of the checked prop (1), the event object (2) and the id prop (3).
	 */
	onChange : PropTypes.func ,
	/**
	 *  When disabled, the switch will no longer be interactive and its colors will be greyed out.
	 */
	disabled : PropTypes.bool ,
	/**
	 * The switch will take on this color when it is not checked. Only accepts hex-colors.
	 */
	offColor : PropTypes.string ,
	/**
	 * The switch will take on this color when it is checked. Only accepts hex-colors.
	 */
	onColor : PropTypes.string ,
	/**
	 * The handle of the switch will take on this color when it is not checked. Only accepts hex-colors.
	 */
	offHandleColor : PropTypes.string ,
	/**
	 * The handle of the switch will take on this color when it is not checked. Only accepts hex-colors.
	 */
	onHandleColor : PropTypes.string ,
	/**
	 * The diameter of the handle, measured in pixels. By default it will be 2 pixels smaller than the height of the switch.
	 */
	handleDiameter : PropTypes.number ,
	/**
	 * An icon that will be shown on the switch when it is not checked. Pass in false if you don't want any icon.
	 */
	uncheckedIcon : PropTypes.node || PropTypes.bool ,
	/**
	 * An icon that will be shown on the switch when it is checked. Pass in false if you don't want any icon.
	 */
	checkedIcon : PropTypes.node || PropTypes.bool ,
	/**
	 * The default box-shadow of the handle. You can read up on the box-shadow syntax [on MDN](https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow?v=b).
	 */
	boxShadow : PropTypes.string ,
	/**
	 * The box-shadow of the handle when it is active or focused. Do not set this to null, since it is important for accessibility.
	 */
	activeBoxShadow : PropTypes.string ,
	/**
	 * The height of the background of the switch, measured in pixels.
	 */
	height : PropTypes.number ,
	/**
	 * The height of the background of the switch, measured in pixels.
	 */
	width : PropTypes.number ,
	/**
	 * Set as an attribute to the embedded checkbox. This is useful for the associated label, which can point to the id in its htmlFor attribute.
	 */
	id : PropTypes.string ,

};