import React , { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/SearchRounded";
import FormControl from "@material-ui/core/FormControl";

export default function EmailSearch ( props )
{
	//Extract All Props
	const { searchInputProps } = props;

	// Creating styling Rules
	const useStyles = makeStyles ( ( theme ) => ( {
		searchField : {
			marginRight : theme.spacing ( 2 ) ,
			minWidth : "225px" ,
		} ,
	} ) );
	// Create classes for styling
	const classes = useStyles ();
	// Set the state of the search field
	const [ value , setValues ] = useState ();
	// Set the value of the search field on change
	const handleValueChange = ( event ) =>
	{
		setValues ( event.target.value );
	};

	return (
		<FormControl size = "small" className = { classes.searchField } variant = "outlined">
			<InputLabel htmlFor = "outlined-adornment-amount">Search</InputLabel>
			<OutlinedInput
				id = "outlined-adornment-amount"
				value = { value }
				onChange = { handleValueChange }
				startAdornment = { <InputAdornment position = "start"><Search /></InputAdornment> }
				labelWidth = { 60 }
				{ ...searchInputProps }
			/>
		</FormControl>
	)
}

// Default props
EmailSearch.defaultProps = {};

// Props Types
EmailSearch.propTypes = {};