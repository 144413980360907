import React from "react";
import PropTypes from 'prop-types';
import clsx from "clsx";
import { makeStyles , useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";

/**
 * RapidPageTitles is a component for rendering the page titles for different pages.
 *
 * Below id an example of how you can define a menu that needs to be rendered as breachcrumbs in the Page Title.
 *
 * ```javascript
 * // Import the required Icons
 * import HomeIcon from '@material-ui/icons/Home';
 *
 *const menu = [
	{
		id : 'menu1' ,
		label : 'Home' ,
		path : '/' ,
		icon : ( <HomeIcon /> ) ,
		finalPage : false ,
	} ,
	{
		id : 'menu2' ,
		label : 'Components' ,
		path : '/' ,
		icon : false ,
		finalPage : false ,
	} ,
	{
		id : 'menu3' ,
		label : 'Buttons' ,
		path : '/' ,
		icon : false ,
		finalPage : true ,
	}
 ];
 *
 * ```
 * @since version 1.0.0
 */
export default function RapidPageTitles ( props )
{
	// Extract All The props of the component
	const {
		title ,
		className ,
		verticalPadding ,
		horizontalPadding ,
		titleProps ,
		breadcrumbsProps ,
		linkProps ,
		menu ,
		breadcrumbs ,
		paperProps ,
		ariaBreadcrumbMenu ,
		titleMarginBottom ,
	} = props;

	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			paddingTop : verticalPadding ,
			paddingBottom : verticalPadding ,
			paddingLeft : horizontalPadding ,
			paddingRight : horizontalPadding ,
			display : 'flex' ,
			flexDirection : 'column' ,
			width : '100%'
		} ,
		rtl : {
			flexDirection : 'row-reverse' ,
		} ,
		link : {
			display : 'flex' ,
			alignItems : 'center' ,
			fontFamily : theme.typography.body1.fontFamily ,
			fontSize : theme.typography.body1.fontSize ,
			fontWeight : theme.typography.body1.fontWeight ,
			lineHeight : theme.typography.body1.lineHeight ,
		} ,
		linkLtr : {
			'& svg' : {
				marginRight : theme.spacing ( 1 ) ,
			}
		} ,
		linkRtl : {
			'& svg' : {
				marginLeft : theme.spacing ( 1 ) ,
			}
		} ,
		icon : {
			marginRight : theme.spacing ( 0.5 ) ,
			width : 17 ,
			height : 17 ,
		} ,
		title : {
			display : 'flex' ,
			marginBottom : theme.spacing ( titleMarginBottom ) ,
		} ,
		breadcrumbs : {
			display : 'flex' ,
		}
	} ) );

	// Function to render links
	function renderLink ( link , linkProps )
	{
		return (
			<Link
				key = { link.id }
				component = { LinkRouter }
				to = { link.path }
				color = "textSecondary"
				className = { clsx ( classes.link , {
					[ classes.linkLtr ] : ltr ,
					[ classes.linkRtl ] : rtl ,
				} ) }
				{ ...linkProps }
			>
				{ theme.direction === 'ltr' ? (
						<>
							{ link.icon ? ( link.icon ) : false }
							{ link.label }
						</>
					)
					:
					(
						<>
							{ link.icon ? ( link.icon ) : false }
							{ link.label }
						</>
					)
				}
			</Link>
		)
	}

	// Function to render links
	function renderFinal ( link )
	{
		return (
			<Typography key = { link.id } variant = { "body1" } color = "textSecondary">
				{ link.label }
			</Typography>
		)
	}

	// Declare the required constants
	const classes = useStyles ();
	const theme = useTheme ();
	const ltr = theme.direction === 'ltr';
	const rtl = theme.direction === 'rtl';

	return (
		<Paper
			variant = "outlined"
			className = { classes.root + ' ' + className }
			{ ...paperProps }
		>
			<Typography
				className = { clsx ( classes.title , {
					//[ classes.rtl ] : rtl
				} ) }
				variant = "h2"
				component = "h1"
				color = "textPrimary"
				{ ...titleProps }
			>
				{ title }
			</Typography>

			{ breadcrumbs && menu ?
				(
					<Breadcrumbs
						className = { clsx ( classes.breadcrumbs , {
							//[ classes.rtl ] : rtl
						} ) }
						aria-label = { ariaBreadcrumbMenu }
						{ ...breadcrumbsProps }
					>
						{ menu.map ( ( link ) => (
							link.finalPage ?
								( renderFinal ( link ) )
								:
								( renderLink ( link , linkProps ) )
						) ) }

					</Breadcrumbs>
				)
				:
				false
			}
		</Paper>
	)
}

//Assign default props
RapidPageTitles.defaultProps = {
	title : 'This is the page title' ,
	verticalPadding : '15px' ,
	horizontalPadding : '15px' ,
	breadcrumbs : true ,
	ariaBreadcrumbMenu : 'breadcrumb' ,
	titleMarginBottom : .5 ,
};

/**
 * propTypes for the component
 */
RapidPageTitles.propTypes = {
	/**
	 *  The main title of the page.
	 */
	title : PropTypes.string ,

	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,

	/**
	 *  Vertical padding assigned to the component
	 */
	verticalPadding : PropTypes.string ,

	/**
	 *  Horizontal padding assigned to the component
	 */
	horizontalPadding : PropTypes.string ,

	/**
	 *  Use any props from Material UI [Typography API](https://material-ui.com/api/typography/) as an object
	 */
	titleProps : PropTypes.object ,

	/**
	 *  Use any props from Material UI [Breadcrumbs API](https://material-ui.com/api/breadcrumbs/) as an object
	 */
	breadcrumbsProps : PropTypes.object ,

	/**
	 *  Use any props from Material UI [Link API](https://material-ui.com/api/link/) as an object
	 */
	linkProps : PropTypes.object ,

	/**
	 *  The breadcrumb menu that needs to be rendered by the component. See the above example of the array of objects that you need to pass.
	 */
	menu : PropTypes.array ,

	/**
	 *  The breadcrumb menu that needs to be rendered by the component. See the above example of the array of objects that you need to pass.
	 */
	breadcrumbs : PropTypes.bool ,

	/**
	 *  Use any props from Material UI [Paper API](https://material-ui.com/api/paper/) as an object
	 */
	paperProps : PropTypes.object ,

	/**
	 * Aria label for the breacdcrumb menu.
	 */
	ariaBreadcrumbMenu : PropTypes.string ,

	/**
	 * Margin bottom assigned to the title. Using the `theme.spacing()` method of mater UI and can contain a floating poijnt value to render the margin. Material UI [Spacing](https://material-ui.com/customization/spacing/#spacing)
	 */
	titleMarginBottom : PropTypes.number ,

};