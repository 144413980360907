import React , { useState } from 'react';
import PropTypes from "prop-types"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles ( ( theme ) => ( {
	rapidQuillWrap : {
		'& .quill' : {

			'& .ql-toolbar' : {
				border : `1px solid ${ theme.palette.action.active }` ,
				borderTopRightRadius : theme.shape.borderRadius ,
				borderTopLeftRadius : theme.shape.borderRadius ,
			} ,
			'&  .ql-container' : {
				border : `1px solid ${ theme.palette.action.active }` ,
				borderBottomRightRadius : theme.shape.borderRadius ,
				borderBottomLeftRadius : theme.shape.borderRadius ,
			} ,

			'& .ql-formats, .ql-picker-label, .ql-picker-options, .ql-picker, .ql-picker-item, svg' : {
				'&:focus' : {
					outline : 'none !important' ,
					border : '0px' ,
				} ,
			} ,

			'& .ql-picker' : {
				fontWeight : theme.typography.body1.fontWeight ,
				fontSize : theme.typography.body1.fontSize ,
				fontFamily : theme.typography.body1.fontFamily ,
				color : theme.palette.text.secondary ,
				width : '105px' ,
				'&:focus' : {
					outline : 'none' ,
				} ,
				'& svg.ql-stroke' : {
					stroke : theme.palette.text.secondary ,
				} ,
			} ,

			'& .ql-picker-options' : {
				backgroundColor : theme.palette.background.paper ,
				border : '0px' ,
				boxShadow : theme.shadows[ 6 ] ,
				bordeRadius : theme.shape.bordeRadius ,
			}

		} ,

	}
} ) );

export default function RapidQuillEditor ( props )
{
	// Extract all props
	const {
		className ,
		quillProps ,
	} = props;

	// Create classes for styling
	const classes = useStyles ();
	// Set state for the React Quill value on editing
	const [ value , setValue ] = useState ( '' )

	return (
		<Box
			className = { classes.rapidQuillWrap + ' ' + className }
			component = "span"
		>
			<ReactQuill
				theme = "snow"
				value = { value }
				onChange = { setValue }
				{ ...quillProps }
			/>
		</Box>
	);
};

// Default props
RapidQuillEditor.defaultProps = {};

// Proptypes
RapidQuillEditor.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 * Pass an object of proptypes for the Quill editor. You can use any proptypes available for [React Quill in the documentation](https://github.com/zenoamaro/react-quill#exports)
	 */
	quillProps : PropTypes.object ,
};