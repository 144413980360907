import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { Link } from "react-router-dom";

// HOC to conditionally open in a new tab
function NewTabCheck ( props )
{
	// Extract props
	const { cell } = props;

	if ( cell.newTab )
	{
		return (
			<IconButton
				aria-label = "upload picture"
				component = { Link }
				to = { cell.data }
				target = "_blank"
			>
				<OpenInNewOutlinedIcon />
			</IconButton>
		)
	}
	else
	{
		return (
			<IconButton
				aria-label = "upload picture"
				component = { Link }
				to = { cell.data }
			>
				<OpenInNewOutlinedIcon />
			</IconButton>
		)
	}

}

export default function RenderLinkButton ( props )
{
	// Extract all props
	const { cell } = props;

	return (
		<NewTabCheck cell = { cell } />
	);
}

