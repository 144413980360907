import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import uniqid from "uniqid";

// USe https://www.npmjs.com/package/uniqid

// Create Styling
const useStyles = makeStyles ( ( theme ) => ( {
	avatar : {
		cursor : 'pointer' ,
		border : `2px solid ${ theme.palette.background.paper }` ,
		'&:hover' : {
			boxShadow : theme.shadows[ 8 ] ,
			zIndex : '100' ,
		} ,
		'&:focus' : {
			boxShadow : theme.shadows[ 4 ] ,
			zIndex : '100' ,
		} ,
		'&:first-child' : {
			marginLeft : theme.spacing ( 0 ) ,
		}
	} ,
	avatarGroup : {
		'& .MuiAvatarGroup-avatar' : {
			fontSize : theme.typography.subtitle2.fontSize ,
			fontWeight : theme.typography.subtitle2.fontWeight ,
			fontFamily : theme.typography.subtitle2.fontFamily ,
			lineHeight : theme.typography.subtitle2.lineHeight ,
		}
	} ,
	spaceSmall : {
		marginLeft : theme.spacing ( - 2 )
	} ,
	spaceMedium : {
		marginLeft : theme.spacing ( - 1 )
	}
} ) );

// Conditionally render Tooltip
function ConditionalTooltip ( props )
{
	// Extract Props
	const {
		data ,
	} = props;

	if ( data.tooltip )
	{
		return (
			<Tooltip
				key = { uniqid ( 'rapid-' ) }
				placement = "top"
				title = { data.tooltip }
				enterDelay = { 0 }
				leaveDelay = { 0 }
				enterNextDelay = { 0 }
				arrow
			>
				{ props.children }
			</Tooltip>
		)
	}
	else
	{
		return (
			props.children
		)
	}
}

export default function RenderAvatar ( props )
{
	// Extract all props
	const { cell } = props;

	// Create classes
	const classes = useStyles ();

	return (
		<AvatarGroup
			max = { cell.maxAvatars ? cell.maxAvatars : 5 }
			spacing = { cell.avatarSpacing ? cell.avatarSpacing : 'medium' }
			className = { classes.avatarGroup }
			key = { uniqid ( 'rapid-' ) }
		>
			{ cell.data && Array.isArray ( cell.data ) ? (
				cell.data.map ( ( item , index ) => (
					<ConditionalTooltip
						key = { uniqid ( 'rapid-' ) }
						data = { item }
					>
						<Avatar
							key = { uniqid ( 'rapid-' ) }
							component = { Link }
							to = { item.link }
							className = { clsx ( classes.avatar , {
								[ classes.spaceSmall ] : cell.avatarSpacing === 'small' && index !== 0 ,
								[ classes.spaceMedium ] : cell.avatarSpacing === 'medium' && index !== 0 ,
							} ) }
							alt = { item.alt }
							src = { item.src }
							variant = { item.variant ? item.variant : 'circle' }
						/>
					</ConditionalTooltip>
				) )
			) : console.error ( 'You have not passed a valid data for the avatar in the RapidTaskStatusTable' )
			}
		</AvatarGroup>

	)
}