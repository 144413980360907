import React from "react";
import PropTypes from "prop-types";
import RenderAvatar from "./RenderAvatar";
import RenderString from "./RenderString";
import RenderProgress from "./RenderProgress";
import RenderStatus from "./RenderStatus";
import RenderLinkButton from "./RenderLinkButton";
import RenderTableActions from "./RenderTableActions";

export default function RenderCorrectComponent ( props )
{
	// Extract All Props
	const { cell } = props;

	switch ( cell.type )
	{
		case 'avatar':
			return <RenderAvatar cell = { cell } />;
		case 'string':
			return <RenderString cell = { cell } />;
		case 'progress':
			return <RenderProgress cell = { cell } />;
		case 'status':
			return <RenderStatus cell = { cell } />;
		case 'link':
			return <RenderLinkButton cell = { cell } />;
		case 'action':
			return <RenderTableActions cell = { cell } />;
		default:
			return <></>;
	}
};

RenderCorrectComponent.defaultProps = {};

RenderCorrectComponent.propTypes = {
	/**
	 *  The cell data object
	 */
	cell : PropTypes.object ,
};