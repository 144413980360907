/**
 * Declaring object for the default blue color
 */
const rapidSecondary = {
	50 : '#e2e2e4' ,
	100 : '#b6b7ba' ,
	200 : '#85878d' ,
	300 : '#54575f' ,
	400 : '#2f333c' ,
	500 : '#0a0f1a' ,
	600 : '#090d17' ,
	700 : '#070b13' ,
	800 : '#05080f' ,
	900 : '#030408' ,
	A100 : '#4e4eff' ,
	A200 : '#1b1bff' ,
	A400 : '#0000e7' ,
	A700 : '#0000ce' ,
	light : '#54575f',
	main : '#0a0f1a',
	dark : '#030408',
};

export default rapidSecondary;
