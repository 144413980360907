import React , { useState } from "react";

export const MiniSidebarLinkState = React.createContext ( {
	id : null ,
	setId : () =>
	{
	} ,
} );

export function MiniSidebarLinkStateContextProvider ( props )
{
	const [ id , setId ] = useState ( null );

	// Function to handle the open state of a Nav item
	const handleClick = ( e , navId ) =>
	{
		if ( id === navId )
		{
			setId ( null );
		}
		else
		{
			setId ( navId );
		}
	};


	return (
		<MiniSidebarLinkState.Provider
			value = {
				{
					id : id ,
					setId : handleClick ,
				}
			}
		>
			{ props.children }
		</MiniSidebarLinkState.Provider>
	)
}

