/**
 * calculateAdjacentBreakpoints
 * @since version 1.0.0
 *
 * @param {string} mainBreakpoint - The brakpoint of which the adjacent breakpoints need to be returned
 * @returns {object} - an object of adjacent breakpoints
 */

export default function calculateAdjacentBreakpoints ( mainBreakpoint )
{
	if ( mainBreakpoint === 'lg' )
	{
		return {
			next : 'xl' ,
			current : mainBreakpoint ,
			prev : 'md' ,
		}
	}
	else if ( mainBreakpoint === 'md' )
	{
		return {
			next : 'lg' ,
			current : mainBreakpoint ,
			prev : 'sm' ,
		}
	}
	else if ( mainBreakpoint === 'sm' )
	{
		return {
			next : 'md' ,
			current : mainBreakpoint ,
			prev : 'xs' ,
		}
	}
	else
	{
		console.log ( 'You have used a wrong breakpoint for mobile devices. Please check your settings.' )
	}
}