import React , { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles , useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import { MiniSidebarState } from '../../../context';

/**
 * RapidHeaderLinksLeft is used to render the components on the left side of the header. This component is included in the default layouts. You can read more about [customising layouts here](/docs/createCutomLayouts/).
 *
 * ##### Syntax
 *```jsx
 <RapidHeaderLinksLeft>
	Insert The Links Visible On The left Side Of The Header As Child Elements
 </RapidHeaderLinksLeft>
 *```
 * @since version 1.0.0
 */
export default function RapidHeaderLinksLeft ( props )
{
	// Extract all props
	const { className } = props;

	const useStyles = makeStyles ( ( theme ) => ( {
		navbarSection : {
			display : 'flex' ,
			alignItems : "center" ,
		} ,
		menuButton : {
			marginRight : theme.spacing ( 3 ) ,
		} ,
		menuButtonRtl : {
			marginLeft : theme.spacing ( 3 ) ,
		} ,
		menuButtonDisplay : {} ,
		hide : {
			display : 'none' ,
		} ,
	} ) );

	const classes = useStyles ();
	const theme = useTheme ();
	const ltr = theme.direction === 'ltr';
	let sidebarContext = useContext ( MiniSidebarState );
	let open = sidebarContext.open;

	return (
		/* Render the icon for opening the sidebar */
		<Box
			className = { classes.navbarSection + ' ' + className }
			component = "div"
		>
			<IconButton
				color = "inherit"
				aria-label = "open drawer"
				onClick = { () =>
				{
					sidebarContext.toggle ();
				} }
				edge = { ltr ? ( "start" ) : ( "end" ) }
				className = { clsx ( classes.menuButtonDisplay + ' ' + classes.menuButton , {
					[ classes.hide ] : open ,
				} ) }
			>
				<MenuIcon />
			</IconButton>

			{/* Include children props*/ }
			{ props.children }

		</Box>
	)
}

// Declaring default props
RapidHeaderLinksLeft.defaultProps = {};

/**
 * propTypes for the component
 */
RapidHeaderLinksLeft.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
};

