/*
 *	Rapid Admin React Settings
 *	Contains all the settings needed for altering Rapid Admin Functionality
 *
 */
import routePaths from "../routes/paths";

const settings = {
	config : {
		appUrl : 'http://localhost:3000/' ,	// The URL of your application which is applied to the logo as well
		router : 'browser' , // use 'hash' if you need to replace the BrowserRouter with the HashRouter
		theme : 'context' ,  // The option to choose between the `'light'` and the `'dark'` theme. Use `'context'` if you are using auto switching between the dark and the light theme
		themeDirection : 'ltr' , // Choose between `ltr` and `rtl` theme.
		redux : true , // Set to false if you want to disable Redux in your application
		desktopBreakpoint : 'md' , // Set the default breakpoint for Desktop Below this mobile components will load. Possible values are `lg`, `md`, `sm`.
	} ,
	sidebar : {
		drawerWidth : 280 , // The width of the sidebar when its in open state
	} ,
	user : {
		defaultRedirect : routePaths.login , // Default path to redirect the user when she is not logged into Rapid Admin React
		redirectWhenAuth : routePaths.dashboard , // Path to redirect the user to when the user is Authenticated Successfully
	} ,
	scrollBars : {
		width : '6px' , // The width of the scrollbars globally, applied to height in case of horizontal scrollbars. Overrides the default browser scrollbars for all the components to keep them consistent across all the browsers.
	} ,
};

export default settings;
