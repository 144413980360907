import * as actionTypes from '../../../actionTypes';

const initialState = {
	open : false ,
};

const addEventReducer = ( state = initialState , action ) =>
{
	switch ( action.type )
	{
		case actionTypes.ADD_EVENT_OPEN:
			return {
				open : true
			};
		case actionTypes.ADD_EVENT_CLOSE:
			return {
				open : false
			};
		default:
			return state
	}
};

export default addEventReducer;
