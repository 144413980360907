import React , { useRef , useState , useEffect } from 'react';
import clsx from "clsx";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles , useTheme } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Typography from "@material-ui/core/Typography";
import EmailActions from "./helpers/EmailActions";
import SingleEmailCheckbox from "./helpers/SingleEmailCheckbox";
import SelectAllEmailCheckbox from "./helpers/SelectAllEmailCheckbox";
import StarEmail from "./helpers/StarEmail";
import EmailSearch from "./helpers/EmailSearch";
import EmailPagination from "./helpers/EmailPagination";
import Avatar1 from "../../../../assets/images/avatars/joeseph-taylor.jpg";
import renderIndicatorColor from "./helpers/functions/renderIndicatorColor";
import tinycolor from "tinycolor2";
import SelectAllActions from "./helpers/SelectAllActions";

/**
 * RapidInbox - Create a functional email app using just one component which takes care of all the low level details for you.
 * @since 1.0.0
 *
 */
export default function RapidInbox ( props )
{
	// Extract All Props
	const {
		className ,
		data ,
		indicatorThickness ,
		emailActions ,
		othersEmailActionProps ,
		selectAllActions ,
		paginationProps ,
		searchInputProps ,
	} = props;

	// Get the main theme that is being used
	const parentTheme = useTheme ();
	// Set state to monitor the resize of the window object
	const [ resizeCount , setResizeCount ] = useState ( 0 );
	// Create ref to calculate the width of the inbox
	const widthRef = useRef ();
	// Create a state to store the width of the container
	const [ fullWidth , setFullWidth ] = useState ( 0 );
	// useEffect to set the state of the width of the container
	useEffect ( () =>
	{
		setFullWidth ( widthRef.current.offsetWidth )
	} , [ resizeCount ] )

	const profileWidth = 300;

	// Create ref to calculate the width of the profile section
	const actionRef = useRef ();
	// Create a state to store the width of the container
	const [ actionWidth , setActionWidth ] = useState ( 0 );
	// useEffect to set the state of the width of the container
	useEffect ( () =>
	{
		setActionWidth ( actionRef.current.offsetWidth )
	} , [ resizeCount ] )

	// State for the dynamic email width
	const [ emailLinkWidth , setEmailLinkWidth ] = useState ( 0 );
	// Calculate only when DOM changes
	useEffect ( () =>
	{
		setEmailLinkWidth ( fullWidth - profileWidth - actionWidth - ( 8 * 4.5 ) )
	} , [ fullWidth , actionWidth ] )

	// Reset all variables for layout once the window size changes
	window.onresize = function ()
	{
		setResizeCount ( resizeCount + 1 )
	};

	// Declaring Styling
	const useStyles = makeStyles ( ( theme ) => ( {
		emailListHeader : {
			display : "flex" ,
			justifyContent : "space-between" ,
			alignItems : "center" ,
			marginLeft : indicatorThickness ,
		} ,
		selectAllWrapper : {
			[ theme.breakpoints.down ( 'xs' ) ] : {
				display : 'none' ,
			} ,
		} ,
		searchWrapper : {
			display : "flex" ,
			alignItems : "center" ,
			[ theme.breakpoints.down ( 'sm' ) ] : {
				display : 'none' ,
			} ,

		} ,
		emailWrapper : {
			display : "flex" ,
			alignItems : "center" ,
			padding : theme.spacing ( 1 , 2 ) ,
			borderLeft : `${ indicatorThickness } solid transparent` ,
			transition : theme.transitions.create ( 'background-color' , {
				easing : theme.transitions.easing.easeIn ,
				duration : theme.transitions.duration.short ,
			} ) ,
		} ,
		warningHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.warning.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.warning.main ).setAlpha ( .18 ) ,
			}
		} ,
		errorHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.error.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.error.main ).setAlpha ( .18 ) ,
			}
		} ,
		infoHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.info.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.info.main ).setAlpha ( .18 ) ,
			}
		} ,
		successHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.success.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.success.main ).setAlpha ( .18 ) ,
			}
		} ,
		primaryHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.primary.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.primary.main ).setAlpha ( .18 ) ,
			}
		} ,
		secondaryHighlighter : {
			backgroundColor : tinycolor ( parentTheme.palette.secondary.main ).setAlpha ( .1 ) ,
			'&:hover' : {
				backgroundColor : tinycolor ( parentTheme.palette.secondary.main ).setAlpha ( .18 ) ,
			}
		} ,
		noneHighlighter : {
			backgroundColor : 'transparent' ,
			'&:hover' : {
				backgroundColor : theme.palette.action.hover ,
			}
		} ,
		profileContainer : {
			display : 'flex' ,
			alignItems : 'center' ,
			width : profileWidth ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				width : '100px' ,
			} ,
		} ,
		emailStar : {
			display : 'flex' ,
			marginRight : theme.spacing ( 2 ) ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				display : 'none' ,
			} ,
		} ,
		emailCheckbox : {
			display : "inline-flex" ,
			'& label' : {
				marginRight : "8px" ,
			} ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				display : 'none' ,
			} ,
		} ,
		emailLink : {
			display : "flex" ,
			alignItems : "center" ,
			textDecoration : "none" ,
			whiteSpace : "nowrap" ,
			width : `${ emailLinkWidth }px` ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				width : '190px' ,
			} ,
		} ,
		emailSender : {
			display : "flex" ,
			alignItems : "center" ,
			marginRight : theme.spacing ( 3 ) ,
			minWidth : "150px" ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				minWidth : "0px" ,
				maxWidth : '70px' ,
				marginRight : theme.spacing ( 2 ) ,
			} ,
		} ,
		emailSenderProfile : {
			marginRight : theme.spacing ( 1 ) ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				display : 'none' ,
			} ,
		} ,
		emailSubject : {
			marginRight : theme.spacing ( 2 ) ,
			display : "flex" ,
			alignItems : "center" ,
			width : ( emailLinkWidth - 151 - ( 8 * 4.5 ) ) + 'px' ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				width : '185px' ,
			} ,

		} ,
		emailText : {
			overflow : "hidden" ,
			textOverflow : "ellipsis" ,
			whiteSpace : "nowrap" ,
			width : ( emailLinkWidth - ( 8 * 4.5 ) ) + 'px' ,

		} ,
		emailDate : {
			marginRight : theme.spacing ( 2 ) ,
			[ theme.breakpoints.down ( 'xs' ) ] : {
				display : 'none' ,
			} ,
		} ,
		emailActions : {
			display : "flex" ,
			marginLeft : "auto" ,
		}

	} ) );
	// Declare classes for styling
	const classes = useStyles ();

	return (

		<Paper
			ref = { widthRef }
			className = { className }
			elevation = { 0 }
		>
			<Box component = "div" py = { 2 } px = { 2 } className = { classes.emailListHeader }>
				<Box className = { classes.selectAllWrapper }>
					<SelectAllEmailCheckbox />
					<SelectAllActions
						selectAllActions = { selectAllActions }
						othersEmailActionProps = { othersEmailActionProps }
					/>
				</Box>

				<Box className = { classes.searchWrapper }>
					<EmailSearch
						searchInputProps = { searchInputProps }
					/>
					<EmailPagination
						paginationProps = { paginationProps }
					/>
				</Box>
			</Box>
			<Divider />

			{
				data.map ( ( email ) =>
					(
						<Box
							key = { email.id }
							className = { clsx ( classes.emailWrapper , {
								[ classes.warningHighlighter ] : email.highlightColor === 'warning' ,
								[ classes.errorHighlighter ] : email.highlightColor === 'error' ,
								[ classes.infoHighlighter ] : email.highlightColor === 'info' ,
								[ classes.successHighlighter ] : email.highlightColor === 'success' ,
								[ classes.primaryHighlighter ] : email.highlightColor === 'primary' ,
								[ classes.secondaryHighlighter ] : email.highlightColor === 'secondary' ,
								[ classes.noneHighlighter ] : email.highlightColor === 'none' ,
							} ) }
							style = {
								{
									borderColor : renderIndicatorColor ( email.indicatorColor , parentTheme ) ,
								}
							}
						>

							<Box className = { classes.profileContainer }>
								<Box className = { classes.emailCheckbox }>
									<SingleEmailCheckbox
										emailId = { email.id }
									/>
								</Box>

								<Box className = { classes.emailStar }>
									<StarEmail
										emailId = { email.id }
										starClick = { email.starClick }
									/>
								</Box>
								<Box className = { classes.emailSender }>
									<Avatar
										className = { classes.emailSenderProfile }
										alt = { email.profileImageAlt }
										src = { email.fromProfileImage }
										title = { email.profileImageTitle }
									/>
									<Typography variant = "subtitle2" color = "textPrimary">
										{ email.fromName }
									</Typography>
								</Box>
							</Box>


							<Link
								className = { classes.emailLink }
								to = { email.link }
							>
								<Box className = { classes.emailSubject }>
									<Typography
										className = { classes.emailText }
										component = "p"
										variant = "subtitle2"
										color = "textSecondary"
									>
										{ email.emailDescription }
									</Typography>
								</Box>

								<Box className = { classes.emailDate }>
									<Typography
										variant = "subtitle2"
										color = "textPrimary"
									>
										{ email.date }
									</Typography>
								</Box>
							</Link>

							<Box ref = { actionRef } className = { classes.emailActions }>
								<EmailActions
									emailActions = { emailActions }
									othersEmailActionProps = { othersEmailActionProps }
									emailId = { email.id }
								/>
							</Box>
						</Box>
					)
				)
			}
		</Paper>
	)
}

// Default PropTypes
RapidInbox.defaultProps = {
	indicatorThickness : '4px' ,
	data : [
		{
			id : 'email1' ,
			indicatorColor : 'warning' ,
			fromName : 'Andrew Smith Two Lined' ,
			fromProfileImage : Avatar1 ,
			profileImageAlt : 'Andrew Smith' ,
			profileImageTitle : 'Andrew Smith' ,
			emailDescription : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sollicitudin aliquet sapien id blandit. Nam cursus elit vel libero faucibus, eget hendrerit eros lobortis.' ,
			highlightColor : 'none' ,
			date : '24 Spetember, 2020' ,
			link : '#' ,
		}
	]
};

// PropTypes
RapidInbox.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  The data if emails that need to be rendered by the component. You can pass an array of object with the proptypes for the object defined below
	 */
	data : PropTypes.arrayOf (
		PropTypes.shape (
			{
				/** Unique ID for each email */
				id : PropTypes.string.isRequired ,
				/** An indicator that shows up on each email.  Can use any of these colors 'error' ,
						'warning' ,
						'success' ,
						'info' ,
						'primary' ,
						'secondary',
				 		'none',
				 */
				indicatorColor : PropTypes.oneOf
				(
					[
						'error' ,
						'warning' ,
						'success' ,
						'info' ,
						'primary' ,
						'secondary' ,
						'none' ,
					]
				).isRequired ,
				/** The name of the sender of the email */
				fromName : PropTypes.string.isRequired ,
				/** The profile image of the sender */
				fromProfileImage : PropTypes.node.isRequired ,
				/** Alt text for the profile image */
				profileImageAlt : PropTypes.string.isRequired ,
				/** Title of the profile image */
				profileImageTitle : PropTypes.string ,
				/** The description of the email. Can be the subject or any other valid description that you want. */
				emailDescription : PropTypes.string.isRequired ,
				/** The date of the email. */
				date : PropTypes.string.isRequired ,
				/**
				 * The highlight color assigned as the background color of that email. Can use any of these colors 'error' ,
						'warning' ,
						'success' ,
						'info' ,
						'primary' ,
						'secondary',
				 		'none' ,
				 */
				highlightColor : PropTypes.oneOf (
					[
						'error' ,
						'warning' ,
						'success' ,
						'info' ,
						'primary' ,
						'secondary' ,
						'none' ,
					]
				).isRequired ,
				/** Link to the detail page of the email */
				link : PropTypes.string.isRequired ,
				/** Function to handle the click on the star. Your function gets `event` object as well as `emailId` as default params. You can get the bool value of star checkbox by accessing `event.target.checked`. */
				starClick : PropTypes.func.isRequired ,
			}
		) ).isRequired ,
	/**
	 *  The thickeness of the border indicator on each email. Should be a valid `px` value passed as a string
	 */
	indicatorThickness : PropTypes.string ,
	/**
	 *  An array of objects that would be passed as actions to each emails action button
	 */
	emailActions : PropTypes.arrayOf (
		PropTypes.shape ( {
			/** The icon that would be displayed for the action */
			actionIcon : PropTypes.node ,
			/** Pass a function that would handle the click for an action. You by default get an **`event`** object and **`emailid`**  when user clicks on the action as a param in your function. Once you get the event you can use `e.target.id` , `e.target` and `emailId` to handle click based on which emailId was clicked */
			onActionClick : PropTypes.func ,
			/** A uniqe ID for each of the actions */
			actionId : PropTypes.string ,
			/** A label for the action  */
			actionLabel : PropTypes.string ,
		} )
	).isRequired ,
	/**
	 *  An array of objects that would be passed as actions to the Select All actions button
	 */
	selectAllActions : PropTypes.arrayOf (
		PropTypes.shape ( {
			/** The icon that would be displayed for the action */
			actionIcon : PropTypes.node ,
			/** Pass a function that would handle the click for an action. You by default get an **`event`** object and **`allEmailsStatus`**  when user clicks on the action as a param in your function. Once you get the event you can use `e.target.id` to get the action that was click on by th user. `allEmailsStatus` gives you the status of each email whether it was selected by the user or not. */
			onActionClick : PropTypes.func ,
			/** A uniqe ID for each of the actions */
			actionId : PropTypes.string ,
			/** A label for the action  */
			actionLabel : PropTypes.string ,
		} )
	).isRequired ,
	/** An object of any other props that you want to pass down to the actions buttton. You can use any of the [Material UI props for the Menu](https://material-ui.com/components/menus/) as well  */
	othersEmailActionProps : PropTypes.object ,
	/** An object of any of [Material UI props for the Pagination](https://material-ui.com/api/pagination/) */
	paginationProps : PropTypes.object ,
	/** An object of any of [Material UI props for the input](https://material-ui.com/api/input/) */
	searchInputProps : PropTypes.object ,
};