import React , { useState , useContext , useEffect } from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { SelectAllEmail } from "../../../../context";

export default function SingleEmailCheckbox ( props )
{
	// Extract all props
	const {
		emailId ,
	} = props

	// Declaring the styling rules
	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			margin : theme.spacing ( 0 ) ,
		} ,
	} ) )
	// Declaring classes
	const classes = useStyles ();

	// Use the context for the Select All email
	const selectAllContext = useContext ( SelectAllEmail );

	// Declare a variable to manage the select all state
	let selectAll = selectAllContext.selectAll;

	// Declare the state for th checkbox
	const [ checked , setChecked ] = useState ( false );

	// Handle change checkbox method
	const handleChange = ( event ) =>
	{
		setChecked ( event.target.checked );
	};

	// Register the email to the context as soon as the email is loaded
	useEffect ( () =>
	{
		selectAllContext.registerEmail ( emailId , checked );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [] )

	// useEffect to update the context as soon as the checbox is updated
	useEffect ( () =>
	{
		selectAllContext.updateEmailState ( emailId , checked );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ checked ] )

	// Change the state of the checkbox when the selectAll changes
	useEffect ( () =>
	{
		setChecked ( selectAll );
		selectAllContext.updateEmailState ( emailId , checked );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ selectAll ] )

	return (
		<FormControlLabel
			className = { classes.root }
			control = {
				<Checkbox
					checked = { checked }
					onChange = { handleChange }
					name = { emailId }
					color = "primary"
					id = { emailId }
				/>
			}
			label = { false }
		/>
	)
}

SingleEmailCheckbox.defaultProps = {};

SingleEmailCheckbox.propTypes = {
	/** The email id for which the checkbox is for  */
	emailId : PropTypes.string.isRequired ,
};