import React , { useState } from "react";

export const MiniSidebarCloseButton = React.createContext ( {
	display : false ,
	toggleDisplay : () =>
	{
	} ,
} );

export function MiniSidebarCloseButtonContextProvider ( props )
{
	const [ display , setDisplay ] = useState ();

	function toggleDisplay ( bool )
	{
		setDisplay ( bool );
	}

	return (
		<MiniSidebarCloseButton.Provider
			value = {
				{
					display : display ,
					toggleDisplay : toggleDisplay ,
				}
			}
		>
			{ props.children }
		</MiniSidebarCloseButton.Provider>
	)
}

