import React , { useContext } from 'react';
import Box from "@material-ui/core/Box";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import settings from "../../../../config/rapidAdmin/settings";
import { CalendarAddEvent } from "../../../context";

// Full Calendar Related Imports
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

// Import the required buttons
import {
	renderTimeGridDay ,
	renderDayGridMonth ,
	renderTimeGridWeek ,
	renderListMonth ,
	renderNext ,
	renderPrevious ,
	renderNextYear ,
	renderPreviousYear ,
	renderToday
} from './helpers/calendarCustomButton'

/**
 * RapidCalendar - Component to render Full Calendar
 *
 * For passing the events a as a prop you can can see the following example of an array of abjects that can be used. Apart from the properties use here you can use any of the properties that have been mentioned on the FullCalenday website.
 * Please have a look at the following url for a full detail of the properties that can be used to render the events in the calender (https://fullcalendar.io/docs/event-object)
 *
 * ```jsx
 * const events = [
	{
		id : 'a' ,
		title : 'The Name Of The Event' ,
		start : new Date ().setHours ( 10 , 0 , 0 ) ,
		end : new Date ().setHours ( 12 , 59 , 59 ) ,
		backgroundColor : theme.palette.primary.main ,
		borderColor : theme.palette.primary.main ,
	} ,
 ];
 *
 * ```
 *
 * @since version 1.0.0
 */
export default function RapidCalendar ( props )
{
	// Extract all the props
	const
		{
			events ,
			className ,
			defaultView ,
			locale ,
			titleMonthFormat ,
			startToolbarHeader ,
			centerToolbarHeader ,
			endToolbarHeader ,
			eventClick ,
			eventMouseEnter ,
			disableDateClick ,
			dateClick ,
		} = props;

	const useStyles = makeStyles ( ( theme ) => ( {
		rapidCaledarWrap : {
			// All FullCalendar CSS variables
			'--fc-small-font-size' : theme.typography.caption.fontSize ,
			'--fc-page-bg-color' : theme.palette.background.paper ,
			'--fc-neutral-bg-color' : theme.palette.background.default ,
			'--fc-neutral-text-color' : theme.palette.text.secondary ,
			'--fc-border-color' : theme.palette.divider ,

			'--fc-button-text-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-bg-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-border-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-hover-bg-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-hover-border-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-active-bg-color' : 'rgba(0,0,0,0)' ,
			'--fc-button-active-border-color' : 'rgba(0,0,0,0)' ,

			'--fc-event-bg-color' : '#3788d8' ,
			'--fc-event-border-color' : '#3788d8' ,
			'--fc-event-text-color' : '#fff' ,
			'--fc-event-selected-overlay-color' : 'rgba(0, 0, 0, 0.25)' ,

			'--fc-event-resizer-thickness' : '8px' ,
			'--fc-event-resizer-dot-total-width' : '8px' ,
			'--fc-event-resizer-dot-border-width' : '1px' ,

			'--fc-non-business-color' : ' rgba(215, 215, 215, 0.3)' ,
			'--fc-bg-event-color' : ' rgb(143, 223, 130)' ,
			'--fc-bg-event-opacity' : ' 0.3' ,
			'--fc-highlight-color' : ' rgba(188, 232, 241, 0.3)' ,
			'--fc-today-bg-color' : theme.palette.action.hover ,
			'--fc-now-indicator-color' : theme.palette.error.main ,

			// List variables
			'--fc-list-event-dot-width' : '10px' ,
			'--fc-list-event-hover-bg-color' : 'rgba(0, 0, 0, .1)' ,

			// Daygrid variables
			'--fc-daygrid-event-dot-width' : '8px' ,

			// Wrap Styles
			width : '100%' ,

			'& .fc .fc-toolbar.fc-header-toolbar' : {
				marginBottom : theme.spacing ( 1 )
			} ,
			'& h2' : {
				fontSize : theme.typography.h1.fontSize ,
				fontFamily : theme.typography.h1.fontFamily ,
				fontWeight : theme.typography.h1.fontWeight ,
				lineHeight : theme.typography.h1.lineHeight ,
				color : theme.palette.text.primary ,
			} ,
			'& .fc-event-title' : {
				fontSize : theme.typography.subtitle2.fontSize ,
				fontFamily : theme.typography.subtitle2.fontFamily ,
				fontWeight : theme.typography.subtitle2.fontWeight ,
				lineHeight : theme.typography.subtitle2.lineHeight ,
			} ,
			'& .fc-list-event.fc-event.fc-event-start.fc-event-past' : {
				'&:hover' : {
					//backgroundColor : theme.palette.action.hover + ' !important' ,
				} ,
			} ,
			'& .fc-event-time' : {
				fontFamily : theme.typography.caption.fontFamily ,
				fontSize : theme.typography.caption.fontSize ,
				fontWeight : theme.typography.caption.fontWeight ,
				lineHeight : theme.typography.caption.lineHeight ,
			} ,
			'& .fc-button' : {
				padding : theme.spacing ( .5 ) ,
			} ,
			'& .fc-button-primary' : {
				backgroundColor : 'rgba(0,0,0,0)' ,
				border : '0px' ,
				borderColor : 'rgba(0,0,0,0)' ,
				'&:hover' : {
					backgroundColor : 'rgba(0,0,0,0)' ,
					border : '0px' ,
					borderColor : 'rgba(0,0,0,0)' ,
				}
			} ,
			'& .fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active ' : {
				backgroundColor : 'rgba(0,0,0,0)' ,
				border : '0px' ,
				borderColor : 'rgba(0,0,0,0)' ,
			} ,
			'&  .fc-daygrid-day , .fc-timegrid-slots table tr, .fc-event' : {
				cursor : disableDateClick ? '' : 'pointer' ,
				transition : theme.transitions.create ( 'background-color' , {
					easing : theme.transitions.easing.easeIn ,
					duration : theme.transitions.duration.easeOut ,
				} ) ,
				'&:hover' : {
					backgroundColor : theme.palette.action.hover ,
				} ,
			} ,
			'& .fc-toolbar' : {
				[ theme.breakpoints.down ( 'sm' ) ] : {
					flexDirection : 'column' ,
				} ,
			}
		}
	} ) );

	// Declare overide classes for the FullCalendar
	const classes = useStyles ();
	// Create ref to get the access to calendar object
	let calendarRef = React.createRef ();
	// Get the direction of the theme
	const themeDirection = settings.config.themeDirection;
	// Set the content for the state of state of the Add Event
	let calendarContext = useContext ( CalendarAddEvent );

	// Action Dispatched to redux to set the state of the add event
	const dateClicked = function ( info )
	{
		calendarContext.toggle ();
	};

	// Function to render the timeGridDay view using button
	function renderTimeGridDayView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.changeView ( 'timeGridDay' );
	}

	// Function to render dayGridMonth view
	function renderDayGridMonthView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.changeView ( 'dayGridMonth' );
	}

	// Function to render timeGridWeek view
	function renderTimeGridWeekView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.changeView ( 'timeGridWeek' );
	}

	// Function to render listMonth view
	function renderListMonthView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.changeView ( 'listMonth' );
	}

	// Function to render Next view
	function renderNextView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.next ();
	}

	// Function to render previous view
	function renderPreviousView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.prev ();
	}

	// Function to render next year view
	function renderNextYearView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.nextYear ();
	}

	// Function to render previous year view
	function renderPreviousYearView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.prevYear ();
	}

	// Function to render previous year view
	function renderTodayView ()
	{
		// Get the API of the FullCalendar using the Ref
		let calendarApi = calendarRef.current.getApi ();
		calendarApi.today ();
	}

	// Create an object to serve Custom Buttons
	const customButtonsObj = {
		timeGridDayButton : {
			text : renderTimeGridDay () ,
			click : renderTimeGridDayView ,
		} ,
		dayGridMonthButton : {
			text : renderDayGridMonth () ,
			click : renderDayGridMonthView ,
		} ,
		timeGridWeekButton : {
			text : renderTimeGridWeek () ,
			click : renderTimeGridWeekView ,
		} ,
		listMonthButton : {
			text : renderListMonth () ,
			click : renderListMonthView ,
		} ,
		nextButton : {
			text : renderNext () ,
			click : renderNextView ,
		} ,
		previousButton : {
			text : renderPrevious () ,
			click : renderPreviousView ,
		} ,
		nextYearButton : {
			text : renderNextYear () ,
			click : renderNextYearView ,
		} ,
		previousYearButton : {
			text : renderPreviousYear () ,
			click : renderPreviousYearView ,
		} ,
		todayButton : {
			text : renderToday () ,
			click : renderTodayView ,
		} ,
	};

	return (
		<Box className = { classes.rapidCaledarWrap + ' ' + className }>
			<FullCalendar
				ref = { calendarRef }
				themeSystem = { {
					default : 'standard'
				} }
				locale = { locale }
				direction = { themeDirection }
				customButtons = { customButtonsObj }
				defaultView = { defaultView }
				plugins = { [ dayGridPlugin , timeGridPlugin , listPlugin , interactionPlugin ] }
				titleFormat = {
					{
						year : 'numeric' ,
						month : titleMonthFormat ,
						day : 'numeric'
					}
				}
				headerToolbar = { {
					start : startToolbarHeader ,
					center : centerToolbarHeader ,
					end : endToolbarHeader ,
				} }
				firstDay = { 1 }
				events = { events }
				buttonText = { {
					dayGridMonth : 'Month' ,
					timeGridWeek : 'Week' ,
					timeGridDay : 'Daily' ,
					listMonth : 'Task List' ,
					today : 'Today' ,
				} }
				buttonIcons = { {} }
				dateClick = { disableDateClick ?
					(
						dateClick

					) :
					(
						( info ) =>
						{
							dateClicked ( info )
						}
					)
				}
				eventMouseEnter = { eventMouseEnter }
				eventClick = { eventClick }

				eventDidMount = { ( info ) =>
				{
					//console.log ( info )
				} }
				eventDisplay = "auto"
			/>
		</Box>
	)
}

RapidCalendar.defaultProps = {
	defaultView : 'dayGridMonth' ,
	titleMonthFormat : 'long' ,
	startToolbarHeader :
		'dayGridMonthButton,' +
		'timeGridWeekButton,' +
		'timeGridDayButton,' +
		'listMonthButton,' ,
	centerToolbarHeader : 'title' ,
	endToolbarHeader :
		'todayButton, ' +
		'previousYearButton,' +
		'previousButton,' +
		'nextButton,' +
		'nextYearButton' ,
	disableDateClick : false ,
	monthViewText : 'Month' ,
	weekViewText : 'Week' ,
	dailyViewText : 'Daily' ,
	taskViewText : 'Task List' ,

};

RapidCalendar.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  An array of objects of event sthat need to be loaded by the calendar
	 */
	events : PropTypes.array ,
	/**
	 *  The defaul view of the calendar
	 */
	defaultView : PropTypes.oneOf ( [ 'dayGridMonth' , 'timeGridWeek' , 'timeGridDay' , 'listMonth' ] ) ,
	/**
	 *  Localise the language of the calendar. For more information on which language you can import check [Localisation Documentation](https://fullcalendar.io/docs/locale)
	 */
	locale : PropTypes.node ,
	/**
	 *  Determines the format month's name that will be displayed in the header toolbar of the calendar
	 */
	titleMonthFormat : PropTypes.oneOf ( [ 'long' , 'short' ] ) ,
	/**
	 *  The content of the start of the header toolbar. You can remove the specific values that you do not want but without adding spaces between the commmas as a space would create an empty space between icons. You can also use the
	 * content of the start of the header to the end of the header and visa versa
	 */
	startToolbarHeader : PropTypes.string ,
	/**
	 *  The content of the center of the header toolbar. You can remove the specific values that you do not want but without adding spaces between the commmas as a space would create an empty space between icons. You can also use the
	 * content of the start of the header to the end of the header and visa versa
	 */
	centerToolbarHeader : PropTypes.string ,
	/**
	 *  The content of the end of the header toolbar. You can remove the specific values that you do not want but without adding spaces between the commmas as a space would create an empty space between icons. You can also use the
	 * content of the start of the header to the end of the header and visa versa
	 */
	endToolbarHeader : PropTypes.string ,
	/**
	 *  The function to be fired when the event is clicked. You get `eventClickInfo` as the default parameter to get all the information about the event that has been clicked.
	 */
	eventClick : PropTypes.func ,
	/**
	 *  The function to be fired when the mouse pointer hovers over the event. You get `mouseEnterInfo` as the default parameter to get all the information about the event that has been hovered over.
	 */
	eventMouseEnter : PropTypes.func ,
	/**
	 *  Disables the ability to add an event on the click of the date
	 */
	disableDateClick : PropTypes.bool ,
	/**
	 *  The function to be fired when the on ethe click of the date. You get `info` as the default parameter to get all the information about the date clicked. **Make sure `disableDateClick` is set to `true` inorder for your function to
	 * execute**
	 */
	dateClick : PropTypes.func ,
	/**
	 *  The text displayed in the tool-tip on icon hover for the monthly view
	 */
	monthViewText : PropTypes.string ,
	/**
	 *  The text displayed in the tool-tip on icon hover for the weekly view
	 */
	weekViewText : PropTypes.string ,
	/**
	 *  The text displayed in the tool-tip on icon hover for the daily view
	 */
	dailyViewText : PropTypes.string ,
	/**
	 *  The text displayed in the tool-tip on icon hover for the task list view
	 */
	taskViewText : PropTypes.string ,

};

