const rapidTypography = {
    fontFamily: "'Poppins', sans-serif",
    h1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontSize: "1.5rem",
        lineHeight: 1.333,
        letterSpacing: "0.1px"
    },
    h2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontSize: "1.375rem",
        lineHeight: 1.363,
        letterSpacing: "0.1px"
    },
    h3: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: "1.375rem",
        lineHeight: 1.35,
        letterSpacing: "0.18px"
    },
    h4: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: "1.125rem",
        lineHeight: 1.333,
        letterSpacing: "0.16px"
    },
    h5: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: "1.0625rem",
        lineHeight: 1.353,
        letterSpacing: "0.15px"
    },
    h6: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: 1.375,
        letterSpacing: "0.15px"
    },
    subtitle1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontSize: "1rem",
        lineHeight: 1.3125,
        letterSpacing: "0.25px"
    },
    subtitle2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.357,
        letterSpacing: "0.23px"
    },
    body1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: ".9375rem",
        lineHeight: 1.4,
        letterSpacing: "0.15px"
    },
    body2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: "0.8125rem",
        lineHeight: 1.6,
        letterSpacing: "0.3px"
    },
    caption: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: "0.75rem",
        lineHeight: 1.333,
        letterSpacing: "0.3px"
    },
    overline: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: "0.6875rem",
        lineHeight: 1.363,
        letterSpacing: "0.37px",
        textTransform: "uppercase"
    },
    button: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: "0.875rem",
        lineHeight: 1.71,
        letterSpacing: "0.13px",
        textTransform: "uppercase",
    },
};

export default rapidTypography;
