export default function emitGrid ( grid , parentTheme )
{
	// If falsy declare a dummy object
	if ( !grid )
	{
		grid = {};
	}
	// The actually styling object
	return {
		...grid ,
		borderColor : parentTheme.palette.divider ,
	};
}