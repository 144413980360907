import { createMuiTheme } from "@material-ui/core";

export default function rapidGenerateColoredTheme ( color , theme )
{
	switch ( color )
	{
		case 'primary':
			break;
		case 'secondary':
			return theme = createMuiTheme ( {
				palette : {
					primary : theme.palette.secondary ,
				}
			} );
		case 'warning':
			return theme = createMuiTheme ( {
				palette : {
					primary : theme.palette.warning ,
				}
			} );
		case 'error':
			return theme = createMuiTheme ( {
				palette : {
					primary : theme.palette.error ,
				}
			} );
		case 'success':
			return theme = createMuiTheme ( {
				palette : {
					primary : theme.palette.success ,
				}
			} );
		case 'info':
			return theme = createMuiTheme ( {
				palette : {
					primary : theme.palette.info ,
				}
			} );
		default:
			break;
	}
}