
import { createMuiTheme } from '@material-ui/core/styles';
import settings from "../rapidAdmin/settings";
import rapidSecondary from './colors/rapidSecondary'
import rapidRed from "./colors/rapidRed";
import rapidGrey from "./colors/rapidGrey";
import rapidAmber from "./colors/rapidAmber";
import rapidGreen from "./colors/rapidGreen";
import rapidPrimary from "./colors/rapidPrimary";
import rapidLightBlue from "./colors/rapidLightBlue";
import rapidTypography from "./typography/rapidTypography";
import rapidTextColorDark from "./darkTheme/rapidTextColorDark";
import rapidActionColorsDark from "./darkTheme/rapidActionColorsDark";
import rapidBackgroundDark from "./darkTheme/rapidBackgroundDark";

const themeDark = createMuiTheme ( {
	typography : rapidTypography ,
	direction : settings.config.themeDirection ,
	palette : {
		type : "dark" ,
		primary : rapidPrimary ,
		secondary : rapidSecondary ,
		error : rapidRed ,
		warning : rapidAmber ,
		info : rapidLightBlue ,
		success : rapidGreen ,
		grey : rapidGrey ,
		text : rapidTextColorDark ,
		action : rapidActionColorsDark ,
		background : rapidBackgroundDark ,
		divider : 'rgba(255, 255, 255, 0.12)' ,
	} ,
	shape : {
		borderRadius : 4
	} ,
	zIndex : {
		mobileStepper : 1000 ,
		speedDial : 1050 ,
		appBar : 1100 ,
		drawer : 1200 ,
		modal : 1300 ,
		snackbar : 1400 ,
		tooltip : 1500 ,
	} ,
} );

export default themeDark;
