// Function to render the correct indicator color
export default function renderIndicatorColor ( color , parentTheme )
{
	if ( color === 'primary' )
	{
		return parentTheme.palette.primary.main;
	}
	else if ( color === 'secondary' )
	{
		return parentTheme.palette.secondary.main;
	}
	else if ( color === 'error' )
	{
		return parentTheme.palette.error.main;
	}
	else if ( color === 'warning' )
	{
		return parentTheme.palette.warning.main;
	}
	else if ( color === 'info' )
	{
		return parentTheme.palette.info.main;
	}
	else if ( color === 'success' )
	{
		return parentTheme.palette.success.main;
	}
	else
	{
		return 'transparent'
	}
}