import React from 'react';
import routePaths from "../routes/paths";
import Email from '@material-ui/icons/EmailOutlined';
import Dashboard from '@material-ui/icons/DashboardOutlined';
import Event from '@material-ui/icons/EventOutlined';
import Chat from '@material-ui/icons/ChatOutlined';
import Assessment from '@material-ui/icons/AssessmentOutlined';
import Lock from '@material-ui/icons/LockOutlined';
import ErrorOutline from '@material-ui/icons/ErrorOutlineOutlined';
import PersonOutline from '@material-ui/icons/PersonOutlineOutlined';
import TextFields from '@material-ui/icons/TextFields';
import VerticalSplit from '@material-ui/icons/VerticalSplitOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import DeveloperBoardOutlinedIcon from '@material-ui/icons/DeveloperBoardOutlined';
import BlurOnOutlinedIcon from '@material-ui/icons/BlurOnOutlined';
import DehazeOutlinedIcon from '@material-ui/icons/DehazeOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EnhancedEncryptionOutlinedIcon from '@material-ui/icons/EnhancedEncryptionOutlined';

const navigation = [
	{
		id : 'mmdas' ,  // Use Prefix With ID So that it is unique
		name : 'Dashboard' ,
		icon : ( <Dashboard /> ) ,
		link : routePaths.dashboard , // path will not be valid if has sublinks
		badge : false ,
		divider : false ,
		subheader : 'IMPLEMENTATIONS' ,
	} ,
	{
		id : 'mmcal' ,  // Use Prefix With ID So that it is unique
		name : 'Calendar' ,
		icon : ( <Event /> ) ,
		link : routePaths.calendar ,
		badge : false ,
		divider : false ,
		subheader : false
	} ,
	{
		id : 'mminb' ,
		name : 'Inbox' ,
		icon : ( <Email /> ) ,
		link : routePaths.inbox ,
		badge : 5 ,
		divider : false ,
		subheader : false
	} ,
	{
		id : 'mmcha' ,
		name : 'Chat' ,
		icon : ( <Chat /> ) ,
		link : routePaths.chat ,
		badge : false ,
		divider : false ,
		subheader : false
	} ,
	{
		id : 'mmkan' ,
		name : 'Kanban' ,
		icon : ( <Assessment /> ) ,
		link : routePaths.kanban ,
		badge : false ,
		divider : true ,
		subheader : false
	} ,
	{
		id : 'mmlog' ,
		name : 'Login' ,
		link : routePaths.login ,
		icon : ( <Lock /> ) ,
		badge : false ,
		divider : false ,
		subheader : 'PAGES' ,
		subLinks : false ,
	} ,
	{
		id : 'mmregi' ,
		name : 'Register' ,
		link : routePaths.register ,
		icon : ( <LockOpenIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false
	} ,
	{
		id : 'mmfor' ,
		name : 'Forgot Password' ,
		link : routePaths.forgotPassword ,
		icon : ( <EnhancedEncryptionOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false
	} ,
	{
		id : 'mmerr' ,
		name : '404 Error' ,
		icon : ( <ErrorOutline /> ) ,
		link : routePaths.notFound ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : false ,
	} ,
	{
		id : 'mmuse' ,
		name : 'User Profile' ,
		link : routePaths.userProfile ,
		icon : ( <PersonOutline /> ) ,
		badge : false ,
		divider : true ,
		subheader : false ,
	} ,
	{
		id : 'rauti' ,
		name : 'Utilities' ,
		link : '/path' ,
		icon : ( <BlurOnOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : 'COMPONENTS' ,
		subLinks : [
			{
				id : 'rauti1' ,
				name : 'Rapid Timeline' ,
				link : routePaths.rapidTimeline ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'rauti2' ,
				name : 'Rapid CkeEditor' ,
				link : routePaths.rapidCkEditor ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'rauti23' ,
				name : 'Rapid Notifications' ,
				link : routePaths.rapidNotifications ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'rauti4' ,
				name : 'Rapid Task Status Table' ,
				link : routePaths.rapidTaskStatusTable ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'ranav' ,
		name : 'Essentials' ,
		link : '/path' ,
		icon : ( <DehazeOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'ranav1' ,
				name : 'Rapid Page Titles' ,
				link : routePaths.rapidPageTitles ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'ranav2' ,
				name : 'Rapid Quick Stats' ,
				link : routePaths.rapidQuickStats ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'rause' ,
		name : 'User' ,
		link : '/path' ,
		icon : ( <PersonOutlineOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'rause1' ,
				name : 'Rapid Mini Profile' ,
				link : routePaths.rapidMiniProfile ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mminp' ,
		name : 'Inputs' ,
		link : '/path' ,
		icon : ( <TextFields /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mminp1' ,
				name : 'Button' ,
				link : routePaths.buttons ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp2' ,
				name : 'Button Group' ,
				link : routePaths.buttonGroup ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp3' ,
				name : 'Checkbox' ,
				link : routePaths.checkbox ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp4' ,
				name : 'Floating Action Buttons' ,
				link : routePaths.FloatingActionButton ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp5' ,
				name : 'Date | Time' ,
				link : routePaths.datePickers ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp6' ,
				name : 'Radio' ,
				link : routePaths.radio ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp7' ,
				name : 'Select' ,
				link : routePaths.select ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp8' ,
				name : 'Slider' ,
				link : routePaths.slider ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp9' ,
				name : 'Switch' ,
				link : routePaths.switches ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp10' ,
				name : 'Text Field' ,
				link : routePaths.textField ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mminp11' ,
				name : 'Tranfer List' ,
				link : routePaths.transferList ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mmnav' ,
		name : 'Navigation' ,
		link : '/path' ,
		icon : ( <VerticalSplit /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mmnav1' ,
				name : 'Bottom Navigation' ,
				link : routePaths.bottomNavigation ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmnav2' ,
				name : 'Breadcrumbs' ,
				link : routePaths.breadcrumbs ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmnav3' ,
				name : 'Drawer' ,
				link : routePaths.drawer ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmnav5' ,
				name : 'Menu' ,
				link : routePaths.menus ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmnav6' ,
				name : 'Stepper' ,
				link : routePaths.stepper ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmnav7' ,
				name : 'Tabs' ,
				link : routePaths.tabs ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mmsur' ,
		name : 'Surfaces' ,
		link : '/path' ,
		icon : ( <AspectRatioOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mmsur1' ,
				name : 'App Bar' ,
				link : routePaths.appBar ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmsur2' ,
				name : 'Paper' ,
				link : routePaths.paper ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmsur3' ,
				name : 'Card' ,
				link : routePaths.cards ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmsur4' ,
				name : 'Accordion' ,
				link : routePaths.accordion ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mmfee' ,
		name : 'Feedback' ,
		link : '#' ,
		icon : ( <NotificationImportantOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mmfee1' ,
				name : 'Progress' ,
				link : routePaths.progress ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmfee2' ,
				name : 'Dialogue' ,
				link : routePaths.dialog ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmfee3' ,
				name : 'Snackbar' ,
				link : routePaths.snackbar ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmsur4' ,
				name : 'Backdrop' ,
				link : routePaths.backdrop ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mmdat' ,
		name : 'Data Display' ,
		link : '#' ,
		icon : ( <TableChartOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mmdat1' ,
				name : 'Avatar' ,
				link : routePaths.avatar ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat2' ,
				name : 'Badge' ,
				link : routePaths.badge ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat3' ,
				name : 'Chip' ,
				link : routePaths.chip ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat4' ,
				name : 'Divider' ,
				link : routePaths.divider ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat5' ,
				name : 'Lists' ,
				link : routePaths.lists ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat6' ,
				name : 'Table' ,
				link : routePaths.table ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmdat7' ,
				name : 'Tooltip' ,
				link : routePaths.tooltip ,
				icon : false ,
				badge : false
			} ,
		]
	} ,
	{
		id : 'mmadv' ,
		name : 'Advance' ,
		link : '/path' ,
		icon : ( <DeveloperBoardOutlinedIcon /> ) ,
		badge : false ,
		divider : false ,
		subheader : false ,
		subLinks : [
			{
				id : 'mmadv1' ,
				name : 'Alert' ,
				link : routePaths.alert ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv2' ,
				name : 'Autocomplete' ,
				link : routePaths.autocomplete ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv3' ,
				name : 'Pagination' ,
				link : routePaths.pagination ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv4' ,
				name : 'Rating' ,
				link : routePaths.rating ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv5' ,
				name : 'Skeleton' ,
				link : routePaths.skeleton ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv6' ,
				name : 'Speed Dial' ,
				link : routePaths.speedDial ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv7' ,
				name : 'Timeline' ,
				link : routePaths.timeline ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv8' ,
				name : 'Toggle Buttons' ,
				link : routePaths.toggleButtons ,
				icon : false ,
				badge : false
			} ,
			{
				id : 'mmadv9' ,
				name : 'Tree View' ,
				link : routePaths.treeView ,
				icon : false ,
				badge : false
			}
		]
	}
];

export default navigation;