import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { RapidComposeEmailButton } from "../../index";

const useStyles = makeStyles ( ( theme ) => ( {
	root : {
		width : '100%' ,
	} ,
	navRoot : {
		paddingTop : theme.spacing ( 0 ) ,
		paddingBottom : theme.spacing ( 0 ) ,
	} ,
	buttonContainer : {
		padding : theme.spacing ( 2 )
	} ,
	buttonItem : {} ,
	buttonIconWrrapper : {
		minWidth : '0px' ,
		width : 'auto' ,
		marginRight : theme.spacing ( 1 ) ,
	} ,
	buttonTextWrapper : {} ,
	divider : {}
} ) );

/**
 * RapidInboxSidebar - A component to compliment the RapidInbox which provides a space to link to the other omponents of an email application that your user might need.
 * @since 1.0.0
 *
 */
export default function RapidInboxSidebar ( props )
{
	// Extract All props
	const {
		className ,
		composeButton ,
		composeButtonPosition ,
		links ,
	} = props;

	const classes = useStyles ();

	return (
		<Box className = { classes.root + ' ' + className }>

			{ composeButton && composeButtonPosition === 'top' ?
				(
					<>
						<RapidComposeEmailButton position = { composeButtonPosition } />
					</>
				)
				: false
			}
			<List component = "nav"
				  aria-label = "main mailbox folders"
				  className = { classes.navRoot }
			>
				{ links.map ( ( link ) => (
					<>

						<ListItem
							button
							className = { classes.buttonItem }
							onClick = { ( e ) => link.clickFunc ( e , link.id ) }
						>
							<ListItemIcon
								className = { classes.buttonIconWrrapper }
							>
								{ link.icon }
							</ListItemIcon>
							<ListItemText
								className = { classes.buttonTextWrapper }
								primary = { link.label }
							/>
						</ListItem>
						{ link.divider ? (
							<Divider />
						) : false }

					</>
				) ) }
			</List>


			{ composeButton && composeButtonPosition === 'bottom' ?
				(
					<>
						<RapidComposeEmailButton position = { composeButtonPosition } />
					</>
				)
				: false
			}

		</Box>
	);
}

// Default props for the component
RapidInboxSidebar.defaultProps = {
	composeButton : true ,
	composeButtonPosition : 'top' ,
	links : [] ,
};

// Proptypes for the component
RapidInboxSidebar.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  An Array of links that need to be rendered in the sidebar
	 */
	links : PropTypes.arrayOf (
		PropTypes.shape (
			{
				/** A unique id for each link in the sidebar  */
				id : PropTypes.string ,
				/** Label of the link  */
				label : PropTypes.string ,
				//** Click function for each of the links. You get the `event` and `id` param by default in your function. id returns the id of the link  */
				clickFunc : PropTypes.func ,
				//** Icon for the link */
				icon : PropTypes.node ,
				//** Pass true to this property if you want a divider to be rendered below this link */
				divider : PropTypes.bool ,
			}
		) ).isRequired ,
	/**
	 * Pass false if you do not want the compose button to be rendered in the sidebar
	 */
	composeButton : PropTypes.bool ,
	/**
	 * Pass false if you do not want the compose button to be rendered in the sidebar
	 */
	composeButtonPosition : PropTypes.oneOf ( [ 'top' , 'bottom' ] ) ,
};