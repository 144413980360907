/**
 * Declaring object for the default blue color
 */
const rapidTextColorDark = {
    primary: 'rgba(255,255,255,.90)',
    secondary: 'rgba(255,255,255,.70)',
    disabled: 'rgba(255,255,255,.50)',
    hint: 'rgba(255,255,255,.50)',
    icon: 'rgba(255,255,255,.50)',
    divider: 'rgba(255,255,255,.12)',
};

export default rapidTextColorDark;
