import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import Avatar from '@material-ui/core/Avatar';
import Box from "@material-ui/core/Box";
import PropTypes from 'prop-types';
import { theme } from "../../../../config/theme";
import { RapidApexChart } from "../../index";

/**
 * RapidQuickStats:  is a widget that lets you display quick information using the graphical representation and icons
 * @since version: 1.0.0
 */
export default function RapidQuickStats ( props )
{
	// Extract All Props
	const {
		className ,
		titleComponent ,
		titleVariant ,
		titleColor ,
		subtitleColor ,
		subtitleComponent ,
		subtitleVariant ,
		iconVariant ,
		icon ,
		iconColor ,
		iconBackgroundColor ,
		widgetBackground ,
		iconSize ,
		rapidApexChartSeries ,
		rapidApexChartHeight ,
		title ,
		subtitle ,
		chartColor

	} = props;

	const orderReceivedOptions = {
		chart : {
			width : '100%' ,
			parentHeightOffset : - 20 ,
		} ,
		dataLabels : {
			enabled : false ,
		} ,
		colors : [
			chartColor
		] ,
		plotOptions : {
			area : {
				fillTo : 'origin' ,
			} ,
		} ,
		markers : {
			size : 0 ,
		} ,
		stroke : {
			show : true ,
			curve : 'smooth' ,
			lineCap : 'butt' ,
			colors : undefined ,
			width : 5 ,
			dashArray : [] ,
		} ,
		fill : {
			type : "gradient" ,
			gradient : {
				opacityFrom : .7 ,
				opacityTo : 0 ,
				stops : [ 40 , 100 ]
			}
		} ,
		// https://github.com/apexcharts/apexcharts.js/issues/178
		grid : {
			show : false ,
			padding : {
				left : - 10 ,
				right : 0
			} ,
		} ,
		legend : {
			show : false ,
		} ,
		xaxis : {
			trim : false ,
			labels : {
				show : false ,
			} ,
			axisBorder : {
				show : false ,
			} ,
			axisTicks : {
				show : false ,
			} ,
			crosshairs : {
				show : false ,
			} ,
			tooltip : {
				enabled : false ,
			}
		} ,
		yaxis : {
			labels : {
				show : false ,
				offsetX : - 10 ,
			} ,
			axisBorder : {
				show : false ,
			} ,
			axisTicks : {
				show : false ,
			} ,
			crosshairs : {
				show : false ,
			} ,
			tooltip : {
				enabled : false ,
			}
		} ,
		tooltip : {
			enabled : true ,
			x : {
				show : false ,
			} ,
		}
	};

	// Declare styling for the elements
	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			width : '100%' ,
			background : widgetBackground ,
		} ,
		cardContent : {
			padding : theme.spacing ( 2 ) ,
			'&:last-child' : {
				paddingBottom : theme.spacing ( 0 ) ,
			} ,
		} ,
		contentWrap : {
			display : 'flex' ,
			alignItems : 'center' ,
		} ,
		iconWrap : {
			marginRight : theme.spacing ( 2 ) ,
		} ,
		avatar : {
			backgroundColor : iconBackgroundColor ,
			width : `calc(${ iconSize }  + calc(${ iconSize } * .5 ))` ,
			height : `calc(${ iconSize }  + calc(${ iconSize } * .5 ))` ,
			'& svg' : {
				fill : iconColor ,
				width : iconSize ,
				height : iconSize ,
			} ,
		} ,
		titleWrap : {} ,
		title : {
			color : titleColor ,
		} ,
		subtitle : {
			color : subtitleColor ,
		} ,
		graph : {
			display : 'flex' ,
			width : '100%' ,
		} ,
		quickStatsChart : {}
	} ) );

	// Create classes for styling
	const classes = useStyles ();

	return (
		<Card className = { classes.root + ' ' + className }>
			<CardContent className = { classes.cardContent }>
				<Box className = { classes.contentWrap }>
					<Box className = { classes.iconWrap }>
						<Avatar
							variant = { iconVariant }
							className = { classes.avatar }
						>
							{ icon }
						</Avatar>
					</Box>
					<Box className = { classes.titleWrap }>
						<Typography
							component = { titleComponent }
							className = { classes.title }
							variant = { titleVariant }

						>
							{ title }
						</Typography>

						<Typography
							variant = { subtitleVariant }
							component = { subtitleComponent }
							className = { classes.subtitle }
						>
							{ subtitle }
						</Typography>
					</Box>
				</Box>

				<Box className = { classes.graph }>
					<RapidApexChart
						className = { classes.quickStatsChart }
						options = { orderReceivedOptions }
						series = { rapidApexChartSeries }
						type = "area"
						height = { rapidApexChartHeight }
					/>
				</Box>
			</CardContent>
		</Card>
	);
}

// Default prop types
RapidQuickStats.defaultProps = {
	title : 'Title' ,
	titleComponent : 'h3' ,
	titleVariant : 'h4' ,
	titleColor : theme.palette.text.primary ,
	subtitle : 'subtitle' ,
	subtitleColor : theme.palette.text.secondary ,
	subtitleComponent : 'p' ,
	subtitleVariant : 'subtitle2' ,
	iconVariant : 'circle' ,
	icon : <HomeIcon /> ,
	iconColor : theme.palette.primary.main ,
	iconBackgroundColor : theme.palette.background.default ,
	widgetBackground : theme.palette.background.paper ,
	iconSize : '32px' ,
	rapidApexChartHeight : '150px' ,
	chartColor : theme.palette.primary.main
};

// Prop types declaration
RapidQuickStats.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  Set the abckground of the widget. Pass a valid CSS background property
	 */
	widgetBackground : PropTypes.string ,
	/**
	 *  The title for the widget
	 */
	title : PropTypes.string ,
	/**
	 *  The html component within which the Title needs to be rendered
	 */
	titleComponent : PropTypes.string ,
	/**
	 *  The style variant that needs to be used for the title
	 */
	titleVariant : PropTypes.string ,
	/**
	 *  The color that you want to assign to the title
	 */
	titleColor : PropTypes.string ,
	/**
	 *  The subtitle for the widget
	 */
	subtitle : PropTypes.string ,
	/**
	/**
	 *  The html component within which the subtitle needs to be rendered
	 */
	subtitleComponent : PropTypes.string ,
	/**
	 *  The style variant that needs to be used for the subtitle
	 */
	subtitleVariant : PropTypes.string ,
	/**
	 *
	 *  The color that you want to assign to the subtitle
	 */
	subtitleColor : PropTypes.string ,
	/**
	 *  The shape of the icon
	 */
	iconVariant : PropTypes.oneOf ( [ 'circle' , 'rounded' , 'square' ] ) ,
	/**
	 *  The icon that you want to display
	 */
	icon : PropTypes.node ,
	/**
	 *  Any valid color property for the icon
	 */
	iconColor : PropTypes.string ,
	/**
	 *  Any valid color property for the icon background
	 */
	iconBackgroundColor : PropTypes.string ,
	/**
	 *  Any valid css size property for the icon size
	 */
	iconSize : PropTypes.string ,
	/**
	 *  The series of data that you want to render
	 */
	rapidApexChartSeries : PropTypes.array ,
	/**
	 *  Height of the chart passed in px value
	 */
	rapidApexChartHeight : PropTypes.string ,
	/**
	 *  The color of the chart. Pass any valid CSS color value
	 */
	chartColor : PropTypes.string ,
};