import 'date-fns';
import PropTypes from 'prop-types';
import React , { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { CalendarAddEvent } from "../../../context";

// Import Icons and Images
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles ( () => ( {
	addEventWrap : {} ,
	addEventHeader : {
		display : 'flex' ,
		alignItems : 'center' ,
		justifyContent : 'space-between' ,
	} ,
} ) );

/**
 * RapidAddCalEvent - Componene to open Add Event dialogue when a date is clicked. See how to use this with [RapidCalendar](/#/Utilities?id=rapidcalendar)
 */
export default function RapidAddCalEvent ( props )
{
	// Extract  All Props
	const {
		className ,
		title
	} = props;

	// Set the context for the Add Calendar Calendar Open State
	let addEventContext = useContext ( CalendarAddEvent );
	// Open state of the Add Event
	const open = addEventContext.open;
	// Set the classes to be used in the component
	const classes = useStyles ();

	return (
		<div className = { className }>
			<Dialog
				open = { open }
				onClose = { () =>
				{
					addEventContext.toggle ()
				} }
				className = { classes.addEventWrap }
				aria-labelledby = "alert-event-title"
				aria-describedby = "alert-dialog-description"
			>
				<Box className = { classes.addEventHeader } px = { 2 } py = { 1 }>
					<Typography variant = "h4" id = "alert-event-title">{ title }</Typography>
					<IconButton
						onClick = { () =>
						{
							addEventContext.toggle ()
						} }
						aria-label = "close">
						<Close />
					</IconButton>
				</Box>
				<Divider />
				<Box p = { 2 }>
					{ props.children }
				</Box>
			</Dialog>
		</div>
	);
}

// Default Props
RapidAddCalEvent.defaultProps = {
	title : 'Add Event' ,
};

// Prop Types
RapidAddCalEvent.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  Title of the event dialogue
	 */
	title : PropTypes.string ,

};


