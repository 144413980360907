/**
 * RapidSidebarMini is the sidebar menu fopr Rapip Admin React. When in closed state the sidebar displays as a mini version on Desktop.
 * @since 1.0.0
 * @author Uneebox <info@uneebox.com>
 *
 */
import React , { Fragment , useEffect , useContext , useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles , useTheme } from '@material-ui/core/styles';
import { themeDark } from '../../../../config/theme';
import Drawer from '@material-ui/core/Drawer';
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RapidMiniProfile , RapidNavbarLinks } from '../../';
import navigation from '../../../../config/navbars/navigation';
import themeLogo from "../../../../assets/images/logo/logo-white.svg";
import settings from "../../../../config/rapidAdmin/settings";
import { MiniSidebarState , MiniSidebarScrollPosition , MiniSidebarCloseButton } from '../../../context';
import calculateAdjacentBreakpoints from "../../../tools/functions/calculateAdjacentBreakpoints";
import RapidRenderTheme from "../../../tools/RapidRenderTheme/RapidRenderTheme";

/**
 * **RapidSidebarMini** - component is used to render the mini sidebar which when collapsed renders small icons displaying the contents and acts as a full sidebar with navigation links when expanded.
 *
 * ### Structure for the `navigation` object to be passed as a prop.
 * The following structure can be used to pass navigation object to generate all the navigation links in the sidebar.
 *
 *
 * ```javascript
 *
 * // Import the icons that you want to use in your sidebar elements
 * import CheckBox from '@material-ui/icons/CheckBoxOutlined';
 * import Lock from '@material-ui/icons/LockOutlined';
 *
 *const navigation = [
	{
		id: 'mmtod',
		name: 'Todo',
		icon: (<CheckBox />),
		link: '/path',
		badge: false,
		divider: true,
		subheader: false
	},
	{
		id: 'mmaut',
		name: 'Authentication',
		icon: (<Lock />),
		link: '/path',
		badge: false,
		divider: false,
		subheader: 'PAGES',
		subLinks: [
			{
				id: 'mmaut1',
				name: 'Register',
				link: '/path',
				icon: false,
				badge: false
			},
		]
	}
 ]
 *
 * ```
 */
export default function RapidSidebarMini ( props )
{
	const {
		logo ,
		logoAltText ,
		logoHeight ,
		renderToolTip ,
		themeUsed ,
		navigation ,
		miniProfile ,
		miniProfileProps ,
		className ,
		backgroundColor ,
		toggle ,
		drawerElevation ,
	} = props;

	// Get the width of the drawer from the settings
	const drawerWidth = settings.sidebar.drawerWidth;
	// Get the sidebar open close state sidebar context
	let sidebarContext = useContext ( MiniSidebarState );
	// Set the boolean of the sidebar state
	let open = sidebarContext.open;
	// Get the scroll context for the sidebar
	let scrollContext = useContext ( MiniSidebarScrollPosition );
	// Get the corrent Theme
	const parentTheme = useTheme ();
	// Check set the boolean for a mobile device access
	const mobile = isMobile ();
	// Get the theme direction variables and set the correct boolean
	const rtl = parentTheme.direction === 'rtl';
	// Set the ref container of the sidebar
	const container = useRef ();
	// Create an object of adjacent breakpoints
	const breakpoints = calculateAdjacentBreakpoints ( settings.config.desktopBreakpoint );
	// Get the Context for the close button in MiniSidebar
	const CloseButtonContext = useContext ( MiniSidebarCloseButton );
	// Check the status of DisplayButton In Context
	let displayButton = CloseButtonContext.display;

	// UseEffect to set close button display to false if toggle is set to false
	useEffect ( () =>
	{
		CloseButtonContext.toggleDisplay ( toggle )
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ toggle ] );

	// Function to set the correct background color for the Drawer
	function setBackgroundColor ( backgroundColor , themeUsed )
	{
		if ( backgroundColor )
		{

			return backgroundColor;

		}
		else
		{
			if ( themeUsed === 'light' )
			{
				return parentTheme.palette.secondary.main
			}
			else
			{
				return parentTheme.palette.secondary.dark
			}
		}
	}

	const useStyles = makeStyles ( ( theme ) => ( {
		main : {} ,
		root : {
			display : 'flex' ,
		} ,
		rootRtl : {
			display : 'flex' ,
			flexDirection : 'row-reverse' ,
		} ,
		drawer : {
			flexShrink : 0 ,
			whiteSpace : 'nowrap' ,
			backgroundColor : setBackgroundColor ( backgroundColor , themeUsed ) ,
		} ,
		drawerAnimate : {} ,
		drawerOpen : {
			width : drawerWidth ,
			[ theme.breakpoints.down ( breakpoints.prev ) ] : {
				position : 'fixed' ,
				zIndex : theme.zIndex.drawer ,
			} ,
			transition : theme.transitions.create ( 'width' , {
				easing : theme.transitions.easing.easeOut ,
				duration : theme.transitions.duration.shortest ,
			} ) ,
		} ,
		drawerClose : {
			width : theme.spacing ( 0 ) + 1 ,
			[ theme.breakpoints.up ( breakpoints.current ) ] : {
				width : theme.spacing ( 7 ) + 1 ,
			} ,
			[ theme.breakpoints.down ( breakpoints.prev ) ] : {
				position : 'fixed' ,
			} ,
			transition : theme.transitions.create ( 'width' , {
				easing : theme.transitions.easing.easeOut ,
				duration : theme.transitions.duration.shortest ,
			} ) ,
		} ,
		toolbar : {
			display : 'flex' ,
			alignItems : 'center' ,
			justifyContent : 'space-between' ,
			padding : theme.spacing ( 0 , 2 ) ,
			borderBottom : themeUsed === 'light' ? `1px solid ${ theme.palette.divider }` : `1px solid ${ themeDark.palette.divider }` ,
			// necessary for content to be below app bar
			...theme.mixins.toolbar ,
			//backgroundColor : theme.palette.secondary.main ,
		} ,
		logoContainer : {
			height : '100%' ,
			display : 'flex'
		} ,
		rapidLogo : {
			height : logoHeight ,
		} ,
		closeButton : {
			[ theme.breakpoints.up ( breakpoints.current ) ] : {
				display : displayButton ? 'inherit' : 'none' ,
			} ,
		} ,
		profileHidden : {
			opacity : 0 ,
			display : "none" ,
		} ,
		profileVisible : {
			opacity : 1 ,
			display : "block" ,
		} ,
		profile : {} ,
		hide : {
			display : 'none' ,
		} ,
	} ) );
	// Set the classes object for styling
	const classes = useStyles ();

	// Function to check the browser
	function isMobile ()
	{
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test ( navigator.userAgent );
	}

	// useEffect to close the drawer if the app is access using a mobile device
	useEffect ( () =>
	{
		// eslint-disable-next-line no-unused-expressions
		mobile ? ( sidebarContext.toggle () ) : false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [ mobile ] );

	// useEffect for setting the sidebar to the correct scroll point
	useEffect ( () =>
	{
		scrollToPosition ( container , scrollContext.position );
		// eslint-disable-next-line react-hooks/exhaustive-deps
	} , [] );

	// Function to set the scroll position of the sidebar
	function handleScroll ( e )
	{
		scrollContext.setScrollPosition ( e.target.scrollTop );
	}

	// Function to scroll the sidebar to the required position
	function scrollToPosition ( container , position )
	{
		container.current.parentElement.scrollTop = position;
	}

	return (
		<Fragment>
			<RapidRenderTheme themeOverride = { themeUsed }>
				{ props.children }

				<aside
					className = { clsx ( classes.root + ' ' + className , {
						[ classes.rootRtl + ' ' + className ] : rtl ,
					} ) }
				>
					<Drawer
						onScroll = { e => handleScroll ( e ) }
						variant = "permanent"
						id = { "rapidAdminSidebarMiniId" }
						elevation = { drawerElevation }
						className = {
							clsx ( classes.drawerAnimate , {
								[ classes.drawerOpen ] : open ,
								[ classes.drawerClose ] : !open ,
							} ) }
						classes = { {
							paper : clsx ( classes.drawer , {
								[ classes.drawerOpen ] : open ,
								[ classes.drawerClose ] : !open ,
							} ) ,
						} }
					>

						<div
							ref = { container }
						>
							<div
								className = { clsx ( classes.toolbar , {
									[ classes.hide ] : !open ,
								} ) }
							>
								{/* Check if the logo exists and render it*/ }
								{ logo ?
									<a className = { classes.logoContainer } href = { settings.config.appUrl }>
										<img
											alt = { logoAltText }
											style = { { width : { logoHeight } } }
											className = { classes.rapidLogo }
											src = { logo }
										/>
									</a>
									:
									false
								}

								<IconButton
									aria-label = "Close Drawer"
									color = "primary"
									className = { classes.closeButton }
									onClick = { () =>
									{
										sidebarContext.toggle ()
									} }>
									{ parentTheme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
								</IconButton>
							</div>


							{/* Check if RapidMiniProfile is enabled and render it */ }
							{ miniProfile ?
								(
									<Box className = {
										clsx ( {
											[ classes.profileVisible ] : open ,
											[ classes.profileHidden ] : !open ,
										} ) }
										 mx = { 2 }
										 mt = { 4 }
										 mb = { 2 }
									>
										<RapidMiniProfile { ...miniProfileProps } />
									</Box>
								)
								:
								false
							}
							<RapidNavbarLinks renderToolTip = { renderToolTip } navigation = { navigation } />
						</div>
					</Drawer>
				</aside>
			</RapidRenderTheme>
		</Fragment>
	)
}

//Assign default props
RapidSidebarMini.defaultProps = {
	logo : themeLogo ,
	logoAltText : 'Rapid Admin React Logo' ,
	logoHeight : '32px' ,
	renderToolTip : true ,
	themeUsed : 'dark' ,
	navigation : navigation ,
	miniProfile : true ,
	toggle : true ,
	drawerElevation : 0 ,
};

/**
 * propTypes for the component
 */
RapidSidebarMini.propTypes = {
	/**
	 *  Image that needs to be used as a logo in the header of the sidebar. Uses the theme logo as the `default` value
	 */
	logo : PropTypes.node ,

	/**
	 *  Image that needs to be used as a logo in the header of the sidebar. Uses the theme logo as the `default` value
	 */
	logoAltText : PropTypes.string ,

	/**
	 *  The height of the logo tyhe width is set to auto and renders in same aspect ratio as source file.
	 */
	logoHeight : PropTypes.string ,

	/**
	 *  Whether a tooltip is to be rendered on navlink elements when the sidebar collapses to a RapidSidebarMini
	 */
	renderToolTip : PropTypes.bool ,

	/**
	 *  Whether a tooltip is to be rendered on navlink elements when the sidebar collapses to a RapidSidebarMini
	 */
	themeUsed : PropTypes.oneOf ( [ 'light' , 'dark' ] ) ,

	/**
	 *  The navigation object that you want to render in the sidebar. The default navigation that is passed to the object is the navigation in the `src/confg/navbars'navigation.js`
	 */
	navigation : PropTypes.array ,

	/**
	 *  Choose if you want to render the RapidMiniProfile component in the navigation or not.
	 */
	miniProfile : PropTypes.bool ,

	/**
	 *  Send props to RapidMiniProfile. See the following link to check avaialable props [RapidMiniProfile](https://react-docs.rapidadm.in/api-documentation/#/User?id=rapidminiprofile)
	 */
	miniProfileProps : PropTypes.object ,

	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,

	/**
	 *  Background color for the sidebar, Can be `#`, `rgb` or `rgba` values. best possible values are set based on your theme but you can override the default value using this prop.
	 */
	backgroundColor : PropTypes.string ,
	/**
	 *  Enable or Disbale Toggle of the sidebar on the Desktop Resolution
	 */
	toggle : PropTypes.bool ,

	/**
	 *  Elevation assigned to the drawer
	 */
	drawerElevation : PropTypes.number ,

};
