import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import axe from 'react-axe';

/**
 *  Use React Axe only in development to test the Accessibilty of the react components and Semantic HTML
 */
if ( process.env.NODE_ENV !== 'production' )
{
	axe ( React , ReactDOM , 1000 );
}

/**
 *  Render the React App
 */
ReactDOM.render (
	<App /> ,
	document.getElementById ( 'root' )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister ();
