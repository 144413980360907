import React , { useState } from "react";

export const RapidThemeSwitchButtonContext = React.createContext ( {
	checked : true ,
	toggleChecked : () =>
	{
	} ,
} );

export function RapidThemeSwitchButtonContextProvider ( props )
{
	const [ checked , setChecked ] = useState ( true );

	function toggleTheme ( checkStatus )
	{
		setChecked ( checkStatus )
	}

	return (
		<RapidThemeSwitchButtonContext.Provider
			value = {
				{
					checked : checked ,
					toggleChecked : toggleTheme ,
				}
			}
		>
			{ props.children }
		</RapidThemeSwitchButtonContext.Provider>
	)
}

