// Creating the actions component for each email
import React , { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { SelectAllEmail } from "../../../../context";

export default function SelectAllActions ( props )
{
	// Extract all the props
	const
		{
			selectAllActions ,
		} = props;

	// Declare styling rules
	const useStyles = makeStyles ( ( theme ) => ( {
		wrapper : {
			'& ul' : {
				padding : theme.spacing ( 0 ) ,
			}
		} ,
		actionItem : {
			padding : theme.spacing ( 1.25 , 2 ) ,
			display : 'flex' ,
			alignItems : 'center' ,
		} ,
		actionIcon : {
			minWidth : 'auto' ,
			marginRight : theme.spacing ( 1 ) ,
			'& svg' : {
				fontSize : theme.typography.body1.fontSize ,
				fontWeight : theme.typography.body1.fontWeight ,
				fontFamily : theme.typography.body1.fontFamily ,
				lineHeight : theme.typography.body1.lineHeight ,
			}
		} ,
		actionText : {
			fontSize : theme.typography.body1.fontSize ,
			fontWeight : theme.typography.body1.fontWeight ,
			fontFamily : theme.typography.body1.fontFamily ,
			lineHeight : theme.typography.body1.lineHeight ,
		}
	} ) );
	// Create classes
	const classes = useStyles ();

	const [ anchorEl , setAnchorEl ] = React.useState ( null );
	const open = Boolean ( anchorEl );

	// Handle click for the actions menu
	const handleClick = ( event ) =>
	{
		setAnchorEl ( event.currentTarget );
	};

	// Handle close for the menu
	const handleClose = () =>
	{
		setAnchorEl ( null );
	};

	// Use the context for the Select All email
	const selectAllContext = useContext ( SelectAllEmail );

	// Get the status of all the emails from the context
	let allEmailsStatus = selectAllContext.emails;

	return (
		<>
			<IconButton
				aria-label = "more"
				aria-controls = "long-menu"
				aria-haspopup = "true"
				onClick = { handleClick }
				size = "small"
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id = "select-all-email-actions-menu"
				anchorEl = { anchorEl }
				keepMounted
				className = { classes.wrapper }
				open = { open }
				onClose = { handleClose }
				PaperProps = { {
					style : {
						width : '150px' ,
						padding : '0px' ,
					} ,
				} }
			>
				{ selectAllActions.map ( ( action ) => (
					<MenuItem
						className = { classes.actionItem }
						key = { action.actionId }
						id = { action.actionId }
						onClick = { ( e ) => action.onActionClick ( e, allEmailsStatus ) }
					>
						<ListItemIcon id = { action.actionId } className = { classes.actionIcon }>
							{ action.actionIcon }
						</ListItemIcon>
						<Box
							id = { action.actionId }
							component = "span"
							className = { classes.actionText }
						>
							{ action.actionLabel }
						</Box>
					</MenuItem>
				) ) }
			</Menu>
		</>
	);
}

SelectAllActions.defaultProps = {};

SelectAllActions.propTypes = {
	/**
	 *  An array of objects that would be passed as actions to each emails action button
	 */
	selectAllActions : PropTypes.arrayOf (
		PropTypes.shape ( {
			/** The icon that would be displayed for the action */
			actionIcon : PropTypes.node ,
			/** Pass a function that would handle the click for an action. You byb default get an `event` object when user clicks on the action as a param in your function */
			onActionClick : PropTypes.func ,
			/** A uniqe ID for each of the actions */
			actionId : PropTypes.string ,
			/** A label for the action  */
			actionLabel : PropTypes.string ,
		} )
	).isRequired ,
};

