import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import BeenhereIcon from '@material-ui/icons/Beenhere';
import Button from "@material-ui/core/Button";
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

export default function RapidUserProfile ( props )
{
	// Extract all the props
	const {
		className ,
		title ,
		titleFontVariant ,
		titleComponent ,
		avatarWidth ,
		avatarImage ,
		profileName ,
		approvedIcon ,
		isApproved ,
		socialHandle ,
		messageIcon ,
		messageButtonVariant ,
		messageButtonColor ,
		messageButtonText ,
		extraInfo ,
		accountStats ,
		messageButtonClick ,
	} = props;

	// Create styles for the component
	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			width : '100%' ,
		} ,
		title : {
			padding : theme.spacing ( 2 ) ,
		} ,
		imageSection : {
			display : 'flex' ,
			justifyContent : 'space-between' ,
			alignItems : 'center' ,
			padding : theme.spacing ( 3 , 2 ) ,
		} ,
		imageWrap : {
			display : 'inline-flex' ,
			width : `${ avatarWidth }px` ,
		} ,
		avatar : {
			width : `${ avatarWidth }px` ,
			height : `${ avatarWidth }px` ,
		} ,
		imageInfoWrap : {
			display : 'inline-flex' ,
			flexDirection : 'column' ,
			justifyContent : 'start' ,
			marginLeft : theme.spacing ( 3 ) ,
			flexGrow : 1 ,
		} ,
		imageInfoItem : {
			display : 'flex' ,
			width : '100%' ,
		} ,
		imageInfoName : {
			display : 'inline-flex' ,
			'& .MuiSvgIcon-root' : {
				marginLeft : theme.spacing ( 1 ) ,
				fontSize : theme.typography.h5.fontSize ,
				color : theme.palette.success.main ,
			} ,
		} ,
		infoSectionWrap : {
			paddingTop : theme.spacing ( 1 ) ,
			paddingBottom : theme.spacing ( 2 ) ,
			paddingLeft : theme.spacing ( 2 ) ,
			paddingRight : theme.spacing ( 2 ) ,
			display : 'flex' ,
			flexDirection : 'column' ,
		} ,
		infoSection : {
			display : 'flex' ,
			justifyContent : 'space-between' ,
			flexDirection : 'row' ,
			marginBottom : theme.spacing ( 1 ) ,
		} ,
		infoSectionItem : {
			display : 'inline-flex' ,
		} ,
		infoSectionItemLeft : {
			minWidth : "30%" ,
			marginRight : theme.spacing ( 2 ) ,
			textAlign : 'left' ,
		} ,
		infoSectionItemRight : {
			textAlign : 'right' ,
		} ,
		profileCounterSection : {
			display : 'flex' ,
			paddingTop : theme.spacing ( 1 ) ,
			paddingBottom : theme.spacing ( 3 ) ,
			paddingLeft : theme.spacing ( 2 ) ,
			paddingRight : theme.spacing ( 2 ) ,
			justifyContent : 'space-between' ,
			alignItems : "center" ,
		} ,
		counterWrapper : {
			display : 'inline-flex' ,
			flexDirection : 'column' ,
			alignItems : 'center' ,
		} ,
		counterItem : {
			display : 'flex' ,
			width : '100%' ,
			textAlign : 'center' ,
			justifyContent : 'center' ,
			alignContent : 'stretch' ,
		}
	} ) );

	// Create the classes
	const classes = useStyles ();

	return (
		<Paper className = { `${ classes.root } ${ className }` }>
			<Typography
				variant = { titleFontVariant }
				component = { titleComponent }
				className = { classes.title }
			>
				{ title }
			</Typography>
			<Divider />

			{/*Image Section */ }
			<Box className = { classes.imageSection }>
				<Box className = { classes.imageWrap }>
					<Avatar
						className = { classes.avatar }
						src = { avatarImage }
					/>
				</Box>
				<Box className = { classes.imageInfoWrap }>
					<Box
						className = { `${ classes.imageInfoItem }` }>
						<Typography
							className = { `${ classes.imageInfoName }` }
							variant = "subtitle1"
						>
							{ `${ profileName } ` }
							{ isApproved ? ( approvedIcon ) : null }
						</Typography>
					</Box>
					<Box
						className = { `${ classes.imageInfoItem }` }
						mt = { .25 }
					>
						<Typography
							className = { `${ classes.imageInfoName }` }
							variant = "caption"
						>
							{ socialHandle }
						</Typography>
					</Box>
					<Box
						className = { `${ classes.imageInfoItem }` }
						mt = { 1.5 }
					>
						<Button
							startIcon = { messageIcon }
							size = "small"
							variant = { messageButtonVariant }
							color = { messageButtonColor }
							onClick = { ( e ) => messageButtonClick ( e ) }
						>
							{ messageButtonText }
						</Button>
					</Box>
				</Box>
			</Box>
			{/*Image Section */ }

			{/*Information Section */ }
			{
				Array.isArray ( extraInfo ) && extraInfo.length > 0 ?
					(
						<Box
							className = { classes.infoSectionWrap }
						>
							{ extraInfo.map ( ( info ) => (
								<Box className = { classes.infoSection }>
									<Box
										className = { ` ${ classes.infoSectionItem } ${ classes.infoSectionItemLeft }` }
									>
										<Typography
											variant = "subtitle2"
										>
											{ info.label }
										</Typography>
									</Box>

									<Box
										className = { ` ${ classes.infoSectionItem } ${ classes.infoSectionItemRight }` }
									>
										<Typography
											variant = "body2"
										>
											{ info.value }
										</Typography>
									</Box>
								</Box>
							) ) }
						</Box>
					)
					:
					null
			}
			<Box
				className = { classes.profileCounterSection }
			>
				{
					Array.isArray ( accountStats ) && accountStats.length ?
						(

							accountStats.map ( ( stats ) =>
								(
									<Box
										className = { classes.counterWrapper }
									>
										<Box
											className = { classes.counterItem }
										>
											<Typography
												variant = "h1"
												component = "h6"
											>
												{ stats.count }
											</Typography>
										</Box>
										<Box
											className = { classes.counterItem }

										>
											<Typography
												variant = "subtitle2"
												component = "p"
												color = "textSecondary"
											>
												{ stats.label }
											</Typography>
										</Box>
									</Box>
								) )
						)
						:
						null
				}
			</Box>
			{/*Information Section */ }
		</Paper>
	)
}

//Declare default Props
RapidUserProfile.defaultProps = {
	title : 'Profile Details' ,
	titleFontVariant : 'h5' ,
	titleComponent : 'h2' ,
	avatarWidth : 80 ,
	avatarImage : '/assets/images/avatars/martha-ceazer.jpg' ,
	profileName : 'Martha Ceaser' ,
	approvedIcon : <BeenhereIcon /> ,
	isApproved : true ,
	socialHandle : '@marthaceaser' ,
	messageIcon : <QuestionAnswerIcon /> ,
	messageButtonVariant : 'outlined' ,
	messageButtonColor : 'primary' ,
	messageButtonText : 'Message' ,
	extraInfo : [] ,
	accountStats : [] ,
	messageButtonClick : ( e ) =>
	{
		console.log ( e )
	} ,
};

// Declare PropTypes for the component
RapidUserProfile.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  Title of the profile
	 */
	title : PropTypes.string ,
	/**
	 *  The font variant that should be used for the title of the component
	 */
	titleFontVariant : PropTypes.oneOf ( [ 'h1' , 'h2' , 'h3' , 'h4' , 'h5' , 'h6' , 'subtitle1' , 'subtitle2' ] ) ,
	/**
	 *  The component that should be used for the title.
	 */
	titleComponent : PropTypes.oneOf ( [ 'h1' , 'h2' , 'h3' , 'h4' , 'h5' , 'h6' ] ) ,
	/**
	 *  A valid width value which will be converted to px value and applied to height as well
	 */
	avatarWidth : PropTypes.number ,
	/**
	 * Avatar image path
	 */
	avatarImage : PropTypes.string ,
	/**
	 * The name of the profile
	 */
	profileName : PropTypes.string ,
	/**
	 * official profile icon. This icon is displayed next to the the name of the user
	 */
	approvedIcon : PropTypes.node ,
	/**
	 * The approved icon would only be displayed if this is set to true
	 */
	isApproved : PropTypes.bool ,
	/**
	 * Social handle of the profile
	 */
	socialHandle : PropTypes.string ,
	/**
	 * Message Button Icon
	 */
	messageIcon : PropTypes.node ,
	/**
	 * Message Button Icon
	 */
	messageButtonVariant : PropTypes.oneOf ( [ 'contained' , 'outlined' ] ) ,
	/**
	 * Message Button Color
	 */
	messageButtonColor : PropTypes.oneOf ( [ 'primary' , 'secondary' , 'default' ] ) ,
	/**
	 * Message Button Text
	 */
	messageButtonText : PropTypes.string ,
	/**
	 * Message Button Function. You get the event object by default as the parameter for your function
	 */
	messageButtonClick : PropTypes.func ,
	/**
	 * Extra info that needs to be added to the user pofile
	 */
	extraInfo : PropTypes.arrayOf (
		PropTypes.shape (
			{
				label : PropTypes.string ,
				value : PropTypes.string
			}
		) ) ,
	/**
	 * The stats of the account
	 */
	accountStats : PropTypes.arrayOf (
		PropTypes.shape (
			{
				count : PropTypes.string ,
				label : PropTypes.string
			}
		)
	) ,
};