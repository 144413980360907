import React from 'react';
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { Link } from 'react-router-dom';
import { makeStyles , useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { theme , themeDark } from "../../../../config/theme";
import settings from "../../../../config/rapidAdmin/settings";
import RapidRenderTheme from "../../../tools/RapidRenderTheme/RapidRenderTheme";

export default function RapidMiniProfile ( props )
{
	// Extract all props
	const {
		links ,
		profile ,
		className ,
		themeUsed ,
		backgroundColor ,
	} = props;

	// Get the parent theme in which the component is nested
	const parentTheme = useTheme ();

	// Check the theme selected by the user and select a theme based on that
	const selectedTheme = selectTheme ( themeUsed );

	// Function to select the background color
	function setBackgroundColor ( backgroundColor )
	{
		if ( backgroundColor )
		{
			return backgroundColor;
		}
		else
		{
			return selectedTheme.palette.action.hover;
		}
	}

	// Function to select the active theme
	function selectTheme ( themeUsed )
	{
		if ( themeUsed === 'light' )
		{
			return theme;
		}
		else if ( themeUsed === 'dark' )
		{
			return themeDark
		}
		else
		{
			return parentTheme
		}
	}

	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			display : "flex" ,
			alignItems : "center" ,
			padding : selectedTheme.spacing ( 2 ) ,
			backgroundColor : setBackgroundColor ( backgroundColor ) ,
			borderRadius : selectedTheme.shape.borderRadius + 'px' ,
		} ,
		rootRtl : {
			flexDirection : 'row-reverse' ,
		} ,
		profileImageContainer : {
			display : "inline-flex" ,
			marginRight : selectedTheme.spacing ( 2.5 ) ,
		} ,
		profileImageContainerRtl : {
			display : "inline-flex" ,
			marginLeft : selectedTheme.spacing ( 2.5 ) ,
		} ,
		profileImage : {
			width : "48px" ,
			height : "48px" ,
			borderRadius : "50%" ,
		} ,
		infoContainer : {} ,
		infoContainerRtl : {
			textAlign : 'right' ,
		} ,
		icon : {
			color : selectedTheme.palette.text.primary ,
			transition : theme.transitions.create ( [ 'color' ] , {
				easing : theme.transitions.easing.sharp ,
				duration : theme.transitions.duration.enteringScreen ,
			} ) ,

			'&:hover' : {
				color : selectedTheme.palette.primary.main ,
			}

		} ,
		iconLtr : {
			marginRight : selectedTheme.spacing ( 1 ) ,
		} ,
		iconRtl : {
			marginLeft : selectedTheme.spacing ( 1 ) ,
		} ,
	} ) );

	//Define important constants to be used across the component
	const classes = useStyles ();
	const direction = settings.config.themeDirection;
	const ltr = direction === 'ltr';
	const rtl = direction === 'rtl';

	// HOC to sanitize and add classes to the the icons in the profile
	function RenderIcons ( props )
	{
		// Extract the link
		const { link } = props;

		return (
			<Link
				aria-label = { link.name }
				to = { link.link }
			>
			<span className = { clsx ( classes.icon , {
				[ classes.iconLtr ] : ltr ,
				[ classes.iconRtl ] : rtl ,
			} ) }
			>
				{ link.icon }
			</span>
			</Link>

		)
	}

	return (
		<RapidRenderTheme themeOverride = { themeUsed }>
			<Box
				className = { clsx ( classes.root + ' ' + className , {
					//[ classes.rootRtl ] : rtl ,
				} ) }
				display = "flex"
			>
				<div
					className = { clsx ( classes.profileImageContainer , {
						[ classes.profileImageContainer ] : ltr ,
						//[ classes.profileImageContainerRtl ] : rtl ,
					} ) }
				>
					<img key = "profileImage" className = { classes.profileImage } src = { profile.image } alt = { profile.altText } />
				</div>

				<div
					className = { clsx ( classes.infoContainer , {
						[ classes.infoContainer ] : ltr ,
						//[ classes.infoContainerRtl ] : rtl ,
					} ) }
				>
					<Typography component = "div" color = "textPrimary" variant = { "subtitle2" }>{ profile.name }</Typography>
					<Box component = "div" mt = { 1 }>
						{
							links ?
								(
									links.map ( ( link , index ) => (
										<RenderIcons
											key = { link.name + index }
											link = { link }
											index = { index }
										/>
									) )
								)
								: false
						}
					</Box>
				</div>
			</Box>
		</RapidRenderTheme>
	);
}

//Assign default props
RapidMiniProfile.defaultProps = {
	themeUsed : 'dark' ,
	links : [] ,
};

/**
 * propTypes for the component
 */
RapidMiniProfile.propTypes = {
	/**
	 *  The links object that renders the navigation links along with the icon for each of the link in the component
	 */
	links : PropTypes.array ,
	/**
	 *  An containing the profile name, image as well the alt-text for the profile image
	 */
	profile : PropTypes.object ,
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 * if you want to switch between light and dark theme you can do so using this prop
	 */
	themeUsed : PropTypes.oneOf ( [ 'light' , 'dark' , 'inherit' ] ) ,

	/**
	 *  Background color that needs to be applied to the component, Provide value in #, rgb or rgba
	 */
	backgroundColor : PropTypes.string ,
};
