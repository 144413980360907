import React from 'react';
import { makeStyles , withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Notifications from '@material-ui/icons/Notifications';
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import RapidRenderTheme from "../../../tools/RapidRenderTheme/RapidRenderTheme";

// Import Icons
import ChevronRight from '@material-ui/icons/ChevronRight';

// Styliogn for the RenderIcon Component
const iconStyles = makeStyles ( ( theme ) => ( {
	errorIcon : {
		color : theme.palette.error.main ,
	} ,
	successIcon : {
		color : theme.palette.success.main ,
	} ,
	warningIcon : {
		color : theme.palette.warning.main ,
	} ,
	infoIcon : {
		color : theme.palette.info.main ,
	} ,
} ) );

// HOC to render the correct status icon and color for the notification
function RenderIcon ( props )
{
	// Extract All Props
	const {
		notification ,
	} = props;

	// Create classes for icon color
	const iconClasses = iconStyles ();

	if ( notification.priority === 'success' )
	{
		return (
			<span className = { iconClasses.successIcon }>
				{ notification.icon }
			</span>
		)
	}
	else if ( notification.priority === 'error' )
	{
		return (
			<span className = { iconClasses.errorIcon }>
				{ notification.icon }
			</span>
		)
	}
	else if ( notification.priority === 'info' )
	{
		return (
			<span className = { iconClasses.infoIcon }>
				{ notification.icon }
			</span>
		)
	}
	else if ( notification.priority === 'warning' )
	{
		return (
			<span className = { iconClasses.warningIcon }>
				{ notification.icon }
			</span>
		)
	}
	else
	{
		return (
			<span>
				{ notification.icon }
			</span>
		)
	}
}

// HOC to render the Link
function RenderLink ( props )
{
	// Extract all props
	const { notification , className } = props;

	if ( notification.link )
	{
		return (
			<span className = { className }>
				<Link to = { notification.link }>
					{ props.children }
				</Link>
			</span>
		)
	}
	else
	{
		return props.children
	}

}

/**
 * RapidNotifications - a component used to display notifications to the user
 * @since 1.0.0
 */
export default function RapidNotifications ( props )
{
	// Extract All Props
	const {
		className ,
		data ,
		viewAllLink ,
		width ,
		badgeColor ,
		viewAllButton ,
		viewAllText
	}
		= props;

	// Create the classes for styling
	const useStyles = makeStyles ( ( theme ) => ( {
		link : {
			'& a' : {
				textDecoration : 'none' ,
				display : 'flex' ,
				alignItems : 'center' ,
			}
		} ,
		iconButton : {
			color : theme.palette.text.primary ,
		} ,
		styledMenu : {
			paddingTop : theme.spacing ( 2 ) ,
			paddingBottom : theme.spacing ( 2 ) ,
		} ,
		root : {
			minWidth : '40px' ,
		} ,
		listItemText : {
			'& span' : {
				whiteSpace : 'normal' ,
				fontFamily : theme.typography.subtitle2.fontFamily ,
				fontSize : theme.typography.subtitle2.fontSize ,
				fontWeight : theme.typography.subtitle2.fontWeight ,
				color : theme.palette.text.primary ,
				lineHeight : theme.typography.subtitle2.lineHeight ,
				textDEcoration : 'none' ,
			} ,

		} ,
		listItemDescription : {
			'& span' : {
				whiteSpace : 'normal' ,
				fontFamily : theme.typography.caption.fontFamily ,
				fontSize : theme.typography.caption.fontSize ,
				fontWeight : theme.typography.caption.fontWeight ,
				lineHeight : theme.typography.caption.lineHeight ,
				color : theme.palette.text.primary ,
				textDEcoration : 'none' ,
			} ,
		} ,
		navigateIcon : {
			minWidth : '20px' ,
		} ,
		viewAll : {
			justifyContent : 'center' ,
		}
	} ) );
	// Create classes for styling
	const classes = useStyles ();

	// Hoc to override the menu styling
	const StyledMenu = withStyles ( {
		paper : {
			border : 'none' ,
			width : width ,
		} ,
	} ) ( ( props ) => (
		<Menu
			elevation = { 12 }
			getContentAnchorEl = { null }
			anchorOrigin = { {
				vertical : 'bottom' ,
				horizontal : 'center' ,
			} }
			transformOrigin = { {
				vertical : 'top' ,
				horizontal : 'center' ,
			} }
			{ ...props }
		/>
	) );

	// Create state for opening and closing of the notifications
	const [ anchorEl , setAnchorEl ] = React.useState ( null );

	// Function to handle opening and closing of the notifications
	const handleClick = ( event ) =>
	{
		setAnchorEl ( event.currentTarget );
	};

	const handleClose = () =>
	{
		setAnchorEl ( null );
	};

	return (

		<div className = { className }>
			<IconButton
				className = { classes.iconButton }
				aria-controls = "customized-menu"
				aria-haspopup = "true"
				variant = "contained"
				onClick = { handleClick }
			>
				<Badge badgeContent = { 4 } color = { badgeColor }>
					<Notifications />
				</Badge>
			</IconButton>
			<RapidRenderTheme>
				<StyledMenu
					id = "customized-menu"
					anchorEl = { anchorEl }
					keepMounted
					open = { Boolean ( anchorEl ) }
					onClose = { handleClose }
				>
					<Box px = { 2 } pb = { 1 }>
						<Typography variant = "h5" component = "h3">Notifications</Typography>
					</Box>
					<Divider />

					{ data ?
						(
							data.map ( ( notification ) => (
								<span key = { notification.id }>
										<MenuItem
											id = { notification.id }
											className = { classes.styledMenu }
											color = "default"
										>
											<RenderLink
												className = { classes.link }
												notification = { notification }
											>
												<ListItemIcon className = { classes.root }>
													<RenderIcon notification = { notification } />
												</ListItemIcon>
												<Box>
													<ListItemText className = { classes.listItemText } primary = { notification.title } />
													<ListItemText className = { classes.listItemDescription } primary = { notification.desc } />
												</Box>
												{ notification.link ?
													(
														<ListItemIcon className = { classes.navigateIcon }>
															<ChevronRight fontSize = "small" />
														</ListItemIcon>
													)
													: false }
											</RenderLink>
										</MenuItem>
									<Divider />
								</span>
							) )
						) : false
					}

					{ viewAllButton ?
						(
							<Box className = { classes.viewAll } pt = { 1 } display = "flex">
								<Button component = { Link } to = { viewAllLink } size = "small">{ viewAllText }</Button>
							</Box>
						)
						: false
					}


				</StyledMenu>
			</RapidRenderTheme>
		</div>

	);
}

// Default props for the component
RapidNotifications.defaultProps = {
	width : '300px' ,
	badgeColor : 'primary' ,
	viewAllButton : true ,
	viewAllText : 'View All Notifications' ,
	viewAllLink : '#' ,
};

// Prop types for the component
RapidNotifications.propTypes = {
	/**
	 *  An array of notification objects that need to be rendered by the the RapidNotifications component
	 */
	data : PropTypes.array ,

	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,

	/**
	 * Boolean to display the view all button
	 */
	viewAllButton : PropTypes.bool ,

	/**
	 * The link to be passed to the view all notifications button
	 */
	viewAllLink : PropTypes.string ,

	/**
	 * View all button text
	 */
	viewAllText : PropTypes.string ,

	/**
	 * The width of the notifications box. Any valid width unit passed as a string would work
	 */
	width : PropTypes.string ,

	/**
	 * Background color assigned to the counter badge. Possible options 'default' | 'error' | 'primary' | 'secondary'
	 */
	badgeColor : PropTypes.string ,

};