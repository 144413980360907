// Contains links to all the Routes on your application in one place
const routePaths = {
	// Rapid admin Pages
	dashboard : '/' ,
	calendar : '/calendar' ,
	login : '/login' ,
	register : '/register' ,
	forgotPassword : '/forgot-password' ,
	kanban : '/kanban' ,
	chat : '/chat-widget' ,
	inbox : '/inbox' ,
	notFound : '/404' ,
	userProfile : '/user-profile' ,

	// RapidAdmin Components
	rapidTimeline : '/rapid-timeline' ,
	rapidPageTitles : '/rapid-page-titles' ,
	rapidMiniProfile : '/rapid-mini-profile' ,
	rapidCkEditor : '/rapid-ckeditor' ,
	rapidNotifications : '/rapid-notifications' ,
	rapidTaskStatusTable : '/rapid-task-status-table' ,
	rapidQuickStats : '/rapid-quick-stats' ,

	// Material UI Compionent Paths
	buttons : '/materialui/buttons' ,
	buttonGroup : '/materialui/button-group' ,
	checkbox : '/materialui/checkbox' ,
	FloatingActionButton : '/materialui/floating-action-button' ,
	datePickers : '/materialui/date-pickers' ,
	radio : '/materialui/radio' ,
	select : '/materialui/select' ,
	slider : '/materialui/slider' ,
	switches : '/materialui/switches' ,
	textField : '/materialui/text-field' ,
	transferList : '/materialui/transfer-list' ,
	bottomNavigation : '/materialui/bottom-navigation' ,
	breadcrumbs : '/materialui/breadcrumbs' ,
	drawer : '/materialui/drawer' ,
	menus : '/materialui/menus' ,
	stepper : '/materialui/stepper' ,
	tabs : '/materialui/tabs' ,
	appBar : '/materialui/app-bar' ,
	paper : '/materialui/paper' ,
	cards : '/materialui/cards' ,
	accordion : '/materialui/accordion' ,
	progress : '/materialui/progress' ,
	dialog : '/materialui/dialog' ,
	snackbar : '/materialui/snackbar' ,
	backdrop : '/materialui/backdrop' ,
	avatar : '/materialui/avatar' ,
	badge : '/materialui/badge' ,
	chip : '/materialui/chip' ,
	divider : '/materialui/divider' ,
	lists : '/materialui/lists' ,
	table : '/materialui/table' ,
	tooltip : '/materialui/tooltip' ,
	alert : '/materialui/alert' ,
	autocomplete : '/materialui/autocomplete' ,
	pagination : '/materialui/pagination' ,
	rating : '/materialui/rating' ,
	skeleton : '/materialui/skeleton' ,
	speedDial : '/materialui/speed-dial' ,
	timeline : '/materialui/timeline' ,
	toggleButtons : '/materialui/toggle-buttons' ,
	treeView : '/materialui/tree-view' ,
};

export default routePaths;
