import React , { lazy } from 'react';
import routePaths from "./paths";
import { RapidPreLoader } from "../../@rapidAdmin/components";

export const routes = [
	// {
	// 	path : routePaths.login ,
	// 	exact : true ,
	// 	component : lazy ( () => import('../../pages/Register/Register') ) ,
	// 	redirect : false ,
	// 	private : false ,
	// 	fallback : ( <RapidPreLoader /> ) ,
	// 	routes : [
	// 		{
	// 			path : routePaths.login ,
	// 			exact : true ,
	// 			component : lazy ( () => import('../../pages/Register/Register') ) ,
	// 			redirect : false ,
	// 			private : false ,
	// 			fallback : ( <RapidPreLoader /> ) ,
	// 		}
	// 	]
	// } ,
	{
		path : routePaths.dashboard ,
		component : lazy ( () => import('../../pages/Dashboard/Dashboard') ) ,
		exact : true ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.calendar ,
		component : lazy ( () => import('../../pages/Calendar/Calendar') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.kanban ,
		component : lazy ( () => import('../../pages/Kanban/Kanban') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.inbox ,
		component : lazy ( () => import('../../pages/Inbox/Inbox') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.chat ,
		component : lazy ( () => import('../../pages/Chat/Chat') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.login ,
		component : lazy ( () => import('../../pages/Login/Login') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.register ,
		component : lazy ( () => import('../../pages/Register/Register') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.forgotPassword ,
		component : lazy ( () => import('../../pages/ForgotPassword/ForgotPassword') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.notFound ,
		component : lazy ( () => import('../../pages/404/404') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.userProfile ,
		component : lazy ( () => import('../../pages/UserProfile/UserProfile') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,

	// RapidAdmin Component Routes
	{
		path : routePaths.rapidTimeline ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidTimeline/RapidTimeline') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidPageTitles ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidPageTitles/RapidPageTitlesPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidMiniProfile ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidMiniProfile/RapidMiniProfile') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidCkEditor ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidCkeEditor/RapidCkeEditorPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidNotifications ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidNotificationsPage/RapidNotificationsPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidTaskStatusTable ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidTaskStatusTablePage/RapidTaskStatusTablePage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rapidQuickStats ,
		component : lazy ( () => import('../../pages/rapidAdminComponents/RapidQuickStatsPage/RapidQuickStatsPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,

	// Material Ui Component Routes
	{
		path : routePaths.buttons ,
		component : lazy ( () => import('../../pages/materialUiComponents/Buttons/Buttons') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.buttonGroup ,
		component : lazy ( () => import('../../pages/materialUiComponents/ButtonGroup/ButtonGroup') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.checkbox ,
		component : lazy ( () => import('../../pages/materialUiComponents/Checkbox/Checkbox') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.FloatingActionButton ,
		component : lazy ( () => import('../../pages/materialUiComponents/FloatingActionButton/FloatingActionButton') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.datePickers ,
		component : lazy ( () => import('../../pages/materialUiComponents/DateTime/DateTime') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.radio ,
		component : lazy ( () => import('../../pages/materialUiComponents/Radio/Radio') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.select ,
		component : lazy ( () => import('../../pages/materialUiComponents/Select/Select') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.slider ,
		component : lazy ( () => import('../../pages/materialUiComponents/SliderPage/SliderPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.switches ,
		component : lazy ( () => import('../../pages/materialUiComponents/Switches/Switches') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.textField ,
		component : lazy ( () => import('../../pages/materialUiComponents/TextField/TextField') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.transferList ,
		component : lazy ( () => import('../../pages/materialUiComponents/TransferList/TransferList') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.bottomNavigation ,
		component : lazy ( () => import('../../pages/materialUiComponents/BottomNavigation/BottomNavigation') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.breadcrumbs ,
		component : lazy ( () => import('../../pages/materialUiComponents/Breadcrumbs/Breadcrumbs') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.drawer ,
		component : lazy ( () => import('../../pages/materialUiComponents/Drawer/Drawer') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.menus ,
		component : lazy ( () => import('../../pages/materialUiComponents/Menus/Menus') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.stepper ,
		component : lazy ( () => import('../../pages/materialUiComponents/Stepper/Stepper') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.tabs ,
		component : lazy ( () => import('../../pages/materialUiComponents/Tabs/Tabs') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.appBar ,
		component : lazy ( () => import('../../pages/materialUiComponents/AppBar/AppBar') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.paper ,
		component : lazy ( () => import('../../pages/materialUiComponents/Paper/PaperPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.cards ,
		component : lazy ( () => import('../../pages/materialUiComponents/Cards/Cards') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.accordion ,
		component : lazy ( () => import('../../pages/materialUiComponents/Accordion/Accordion') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.progress ,
		component : lazy ( () => import('../../pages/materialUiComponents/Progress/Progress') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.dialog ,
		component : lazy ( () => import('../../pages/materialUiComponents/Dialog/Dialog') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.snackbar ,
		component : lazy ( () => import('../../pages/materialUiComponents/Snackbar/Snackbar') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.backdrop ,
		component : lazy ( () => import('../../pages/materialUiComponents/Backdrop/Backdrop') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.avatar ,
		component : lazy ( () => import('../../pages/materialUiComponents/Avatar/Avatar') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.badge ,
		component : lazy ( () => import('../../pages/materialUiComponents/Badge/Badge') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.chip ,
		component : lazy ( () => import('../../pages/materialUiComponents/Chip/Chip') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.divider ,
		component : lazy ( () => import('../../pages/materialUiComponents/Divider/DividerPage') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.lists ,
		component : lazy ( () => import('../../pages/materialUiComponents/Lists/Lists') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.table ,
		component : lazy ( () => import('../../pages/materialUiComponents/Table/Table') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.tooltip ,
		component : lazy ( () => import('../../pages/materialUiComponents/Tooltip/Tooltip') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.alert ,
		component : lazy ( () => import('../../pages/materialUiComponents/Alert/Alert') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.autocomplete ,
		component : lazy ( () => import('../../pages/materialUiComponents/Autocomplete/Autocomplete') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.pagination ,
		component : lazy ( () => import('../../pages/materialUiComponents/Pagination/Pagination') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.rating ,
		component : lazy ( () => import('../../pages/materialUiComponents/Rating/Rating') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.skeleton ,
		component : lazy ( () => import('../../pages/materialUiComponents/Skeleton/Skeleton') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.speedDial ,
		component : lazy ( () => import('../../pages/materialUiComponents/SpeedDial/SpeedDial') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.timeline ,
		component : lazy ( () => import('../../pages/materialUiComponents/Timeline/Timeline') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.toggleButtons ,
		component : lazy ( () => import('../../pages/materialUiComponents/ToggleButtons/ToggleButtons') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		path : routePaths.treeView ,
		component : lazy ( () => import('../../pages/materialUiComponents/TreeView/TreeView') ) ,
		exact : false ,
		private : true ,
		fallback : ( <RapidPreLoader /> ) ,
	} ,
	{
		component : lazy ( () => import('../../pages/404/404') ) ,
		exact : false ,
		private : false ,
		fallback : ( <RapidPreLoader /> ) ,
	}
];

export default routes;