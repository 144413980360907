// Get the context of the theme
import React , { useContext } from "react";
import { RapidThemeSwitchContext } from "../../context";
import rapidThemeType from "../functions/rapidThemeType/rapidThemeType";
import settings from "../../../config/rapidAdmin/settings";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme , themeDark } from "../../../config/theme";

/**
 * RapidRenderTheme HOC to render the correct Theme that is selected by the user
 * @since 1.0.0
 *
 * @param {Object} props - props for the react component
 */
export default function RapidRenderTheme ( props )
{
	//Extract all props
	const { themeOverride } = props;

	// Get the theme context
	const themeContext = useContext ( RapidThemeSwitchContext );

	// Return the correct theme
	if ( themeOverride === 'light' )
	{
		return (
			<ThemeProvider theme = { theme }>
				{ props.children }
			</ThemeProvider>
		)
	}
	else if ( themeOverride === 'dark' )
	{
		return (
			<ThemeProvider theme = { themeDark }>
				{ props.children }
			</ThemeProvider>
		)
	}
	else if ( rapidThemeType ( settings , themeContext ) === 'dark' )
	{
		return (
			<ThemeProvider theme = { themeDark }>
				{ props.children }
			</ThemeProvider>
		)
	}
	else if ( rapidThemeType ( settings , themeContext ) === 'light' )
	{
		return (
			<ThemeProvider theme = { theme }>
				{ props.children }
			</ThemeProvider>
		)
	}
}