// eslint-disable-next-line no-unused-vars
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import settings from "../../../../config/rapidAdmin/settings";

export default function RapidScrollbars ( props )
{
	// noinspection JSSuspiciousNameCombination
	const useStyles = makeStyles ( ( theme ) => ( {
		'@global' : {
			'*::-webkit-scrollbar' : {
				width : settings.scrollBars.width ,
				height : settings.scrollBars.width ,
				cursor : 'pointer' ,
			} ,
			'*::-webkit-scrollbar-track' : {
				boxShadow : 'none !important' ,
				backgroundColor : theme.palette.action.hover ,
				opacity : 0 ,
				borderRadius : theme.shape.borderRadius + 'px' ,
			} ,
			'*::-webkit-scrollbar-track:hover' : {
				backgroundColor : theme.palette.action.disabled ,
				cursor : 'pointer !important' ,
			} ,
			'*::-webkit-scrollbar-thumb' : {
				backgroundColor : theme.palette.action.active ,
				borderRadius : theme.shape.borderRadius + 'px' ,
				cursor : 'pointer' ,
			} ,
			'*::-webkit-scrollbar-thumb:hover' : {
				backgroundColor : theme.palette.action.active ,
			} ,
			'::-webkit-scrollbar-corner' : {
				'background' : 'rgba(0,0,0,0)' ,
			} ,
		}
	} ) );
	// Apply All Styles Globally
	useStyles ();

	return (
		props.children
	);

}