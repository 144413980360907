import React , { useContext } from "react";
import PropTypes from "prop-types";
import ReactApexChart from 'react-apexcharts';
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core";
import { RapidThemeSwitchContext } from "../../../context";
import emitYAxis from "./helpers/functions/emitYAxis";
import emitXAxis from "./helpers/functions/emitXAxis";
import emitTooltip from "./helpers/functions/emitTooltip";
import emitTitle from "./helpers/functions/emitTitle";
import emitSubtitle from "./helpers/functions/emitSubtitle";
import emitNoData from "./helpers/functions/emitNoData";
import emitChart from "./helpers/functions/emitChart";
import emitTheme from "./helpers/functions/emitTheme";
import emitGrid from "./helpers/functions/emitGrid";
import { makeStyles } from "@material-ui/core/styles";

// Create styling rules
const useStyles = makeStyles ( () => ( {
	apexWrapper : {
		width : '100%' ,
	}
} ) )

export default function RapidApexChart ( props )
{
	// Extract All Props
	const {
		className ,
		series ,
		options ,
		type ,
		height ,
	} = props;

	// create classes for styling
	const classes = useStyles ();

	// Get the parent theme that is being used
	const parentTheme = useTheme ();

	// Set the theme context
	const themeContext = useContext ( RapidThemeSwitchContext );

	return (
		<Box
			id = "rapidAdminApexChart"
			className = { classes.apexWrapper + ' ' + className }
		>
			<ReactApexChart
				height = { height }
				options = {
					{
						...options ,
						theme : emitTheme ( options.theme , parentTheme , themeContext ) ,
						grid : emitGrid ( options.grid , parentTheme ) ,
						chart : emitChart ( options.chart , parentTheme ) ,
						noData : emitNoData ( options.noData , parentTheme ) ,
						subtitle : emitSubtitle ( options.subtitle , parentTheme ) ,
						title : emitTitle ( options.title , parentTheme ) ,
						tooltip : emitTooltip ( options.tooltip , parentTheme ) ,
						xaxis : emitXAxis ( options.xaxis , parentTheme ) ,
						yaxis : emitYAxis ( options.yaxis , parentTheme ) ,
					}
				}
				series = { series }
				type = { type }
			/>
		</Box>
	);
}

// Default prop types
RapidApexChart.defaultProps = {
	type : 'area' ,
	series : [
		{
			name : "Angelina" ,
			data : [ 60 , 50 , 70 , 60 , 90 , 80 ] ,
		} ,
	] ,
	options : {} ,
	height : 'auto' ,
};

// Prop type declaration
RapidApexChart.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  The series of data that needs to be rendered by ApexCharts. You can have a look at the data format on the [Apex Charts Documentation](https://apexcharts.com/docs/options/series/)
	 */
	series : PropTypes.array.isRequired ,
	/**
	 * Chart options that need to be passed as props. You can also use other chart options from the [Apex Chart Documentation](https://apexcharts.com/docs/options/annotations/). We use the default styling elements of the options
	 * to style the chart in-sync with the RapidAdmin React theme.
	 */
	options : PropTypes.object.isRequired ,
	/**
	 * The type of chart that needs to be rendered.
	 */
	type : PropTypes.string.isRequired ,
	/**
	 * Any valid CSS property for the height. Please note if the height is set to '100%' then the parent container must have a fixed height
	 */
	height : PropTypes.string ,

};