// Minisidebar Imports
import { MiniSidebarStateContextProvider } from './';
import { MiniSidebarLinkStateContextProvider } from './';
import { MiniSidebarScrollContextProvidor } from './';
import { MiniSidebarActiveStateContextProvider } from './';
import { MiniSidebarCloseButtonContextProvider } from './';

// Global Context Import
import { RapidThemeSwitchContextProvider } from './';
import { RapidThemeSwitchButtonContextProvider } from './';
import { RapidThemeDirectionContextProvider } from './';
import { RapidThemeDirectionButtonContextProvider } from './';

// Calendar Context Imports
import { CalendarAddEventContextProvider } from './';

// Inbox Context Imports
import { SelectAllEmailContextProvider } from './';
import { ComposeEmailStateContextProvider } from './';

export const rootContext = [
	// Mini Sidebar Context
	MiniSidebarStateContextProvider ,
	MiniSidebarLinkStateContextProvider ,
	MiniSidebarScrollContextProvidor ,
	MiniSidebarActiveStateContextProvider ,
	MiniSidebarCloseButtonContextProvider ,

	// Calendar Context
	CalendarAddEventContextProvider ,

	// Global
	RapidThemeSwitchContextProvider ,
	RapidThemeSwitchButtonContextProvider ,
	RapidThemeDirectionContextProvider ,
	RapidThemeDirectionButtonContextProvider ,

	// Inbox
	SelectAllEmailContextProvider ,
	ComposeEmailStateContextProvider ,
];