import React , { useContext } from 'react';
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RapidCkeEditorClassic } from "../../";
import { ComposeEmailState } from "../../../context";

// Import Icons
import Close from '@material-ui/icons/Close';
import Backup from '@material-ui/icons/BackupOutlined';

// HOC to add a custom wrapper to the paper comonent
function PaperComponent ( props )
{
	return (
		<Draggable handle = "#draggable-dialog-for-email" cancel = { '[class*="MuiDialogContent-root"]' }>
			<Paper { ...props } />
		</Draggable>
	);
}

// HOC for rendering the paper component in the dropdown of the sender suggestions
function SenderPaper ( props )
{
	return (
		<Paper
			elevation = "8"
			{ ...props }
		/>
	)
}

const useStyles = makeStyles ( ( theme ) => ( {
	paper : {
		position : 'absolute' ,
		right : '10px' ,
		bottom : '10px' ,
		minWidth : '600px' ,
	} ,
	titleWrap : {
		display : 'flex' ,
		alignItems : 'center' ,
		padding : `${ theme.spacing ( 1 ) }px  ${ theme.spacing ( 2 ) }px` ,
		justifyContent : 'space-between'
	} ,
	addressWrap : {
		display : 'flex' ,
		flexDirection : 'column' ,
	} ,
	emailEditor : {
		minHeight : '275px' ,
	} ,
	sendWrap : {
		display : 'flex' ,
		alignItems : 'center' ,
		justifyContent : 'space-between' ,
	} ,
	uploadButton : {
		display : 'none' ,
	} ,
	topInput : {
		marginTop : '0px' ,
	} ,
	bottomInput : {
		marginBottom : '0px' ,
	} ,

} ) );

// HOC to enable the dropdown on sender suggestions
function EnableSenderSuggestions ( props )
{

	// Extract All Props
	const {
		senderEmailSuggestions ,
		enableSenderSuggestions ,
		sendToInputProps ,
		autocompleteProps ,
	} = props;

	// Declare classes for styling
	const classes = useStyles ();

	if ( enableSenderSuggestions )
	{
		return (
			<Autocomplete
				freeSolo
				id = "senderEmailSuggestions"
				disableClearable
				fullWidth
				PaperComponent = { SenderPaper }
				options = { senderEmailSuggestions ? senderEmailSuggestions.map ( ( option ) => `${ option.name } <${ option.email }>` ) : false }
				renderInput = { ( params ) => (
					<TextField
						{ ...params }
						label = "Send To"
						margin = "dense"
						variant = "outlined"
						className = { classes.topInput }
						InputProps = { { ...params.InputProps , type : 'search' } }
						{ ...sendToInputProps }
					/>
				) }
				{ ...autocompleteProps }
			/>
		)
	}
	else
	{
		return (
			<TextField
				label = "Send To"
				margin = "dense"
				variant = "outlined"
				className = { classes.topInput }
				{ ...sendToInputProps }
			/>
		)
	}
}

export default function RapidComposeEmail ( props )
{
	// Etract all props
	const {
		className ,
		ckEditorProps ,
		senderEmailSuggestions ,
		enableSenderSuggestions ,
		sendToInputProps ,
		autocompleteProps ,
		subjectProps ,
		ccInputProps ,
		sendButtonFunction ,
		uploadInputProps ,
		uploadButtonProps ,
	} = props;
	// Declare classes for styling
	const classes = useStyles ();
	// Set the context to check if the compose email is opened or not
	const emailState = useContext ( ComposeEmailState );
	// Set the open constant to check if the compose emaiul is opened or not
	let open = emailState.open;

	console.log ( emailState );

	return (
		<Box
			className = { className }
		>
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth = "sm"
				PaperProps = { {
					className : classes.paper
				} }
				open = { open }
				onClose = { () => emailState.setOpen () }
				PaperComponent = { PaperComponent }
				aria-labelledby = "draggable-dialog-for-email"
			>
				<Box
					className = { classes.titleWrap }
					style = { { cursor : 'move' } }
					id = "draggable-dialog-for-email"
				>
					<Typography component = "div" variant = "h4">Compose Email</Typography>
					<IconButton
						onClick = { () => emailState.setOpen () }
						aria-label = "Close"
					>
						<Close />
					</IconButton>
				</Box>
				<Divider />
				<Box px = { 2 } pt = { 2 } pb = { 2 } className = { classes.addressWrap }>
					<EnableSenderSuggestions
						senderEmailSuggestions = { senderEmailSuggestions }
						enableSenderSuggestions = { enableSenderSuggestions }
						sendToInputProps = { sendToInputProps }
						autocompleteProps = { autocompleteProps }
					/>
					<TextField
						fullWidth
						id = "ccSenders"
						label = "CC"
						margin = "dense"
						placeholder = "Add To CC"
						variant = "outlined"
						{ ...ccInputProps }
					/>
					<TextField
						fullWidth
						id = "emailSubject"
						label = "Subject"
						margin = "dense"
						placeholder = "Subject"
						variant = "outlined"
						className = { classes.bottomInput }
						{ ...subjectProps }
					/>
				</Box>
				<Divider />
				<Box className = { classes.emailEditor } px = { 2 } py = { 2 }>
					<RapidCkeEditorClassic
						ckEditorProps = { ckEditorProps }
					/>
				</Box>
				<Divider />
				<Box className = { classes.sendWrap } px = { 2 } py = { 2 }>

					<Box display = "flex">
						<input
							accept = "image/*"
							className = { classes.uploadButton }
							id = "contained-button-file"
							multiple
							type = "file"
							{ ...uploadInputProps }
						/>
						<label htmlFor = "contained-button-file">
							<Button
								variant = "contained"
								color = "default"
								component = "span"
								startIcon = { <Backup /> }
								{ ...uploadButtonProps }
							>
								Attach
							</Button>
						</label>
					</Box>

					<Box display = "flex">
						<Button
							onClick = { ( e ) =>
							{
								emailState.setOpen ();
								if ( sendButtonFunction )
								{
									sendButtonFunction ( e )
								}
							} }
							variant = "contained"
							color = "primary">
							Send
						</Button>
					</Box>
				</Box>
			</Dialog>
		</Box>
	);
}

// Default props
RapidComposeEmail.defaultProps = {
	senderEmailSuggestions : {
		name : 'Default Suggestion' ,
		email : 'default@email.com'
	} ,
	enableSenderSuggestions : true ,
};
// Prop Types
RapidComposeEmail.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 * You can use any CKEditor props passed as an object. All the props for CKEditor can we check in the [CKEditor React
	 * Documentation](https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html#component-properties)
	 */
	ckEditorProps : PropTypes.object ,
	/**
	 * Whether to enable the send to suggestions or not
	 */
	enableSenderSuggestions : PropTypes.bool ,
	/**
	 * An array of suggestions that you want to add to the Send To field
	 */
	senderEmailSuggestions : PropTypes.arrayOf (
		PropTypes.shape (
			{
				/** The Email address of the suggested contact */
				email : PropTypes.string ,
				/** The name of the suggested person */
				name : PropTypes.string ,
			} ,
		)
	) ,
	/**
	 * An object of the props that you wnat to pass to the Send To Autocomplete. You can use any of the [Material UI Autocomplete Props](https://material-ui.com/api/autocomplete/)
	 */
	autocompleteProps : PropTypes.object ,
	/**
	 * An object of the props that you want to pass to the Send To Input field. You can use any of the [Material UI TextField Props](https://material-ui.com/api/text-field/)
	 */
	sendToInputProps : PropTypes.object ,
	/**
	 * An object of the props that you want to pass to the CC Input field. You can use any of the [Material UI TextField Props](https://material-ui.com/api/text-field/)
	 */
	ccInputProps : PropTypes.object ,
	/**
	 * An object of the props that you want to pass to the Subject Input field. You can use any of the [Material UI TextField Props](https://material-ui.com/api/text-field/)
	 */
	subjectProps : PropTypes.object ,
	/**
	 * You get an event object by default for this function. Based on the event object you can write your own code. Clicking the send button closes the RapidComposeEmail dialogue by default
	 */
	sendButtonFunction : PropTypes.func ,
	/**
	 * An object of props that you would want to pass to the upload file Input Field. You can use any props from the [ Material UI Input API ](https://material-ui.com/api/input/#input-api)
	 */
	uploadInputProps : PropTypes.object ,
	/**
	 * An object of props that you would want to pass to the upload file Button. You can use any props from the [ Material UI Button API ](https://material-ui.com/api/button/)
	 */
	uploadButtonProps : PropTypes.object ,

};
