import React , { useState } from "react";

export const MiniSidebarActiveState = React.createContext ( {
	activeLink : null ,
	setActiveLink : (id) =>
	{
	} ,
} );

export function MiniSidebarActiveStateContextProvider ( props )
{
	const [ activeLink , setActiveLink ] = useState ( null );

	function activeLinkSetter ( id )
	{
		setActiveLink ( id );
	}

	return (
		<MiniSidebarActiveState.Provider
			value = {
				{
					activeLink : activeLink ,
					setActiveLink : activeLinkSetter ,
				}
			}
		>
			{ props.children }
		</MiniSidebarActiveState.Provider>
	)
}

