/**
 * Declaring object for the default blue color
 */
const rapidGreen = {
    50: '#E8FAEF',
    100: '#C6F3D8',
    200: '#A0ECBE',
    300: '#7AE4A4',
    400: '#5EDE91',
    500: '#41D87D',
    600: '#3BD475',
    700: '#32CE6A',
    800: '#2AC860',
    900: '#1CBF4D',
    A100: '#FFFFFF',
    A200: '#C2FFD2',
    A400: '#8FFFAD',
    A700: '#75FF9B',
};

export default rapidGreen;
