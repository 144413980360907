import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles , useTheme , ThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import rapidGenerateColoredTheme from "../../../../tools/functions/rapidGenerateColoredTheme/rapidGenerateColoredTheme";

function LinearProgressWithLabel ( props )
{
}

LinearProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate and buffer variants.
	 * Value between 0 and 100.
	 */
	value : PropTypes.number.isRequired ,
};

const useStyles = makeStyles ( {
	root : {
		width : '100%' ,
	} ,
} );

export default function RenderProgress ( props )
{
	// Extract all props
	const { cell } = props;

	// Declare Classes
	const classes = useStyles ();

	// Get the parent theme being used
	let parentTheme = useTheme ();

	// Generate a new theme based on the color selected by the user
	const theme = rapidGenerateColoredTheme ( cell.progressColor , parentTheme );

	return (
		<div className = { classes.root }>
			<ThemeProvider theme = { theme }>

				<Box display = "flex" alignItems = "center">
					<Box width = "100%" mr = { 1 }>
						<LinearProgress variant = "determinate" value = { cell.data } color = "primary" />
					</Box>
					<Box minWidth = { 35 }>
						<Typography variant = "caption">{ `${ Math.round (
							cell.data ,
						) }%` }</Typography>
					</Box>
				</Box>
			</ThemeProvider>
		</div>
	);
}
