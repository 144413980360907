import React from 'react';
import { RapidMain , RapidRouter } from '../@rapidAdmin/components'
import routes from "../config/routes/routes";

const isAuth = true;

function App ()
{
	return (
		<RapidMain>
			<RapidRouter routes = { routes } isAuth = { isAuth } />
		</RapidMain>
	);
}

export default App;
