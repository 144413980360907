import React from 'react';

export default function ComposeContext ( props )
{
	const { components = [] , children } = props;

	return (
		<>
			{ components.reduceRight ( ( acc , Comp ) =>
			{
				return <Comp>{ acc }</Comp>
			} , children ) }
		</>
	)
}