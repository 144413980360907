/**
 * Declaring object for the default blue color
 */
const rapidRed = {
    50: '#FBE8EA',
    100: '#F6C5CB',
    200: '#F09EA9',
    300: '#E97786',
    400: '#E5596C',
    500: '#E03C52',
    600: '#DC364B',
    700: '#D82E41',
    800: '#D32738',
    900: '#CB1A28',
    A100: '#FFFFFF',
    A200: '#FFCCCF',
    A400: '#FF99A0',
    A700: '#FF8088',
};

export default rapidRed;
