import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PropTypes from "prop-types";
import uniqid from "uniqid";
import RenderCorrectComponent from "./helpers/RenderCorrectComponent";

export default function RapidTaskStatusTable ( props )
{
	// Extract All Prop Types
	const {
		className ,
		tableMinWidth ,
		tableHeaders ,
		rowData ,
		noDataText ,
		containerPaperVariant ,
		containerPaperElevation ,
	} = props;

	const useStyles = makeStyles ( ( theme ) => (
			{
				table : {
					minWidth : tableMinWidth ,
				} ,
				noData : {
					padding : theme.spacing ( 2 ) ,
					textAlign : 'center'
				}
			}
		)
	);

	const classes = useStyles ();

	return (
		<Paper
			variant = { containerPaperVariant }
			elevation = { containerPaperElevation }
			style = { { overflowX : "auto" , width : "100%" } }
			className = { className }
		>
			<Table className = { classes.table } aria-label = "simple table">
				{ tableHeaders.length > 0 ? (
						<TableHead>
							<TableRow>
								{ tableHeaders.map ( ( cell , index ) => (
									<TableCell
										key = { ( cell.id + index ).toString () }
										align = { cell.alignment }
									>
										{ cell.label }
									</TableCell>
								) ) }
							</TableRow>
						</TableHead>
					)
					:
					(
						<></>
					)
				}


				{/* Render The Table Body */ }
				<TableBody>
					{
						rowData.length > 0 ? (
								rowData.map ( ( row , index ) => (
									<TableRow key = { uniqid ( 'rapid-row' ) }>
										{ row.map ( ( cell ) => (
											<TableCell
												key = { ( cell.id + index ).toString () }
												id = { cell.id }
												align = { cell.alignment }
											>
												<RenderCorrectComponent cell = { cell } />
											</TableCell>
										) ) }
									</TableRow>
								) )
							) :
							(
								<TableRow>
									<TableCell
										colSpan = { tableHeaders.length + 1 }
										variant = "subtitle2"
										className = { classes.noData }
									>
										{ noDataText }
									</TableCell>
								</TableRow>
							)
					}
				</TableBody>
			</Table>
		</Paper>
	);
};

// Default Prop types
RapidTaskStatusTable.defaultProps = {
	tableMinWidth : '320px' ,
	tableHeaders : [] ,
	rowData : [] ,
	avatarSpacing : 'medium' ,
	noDataText : 'The Table Does Not Have Any Data As Yet' ,
};

// Prop types
RapidTaskStatusTable.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how you can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 * Min width that needs to be assigned to the table
	 */
	tableMinWidth : PropTypes.string ,
	/**
	 * Min width that needs to be assigned to the table
	 */
	tableHeaders : PropTypes.arrayOf (
		PropTypes.shape (
			{
				/** A Unique ID for each of the column header value  */
				id : PropTypes.string.isRequired ,
				/** A label for the header */
				label : PropTypes.string ,
				/** A label for the header. Options for either 'left' , 'right' , 'center' */
				alignment : PropTypes.oneOf ( [ 'left' , 'right' , 'center' ] ) ,
			}
		) ) ,
	/**
	 * Need to be an array of array of objects with the each object being a single cell and each array being a single row. The shape of the object has been specified.
	 */
	rowData : PropTypes.arrayOf (
		PropTypes.arrayOf (
			PropTypes.shape (
				{
					/** A Unique ID for each of the table cell value  */
					id : PropTypes.string.isRequired ,
					/** The Type Of cell value. Possible values 'string' , 'avatar' , 'status' , 'progress' , 'action' , 'link'   */
					type : PropTypes.oneOf ( [ 'string' , 'avatar' , 'status' , 'progress' , 'action' , 'link' ] ) ,
					/** A label for the header. Possible Values 'left' , 'right' , 'center', 'inherit', 'justify' */
					alignment : PropTypes.oneOf ( [ 'left' , 'right' , 'center' ] ) ,
					/** Max avatars to show before +x.  To be used if component type is 'avatar'  */
					maxAvatars : PropTypes.number ,
					/** Spacing between avatars. Options of 'small' and 'medium'. To be used if component type is 'avatar'  */
					avatarSpacing : PropTypes.oneOf ( [ 'small' , 'medium' ] ) ,
					/** A object of the data that needs to be passed to the cell */
					data : PropTypes.any ,
					/** The weight of the font for the string component. Options available 'regular', 'bold' */
					fontWeight : PropTypes.oneOf ( [ 'regular' , 'bold' ] ) ,
					/** The color of the progress component. Options available 'primary', 'secondary', 'warning' , 'success' , 'error'  */
					progressColor : PropTypes.oneOf ( [ 'primary' , 'secondary' , 'warning' , 'success' , 'error' , 'info' ] ) ,
					/** The color of the status component. Options available 'primary', 'secondary', 'warning' , 'success' , 'error'  */
					statusColor : PropTypes.oneOf ( [ 'primary' , 'secondary' , 'warning' , 'success' , 'error' , 'info' ] ) ,
					/** Two variants available 'default' and 'outlined'  */
					statusVariant : PropTypes.oneOf ( [ 'default' , 'outlined' ] ) ,
				}
			)
		)
	) ,
	/**
	 *The default text that needs to be displayed when there is no data in the tables
	 */
	noDataText : PropTypes.string ,
	/**
	 * The elevation assigned to the paper component hoilding the table
	 */
	containerPaperElevation : PropTypes.number ,
	/**
	 * The variant of the paper element to be used
	 */
	containerPaperVariant : PropTypes.oneOf ( [ 'elevation' , 'outlined' ] ) ,
};