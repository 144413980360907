import rapidThemeType from "../../../../../tools/functions/rapidThemeType/rapidThemeType";
import settings from "../../../../../../config/rapidAdmin/settings";

export default function emitTheme ( theme , parentTheme , themeContext )
{
	// If falsy declare a dummy object
	if ( !theme )
	{
		theme = {};
	}
	// The actually styling object
	return {
		...theme ,
		mode : rapidThemeType ( settings , themeContext ) ,
	};
}