import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles ( () => ( {
	bold : {
		fontWeight : '700' ,
	}
} ) );

export default function RenderString ( props )
{
	// Extract all props
	const { cell } = props;
	// Create classes for styling
	const classes = useStyles ();

	return (
		<>
			<span
				className = { clsx ( {
					[ classes.bold ] : cell.fontWeight === 'bold' ,

				} ) }
			>
				{ cell.data }
			</span>
		</>
	)
}
