/**
 * Declaring object for the default blue color
 */
const rapidLightBlue = {
    50: '#E5F2FD',
    100: '#BDDFFB',
    200: '#92CAF9',
    300: '#66B5F6',
    400: '#45A5F4',
    500: '#2495F2',
    600: '#208DF0',
    700: '#1B82EE',
    800: '#1678EC',
    900: '#0D67E8',
    A100: '#F5FFF8',
    A200: '#E0ECFF',
    A400: '#ADCBFF',
    A700: '#94BBFF',
};

export default rapidLightBlue;
