/**
 * rapidThemeType Function to get the current theme type from the context
 * @since 1.0.0
 *
 * @param {Object} settings  - The theme settings object passed to the function
 * @param {Object} context - the RapidThemeContext passed to the function
 */

export default function rapidThemeType ( settings , context )
{
	if ( settings.config.theme === 'light' )
	{
		return 'light'
	}
	else if ( settings.config.theme === 'dark' )
	{
		return 'dark'
	}
	else if ( settings.config.theme === 'context' )
	{
		return context.theme
	}
}