import Checkbox from "@material-ui/core/Checkbox";
import GradeOutlined from "@material-ui/icons/GradeOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Grade from "@material-ui/icons/Grade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import PropTypes from "prop-types";

export default function StarEmail ( props )
{
	// extract all props
	const {
		starClick ,
		emailId
	} = props;

	// Declaring the styling rules
	const useStyles = makeStyles ( ( theme ) => ( {
		root : {
			margin : theme.spacing ( 0 ) ,
		} ,
	} ) )
	// Create classes
	const classes = useStyles ();

	return (
		<FormControlLabel
			className = { classes.root }
			onClick = { ( e ) => starClick ( e , emailId ) }
			control = {
				<Checkbox
					icon = { <GradeOutlined /> }
					checkedIcon = { <Grade /> }
					name = "star"
					color = "primary"
				/>
			}
			label = { false }
		/>
	)
}

StarEmail.defaultProps = {};

StarEmail.propTypes = {
	/** The email id for which the star is for  */
	emailId : PropTypes.string.isRequired ,
	/** Handle Click function for start checkbox   */
	starClick : PropTypes.func ,
};