import React from "react";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Import the required icons
import CalendarViewDayOutlinedIcon from '@material-ui/icons/CalendarViewDayOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SkipNextOutlinedIcon from '@material-ui/icons/SkipNextOutlined';
import SkipPreviousOutlinedIcon from '@material-ui/icons/SkipPreviousOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';

/**
 * renderTimeGridDay
 * @ignore
 */
export function renderTimeGridDay ()
{
	return (
		<Tooltip placement = "top" title = "Daily" arrow>
			<IconButton component = "span" aria-label = "Daily Grid View">
				<CalendarViewDayOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

/**
 * renderDayGridMonth
 * @ignore
 */
export function renderDayGridMonth ()
{
	return (
		<Tooltip placement = "top" title = "Month" arrow>
			<IconButton component = "span" aria-label = "Monthly View">
				<GridOnOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderTimeGridWeek ()
{
	return (
		<Tooltip placement = "top" title = "Week" arrow>
			<IconButton component = "span" aria-label = "Weekly View">
				<ViewWeekOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderListMonth ()
{
	return (
		<Tooltip placement = "top" title = "Task List" arrow>
			<IconButton component = "span" aria-label = "Task List View">
				<ListOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderNext ()
{
	return (
		<Tooltip placement = "top" title = "Next" arrow>
			<IconButton component = "span" size = "small" aria-label = "Next">
				<ChevronRightIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderPrevious ()
{
	return (
		<Tooltip placement = "top" title = "Previous" arrow>
			<IconButton component = "span" size = "small" aria-label = "Previous">
				<ChevronLeftIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderNextYear ()
{
	return (
		<Tooltip placement = "top" title = "Next Year" arrow>
			<IconButton component = "span" size = "small" aria-label = "Next Year">
				<SkipNextOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderPreviousYear ()
{
	return (
		<Tooltip placement = "top" title = "Previous Year" arrow>
			<IconButton component = "span" size = "small" aria-label = "Previous Year">
				<SkipPreviousOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}

export function renderToday ()
{
	return (
		<Tooltip placement = "top" title = "Today" arrow>
			<IconButton component = "span" aria-label = "Today">
				<TodayOutlinedIcon />
			</IconButton>
		</Tooltip>
	)
}