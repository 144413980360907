import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import DefaulProfileImage from '../../../../assets/images/avatars/andrew-teasor.jpg';
import theme from "../../../../config/theme/theme";

/**
 * RapidChatWidget a fully customizable chat widget.
 *
 * >> RapidChatWidget uses the [react-chat-widget](https://github.com/Wolox/react-chat-widget) under the hood. Apart from the available props the
react-chat-widget also offers other methods for interacting with the chat plugin. You can use all the methods and import them directly using
react-chat-widget
 *
 * @since 1.0.0
 */
export default function RapidChatWidget ( props )
{
	// Extract all the props
	const {
		className ,
		handleNewUserMessage ,
		title ,
		subtitle ,
		senderPlaceHolder ,
		profileAvatar ,
		titleAvatar ,
		autofocus ,
		launcher ,
		handleQuickButtonClicked ,
		showTimeStamp ,
		chatId ,
		launcherOpenLabel ,
		launcherCloseLabel ,
		sendButtonAlt ,
		handleTextInputChange ,
		handleSubmit ,
		backgroundColor ,
		elevation ,
		titleAvatarSize ,
		chatBackground ,

	} = props;

	const useStyles = makeStyles ( ( theme ) => ( {
		rapidChat : {
			// Styling the launcher Button
			'& .rcw-widget-container' : {
				display : 'inline-flex' ,
				width : 'auto' ,
				'@media screen and (max-width: 800px)' : {
					height : 'auto' ,
				} ,
				'& .rcw-launcher' : {
					backgroundColor : backgroundColor ,
					boxShadow : theme.shadows[ elevation ] ,
				}
			} ,

			// Styling the chat widget
			'& .rcw-conversation-container' : {
				width : '370px' ,
				'@media screen and (max-width: 800px)' : {
					height : '80vh !important' ,
				} ,
				boxShadow : theme.shadows[ elevation ] ,
				'& .rcw-header' : {
					background : backgroundColor ,

					'& .rcw-close-button' : {
						padding : theme.spacing ( 1 ) ,
						margin : theme.spacing ( 0 ) ,
						width : '28px' ,
						height : '28px' ,
						top : theme.spacing ( 1 ) ,
						right : theme.spacing ( 1 ) ,
						borderRadius : '16px' ,
						backgroundColor : theme.palette.action.disabled ,
						transition : theme.transitions.create ( 'background-color' , {
							easing : theme.transitions.easing.easeOut ,
							duration : theme.transitions.duration.shortest ,
						} ) ,
						'&:hover' : {
							backgroundColor : theme.palette.action.hover ,
						} ,
						'&:focus' : {
							backgroundColor : theme.palette.action.active ,
							outline : 'none' ,
						} ,
						'& img' : {
							width : `${ theme.spacing ( 1.5 ) }px` ,
							height : `${ theme.spacing ( 1.5 ) }px` ,
							margin : theme.spacing ( 0 ) ,
						} ,

					} ,

					'& .rcw-title' : {
						fontWeight : theme.typography.h2.fontWeight ,
						fontSize : theme.typography.h2.fontSize ,
						fontFamily : theme.typography.h2.fontFamily ,
						lineHeight : theme.typography.h2.lineHeight ,

						'& img' : {
							height : titleAvatarSize ,
							width : titleAvatarSize ,
							margin : theme.spacing ( 0 , 1.5 )
						}
					} ,

					'& span' : {
						fontWeight : theme.typography.body2.fontWeight ,
						fontSize : theme.typography.body2.fontSize ,
						fontFamily : theme.typography.body2.fontFamily ,
						lineHeight : theme.typography.body2.lineHeight ,
					} ,

				} ,

			} ,

			// Styling the input form
			'& .rcw-sender' : {
				backgroundColor : theme.palette.background.paper ,
				padding : theme.spacing ( 1 ) ,
				height : '64px' ,

				'& .rcw-new-message' : {
					fontWeight : theme.typography.body2.fontWeight ,
					fontSize : theme.typography.body2.fontSize ,
					fontFamily : theme.typography.body2.fontFamily ,
					lineHeight : theme.typography.body2.lineHeight ,
					color : theme.palette.text.primary ,
					backgroundColor : theme.palette.background.default ,
					paddingTop : theme.spacing ( 1 ) ,
					paddingBottom : theme.spacing ( 1 ) ,
					height : '100%' ,
					borderTopLeftRadius : theme.shape.borderRadius ,
					borderBottomLeftRadius : theme.shape.borderRadius ,
				} ,

				'& .rcw-send' : {
					backgroundColor : theme.palette.background.default ,
					height : '100%' ,
					borderTopRightRadius : theme.shape.borderRadius ,
					borderBottomRightRadius : theme.shape.borderRadius ,
					'&:focus' : {
						outline : 'none' ,
					} ,
				} ,
			} ,

			// Messages container
			'& .rcw-messages-container' : {
				background : chatBackground ,
				backgroundColor : theme.palette.background.paper ,
				fontWeight : theme.typography.body2.fontWeight ,
				fontSize : theme.typography.body2.fontSize ,
				fontFamily : theme.typography.body2.fontFamily ,
				lineHeight : theme.typography.body2.lineHeight ,
				color : theme.palette.text.primary ,
			} ,

			// Message properties of the client
			'& .rcw-client' : {
				'& .rcw-message-text' : {
					fontWeight : theme.typography.body2.fontWeight ,
					fontSize : theme.typography.body2.fontSize ,
					fontFamily : theme.typography.body2.fontFamily ,
					lineHeight : theme.typography.body2.lineHeight ,
					color : theme.palette.text.primary ,
					backgroundColor : theme.palette.action.disabled ,
				}
			} ,

			// Overriding the reposonse
			'& .rcw-response' : {
				'& .rcw-message-text' : {
					backgroundColor : theme.palette.action.hover ,
					fontWeight : theme.typography.body2.fontWeight ,
					fontSize : theme.typography.body2.fontSize ,
					fontFamily : theme.typography.body2.fontFamily ,
					lineHeight : theme.typography.body2.lineHeight ,
					color : theme.palette.text.primary ,
				}
			} ,

			// Override the link styling
			'& .rcw-snippet' : {
				backgroundColor : theme.palette.action.hover ,
				fontWeight : theme.typography.body2.fontWeight ,
				fontSize : theme.typography.body2.fontSize ,
				fontFamily : theme.typography.body2.fontFamily ,
				lineHeight : theme.typography.body2.lineHeight ,
				color : theme.palette.text.primary ,
				'& .rcw-snippet-title' : {
					fontWeight : theme.typography.subtitle2.fontWeight ,
					fontSize : theme.typography.subtitle2.fontSize ,
					fontFamily : theme.typography.subtitle2.fontFamily ,
					lineHeight : theme.typography.subtitle2.lineHeight ,
					color : theme.palette.text.primary ,
				} ,
				'& .rcw-snippet-details' : {
					borderColor : theme.palette.primary.main ,
				}
			} ,

			// General Styling
			'& .rcw-message' : {
				'& .rcw-avatar' : {
					margin : theme.spacing ( 0 , 1.5 ) ,
				}
			} ,

		}
	} ) );

	// Create the classes constant
	const classes = useStyles ();

	return (
		<Box component = "span" className = { `${ classes.rapidChat }  ${ className }` }>
			<Widget
				handleNewUserMessage = { handleNewUserMessage }
				title = { title }
				subtitle = { subtitle }
				senderPlaceHolder = { senderPlaceHolder }
				profileAvatar = { profileAvatar }
				titleAvatar = { titleAvatar }
				autofocus = { autofocus }
				launcher = { launcher }
				handleQuickButtonClicked = { handleQuickButtonClicked }
				showTimeStamp = { showTimeStamp }
				chatId = { chatId }
				launcherOpenLabel = { launcherOpenLabel }
				launcherCloseLabel = { launcherCloseLabel }
				sendButtonAlt = { sendButtonAlt }
				handleTextInputChange = { handleTextInputChange }
				handleSubmit = { handleSubmit }
			/>
		</Box>
	)
}

RapidChatWidget.defaultProps = {
	title : 'John Doe' ,
	subtitle : 'Example implementation of the Chat Widget' ,
	senderPlaceHolder : 'Type a message...' ,
	profileAvatar : DefaulProfileImage ,
	titleAvatar : DefaulProfileImage ,
	autofocus : true ,
	showTimeStamp : true ,
	chatId : 'rapid-chat-container' ,
	launcherOpenLabel : 'Open chat' ,
	launcherCloseLabel : 'Close chat' ,
	sendButtonAlt : 'Send' ,
	backgroundColor : theme.palette.primary.main ,
	elevation : 10 ,
	titleAvatarSize : '56px' ,
	chatBackground : `url(/assets/images/background/chat-pattern.png) repeat top left` ,
	handleNewUserMessage : ( e ) => console.log ( e ) ,

};
RapidChatWidget.propTypes = {
	/**
	 *  Additional classes you want to add to the root element of the component. See more information on how yo'Type a message...'u can [customise components here](/docs/customisingComponents/).
	 */
	className : PropTypes.string ,
	/**
	 *  Function to handle the user input, will receive the full text message when submitted
	 */
	handleNewUserMessage : PropTypes.func ,
	/**
	 *  Title of the RapidChatWidget
	 */
	title : PropTypes.string ,
	/**
	 *  Subtitle of the RapidChatWidget
	 */
	subtitle : PropTypes.string ,
	/**
	 *  The placeholder text of the message input
	 */
	senderPlaceHolder : PropTypes.string ,
	/**
	 *  The profile image that will be set on the responses
	 */
	profileAvatar : PropTypes.string ,
	/**
	 *  The picture image that will be shown next to the chat title
	 */
	titleAvatar : PropTypes.string ,
	/**
	 *  The the size applied to picture image that will be shown next to the chat title. The same size is applied to height as well as the width
	 */
	titleAvatarSize : PropTypes.string ,
	/**
	 *  Autofocus or not on the user input
	 */
	autofocus : PropTypes.bool ,
	/**
	 *  Custom Launcher component to use instead of the default. You can pass a function and get (handleToggle) as the parameter
	 */
	launcher : PropTypes.func ,
	/**
	 *  Function to handle the user clicking a quick button, will receive the 'value' when clicked.
	 */
	handleQuickButtonClicked : PropTypes.func ,
	/**
	 *  Show time stamp on messages
	 */
	showTimeStamp : PropTypes.bool ,
	/**
	 *  Chat container id for a11y
	 */
	chatId : PropTypes.string ,
	/**
	 *  Alt value for the laucher when closed
	 */
	launcherOpenLabel : PropTypes.string ,
	/**
	 *  Alt value for the laucher when open
	 */
	launcherCloseLabel : PropTypes.string ,
	/**
	 *  Send button alt for a11y purposes
	 */
	sendButtonAlt : PropTypes.string ,
	/**
	 *  Prop that triggers on input change. You get the (event) = {}
	 */
	handleTextInputChange : PropTypes.func ,
	/**
	 *  Prop that triggers when a message is submitted, used for custom validation. You get the (event) = {}
	 */
	handleSubmit : PropTypes.func ,
	/**
	 *  Pass in any valid color value for the color of the launch button
	 */
	backgroundColor : PropTypes.string ,
	/**
	 *  The elevation number that you want to apply to the launch button
	 */
	elevation : PropTypes.number ,

	/**
	 *  Background property assigned to the main messages area. Use any valid CSS property
	 */
	chatBackground : PropTypes.string ,

};

