import React , { useState } from "react";

export const RapidThemeDirectionButtonContext = React.createContext ( {
	checked : true ,
	toggleChecked : () =>
	{
	} ,
} );

export function RapidThemeDirectionButtonContextProvider ( props )
{
	const [ checked , setChecked ] = useState ( true );

	function toggleChecked ()
	{
		checked === true ? setChecked ( false ) : setChecked ( true )
	}

	return (
		<RapidThemeDirectionButtonContext.Provider
			value = {
				{
					checked : checked ,
					toggleChecked : toggleChecked ,
				}
			}
		>
			{ props.children }
		</RapidThemeDirectionButtonContext.Provider>
	)
}

