export default function emitNoData ( noData , parentTheme )
{
	// If falsy declare a dummy object
	if ( !noData )
	{
		noData = {};
	}
	// The actually styling object
	return {
		...noData ,
		text : "Currently there is no data to be displayed. Please add some data." ,
		align : 'center' ,
		verticalAlign : 'middle' ,
		offsetX : 0 ,
		offsetY : 0 ,
		style : {
			...noData.style ,
			color : parentTheme.palette.text.secondary ,
			fontSize : parentTheme.typography.body2.fontSize ,
			fontFamily : parentTheme.typography.body2.fontFamily ,
		}
	};
}