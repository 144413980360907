/**
 * rapidThemeDirection - function that returns the correct theme direction based on the settings set by the user
 * @since 1.0.0
 *
 * @param {Object} settings - the theme settings object
 * @param {Object} context - the theme direction context
 */
export default function rapidThemeDirection ( settings , context )
{
	if ( settings.config.themeDirection === 'ltr' )
	{
		return 'ltr'
	}
	else if ( settings.config.themeDirection === 'rtl' )
	{
		return 'rtl'
	}
	else if ( settings.config.themeDirection === 'context' )
	{
		return context.direction
	}

}