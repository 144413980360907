import React , { useContext } from "react";
import settings from "../../../config/rapidAdmin/settings";
import { jssPreset , makeStyles , StylesProvider } from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { RapidThemeDirectionContext } from "../../context"
import rapidThemeDirection from "../functions/rapidThemeDirection/rapidThemeDirection";

// Adds the direction to the body tag
const useDirection = makeStyles ( () => ( {
	'@global' : {
		'body' : {
			direction : 'ltr' ,
		} ,
	}
} ) );

// Configure JSS
const jss = create ( { plugins : [ ...jssPreset ().plugins , rtl () ] } );

function RtlBodyInsert ( props )
{
	useDirection ();
	return props.children;
}

export default function CheckRTL ( props )
{
	// Set the context for theme direction
	const themeDirectionContext = useContext ( RapidThemeDirectionContext );

	if ( rapidThemeDirection ( settings , themeDirectionContext ) === 'rtl' )
	{
		return (
			<StylesProvider jss = { jss }>
				<RtlBodyInsert>
					{ props.children }
				</RtlBodyInsert>
			</StylesProvider>
		);
	}
	else
	{
		return props.children;
	}

}