import React , { Suspense } from "react";
import { Redirect , Route , Switch } from 'react-router-dom';
import settings from "../../../../config/rapidAdmin/settings";

function RouteWithSubRoutes ( props )
{
	const route = { ...props };
	let isAuth = props.Auth;

	return (
		<Suspense fallback = { route.fallback }>
			<Route
				path = { route.path }
				render = { ( props ) =>
					route.redirect ? <Redirect to = { route.redirect } /> :
						route.private ? (
								isAuth ? route.component &&
									<route.component
										{ ...props }
										routes = { route.routes }
									/>
									:
									<Redirect to = { settings.user.defaultRedirect } />
							)
							:
							isAuth ? <Redirect to = { settings.user.redirectWhenAuth } />
								: route.component &&
								<route.component
									{ ...props }
									routes = { route.routes }
								/>
				}
			/>
		</Suspense>
	);
}

/**
 * RapidRouter RapidAdminReact® seamlessly integrated with the react-router. The react-router is set by default and you can start using without any configuration. This guide will help you get started with routing with RapidAdminReact®.
 * Read in detail about routes and setting routes using RapidAdminReact® in the [Routing Section](/docs/routing/)
 * @since 1.0.0
 */
export default function RapidRouter ( { routes , isAuth } )
{
	return (
		<Switch>
			{ routes && routes.map ( ( route , index ) => <RouteWithSubRoutes key = { route.path + index } { ...route } Auth = { isAuth } /> ) }
		</Switch>
	);
}